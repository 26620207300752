import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
// import { useParams } from 'react-router-dom';
import { exerciseData } from './ExerciseData';

import { GrSplit } from 'react-icons/gr';
import { VscSplitVertical } from 'react-icons/vsc';
import { supabase } from '../../config/supabaseConfig';
import { useGetUserQuery } from '../profile/userSlice';

const EditorPanel = ({ exerciseid }) => {
  //   const foo = {
  //     html: [],
  //     css: [],
  //     bootstrap: [],
  //     js: [],
  //     git: [],
  //   };

  const { data: userData } = useGetUserQuery();
  const user_id = userData?._id;

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('full_stack_users')
        .select('practice_questions')
        .match({ user_id });

      if (fetchedData[0]) {
        // setEnrollData(fetchedData[0]);
        const practice_questions = fetchedData[0].practice_questions || [];
        if (!practice_questions.includes(exerciseid)) {
          practice_questions.push(exerciseid);

          const { error } = await supabase
            .from('full_stack_users')
            .update({ practice_questions: practice_questions })
            .match({ user_id });

          if (error) {
            console.log(error);
          }
        }
      }

      //   setLoading(false);
    };

    if (user_id) {
      setDataFn();
    }
  }, [user_id, exerciseid]);

  // const { exerciseid } = useParams();
  const preData = exerciseData.find((el) => el.exerciseId === exerciseid);

  const preFillCode = preData?.code || '<h1>Hello, world!</h1>';

  const [htmlCode, setHtmlCode] = useState(preFillCode);

  const handleHtmlChange = (value, event) => {
    setHtmlCode(value);
  };

  const [isrotated, setRotated] = useState(false);

  return (
    <div>
      {preData && (
        <div >
          <p className='flex flex-row space-x-2 font-bold text-xl underline '>{' Task: '} {preData.taskHeading}</p>
          <p className='text-lg font-semibold'> {preData.question}   </p>     
        </div>
      )}
      <div className='flex justify-between'>
        <div className=''>
          Try the practice question at least once before moving on to the next
          topic. You can reattempt it as many times as you like.
        </div>
        <div onClick={() => setRotated((pre) => !pre)}>
          {isrotated ? (
            <GrSplit className='text-2xl mr-4' />
          ) : (
            <VscSplitVertical className='text-2xl mr-4' />
          )}
        </div>{' '}
      </div>
      <div className={isrotated ? '' : 'flex'}>
        <div
          className={
            isrotated
              ? 'p-4  border-r-0 border-gray-300'
              : 'w-1/2 p-4  border-r-0 border-gray-300'
          }>
          <Editor
            height='60vh'
            defaultLanguage='html'
            defaultValue={htmlCode}
            onChange={handleHtmlChange}
            theme='vs-dark'
          />
        </div>
        <div className={isrotated ? 'p-4' : 'w-1/2 p-4'}>
          <iframe
            title='output'
            className='w-full h-full border border-gray-300'
            srcDoc={htmlCode}
          />
        </div>
      </div>
    </div>
  );
};

export default EditorPanel;

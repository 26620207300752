import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';


const Navbar = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'> Navbar</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/7AT1X9Z41sA?si=ra-EetC1PYfYCdez'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Creating Responsive Navigation Bars
        </span>
        <p>
          Navigation bars (or navbars) are crucial for helping users navigate
          your website. Bootstrap's navbar is highly customizable and
          responsive, meaning it can automatically collapse into a hamburger
          menu on smaller screens.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Responsive Navbar Example'}
          codeStr={`<nav class="navbar navbar-expand-lg navbar-light bg-light">
<a class="navbar-brand" href="#">Navbar</a>
<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
<span class="navbar-toggler-icon"></span>
</button>
<div class="collapse navbar-collapse" id="navbarNav">
<ul class="navbar-nav">
<li class="nav-item">
  <a class="nav-link" href="#">Home</a>
</li>
</ul>
</div>
</nav>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>navbar-expand-lg:</strong> This class ensures that the
            navbar is fully expanded on large screens but collapses into a
            hamburger menu on smaller screens.
          </li>
          <li>
            <strong>navbar-light:</strong> Makes the text dark and the
            background light. You can switch this to navbar-dark for light text
            on a dark background.
          </li>
        </ul>
        <p>
          This responsive navbar will adapt to different screen sizes, providing
          an optimal user experience across devices.
        </p>
      </div>
    </div>
  );
};

export default Navbar;

import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Forms = () => {
  return (
    <div className='flex flex-col gap-3'>
      <span className='font-semibold text-lg'>Forms</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/dKVX22GR7zQ?si=B7m5_3-hZ-nslZVI'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          How Bootstrap Makes Form Styling Easier
        </span>
        <p>
          Forms are used to collect user input, and Bootstrap's predefined
          classes make them easier to style without writing much CSS. Each input
          field can have a class like form-control, which automatically applies
          consistent styling to the form elements.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Form Example'}
          codeStr={`<form>
  <div class="form-group">
    <label for="email">Email address</label>
    <input type="email" class="form-control" id="email" placeholder="Enter email">
  </div>
</form>`}
        />
        <span>In this example:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`form-group`} />: Groups form elements
            together.
          </li>
          <li>
            <SingleCode codeLine={`form-control`} />: Applies styling to the
            input field to make it look consistent across different devices.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Forms;

import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Tables = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Tables</span>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Creating Clean, Responsive Tables with Bootstrap
        </span>
        <span>
          Bootstrap simplifies the process of creating tables that look clean
          and professional. You can add predefined classes to style your tables,
          such as <SingleCode codeLine='table-striped' /> for alternating row
          colors or <SingleCode codeLine='table-hover' /> for highlighting rows
          when users hover over them.
        </span>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Table Example'}
          codeStr={`<table class="table table-striped">
<thead>
  <tr>
    <th>Name</th>
    <th>Age</th>
  </tr>
</thead>
<tbody>
  <tr>
    <td>John</td>
    <td>30</td>
  </tr>
</tbody>
</table>`}
        />
        <ul className='list-disc pl-5'>
          <li>
            <strong>Striped Rows:</strong> Alternate background colors for
            better readability.
          </li>
          <li>
            <strong>Hoverable Rows:</strong> Makes the table interactive by
            highlighting rows when hovered over.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Tables;

import React from 'react';
import Introduction from './Introduction';
import Rulesets from './Rulesets';
import Properties from './Properties';
import Colors from './Colors';
import Units from './Units';
import BoxModel from './BoxModel';
import Margins from './Margins';
import Paddings from './Paddings';
import Borders from './Borders';
import BlockVsInline from './BlockVsInline';
import Selectors from './Selectors';
import Specificity from './Specificity';
import Inheritance from './Inheritance';
import Cascading from './Cascading';
import { supabase } from '../../../../../config/supabaseConfig';

import { cssTopicsObj } from '../../topics';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import IdealSolution from '../../../../htmlEditor/IdealSolution';

const Index = ({
  setTopic,
  topic,
  enrollData,
  setReFetch,
  nextMilestone,
  setExerciseOpen,
  isExerciseOpen,
  showIdealSolution,
  setShowIdealSolution,
  currExerciseIdx,
  setCurrExerciseIdx,
}) => {
  const updateCssData = async (topic) => {
    const cssPreData = enrollData?.milestone_1?.css || [];
    if (!cssPreData.includes(topic)) {
      cssPreData.push(topic);
      const milestone_1 = enrollData?.milestone_1;
      await supabase
        .from('full_stack_users')
        .update({
          milestone_1: {
            ...milestone_1,
            css: cssPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };

  const cssData = enrollData?.milestone_1?.css || [];
  const topicIsCompleted = cssData.includes(topic);

  return (
    <div className='flex flex-col'>
      {!isExerciseOpen ? (
        <React.Fragment>
          {topic === 'introduction' && (
            <Introduction
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('introduction')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}

          {topic === 'rulesets' && (
            <Rulesets
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('rulesets')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'properties' && (
            <Properties
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('properties')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'colors' && (
            <Colors
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('colors')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'units' && (
            <Units
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('units')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'boxModel' && (
            <BoxModel
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('boxModel')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'borders' && (
            <Borders
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('borders')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'margins' && (
            <Margins
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('margins')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'paddings' && (
            <Paddings
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('paddings')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'block-vs-inline' && (
            <BlockVsInline
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('block-vs-inline')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'selectors' && (
            <Selectors
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('selectors')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'specificity' && (
            <Specificity
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('specificity')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'inheritance' && (
            <Inheritance
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('inheritance')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'cascading' && (
            <Cascading
              nextMilestone={nextMilestone}
              setTopic={setTopic}
              updateCssData={updateCssData}
              isCompleted={cssData.includes('cascading')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <EditorPanel
            exerciseid={cssTopicsObj[topic].exercise_id[currExerciseIdx]}
          />
          <IdealSolution
            exerciseid={cssTopicsObj[topic].exercise_id[currExerciseIdx]}
          />
        </React.Fragment>
      )}
      <div className='flex w-fit gap-3'>
        <button
          onClick={() => {
            // 1: If topic doesnt have any exercise, switch to next topic
            if (cssTopicsObj[topic].exercise_id.length === 0) {
              // Topic doesnt have any exercise
              updateCssData(topic);
              if (topic === 'cascading') {
                setExerciseOpen(false);
                nextMilestone();
              } else {
                setTopic(cssTopicsObj[topic].next_topic_id);
              }
              return;
            }

            // 2: If ex not open, open it
            if (!isExerciseOpen) {
              setExerciseOpen(true);
              return;
            }

            // 3: Ex open, increment index until it reaches max size.
            // If max reached, update data and change topic
            if (isExerciseOpen) {
              // Last ex in array
              if (
                currExerciseIdx ===
                cssTopicsObj[topic].exercise_id.length - 1
              ) {
                setCurrExerciseIdx(0);
                updateCssData(topic);
                setExerciseOpen(false);
                if (topic === 'cascading') {
                  // setTutorialCSS();
                  nextMilestone();
                } else {
                  setTopic(cssTopicsObj[topic].next_topic_id);
                }
                return;
              } else {
                setCurrExerciseIdx(currExerciseIdx + 1);
              }
            }
            // updateCssData(topic);
            // setTopic(cssTopicsObj[topic].next_topic_id);
            // setExerciseOpen(true);
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {topicIsCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Index;

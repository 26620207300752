export const exerciseData = [
  {
    exerciseId: 'html-heading',
    taskHeading: 'Heading Showdown',
    question: (
      <div>
        <p>
          Explore different heading tags available in HTML, from &lt;h1&gt; to
          &lt;h6&gt;.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
    <body>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
    </body>
</html>`,
  },
  {
    exerciseId: 'html-paragraph-1',
    taskHeading: 'About Myself Showcase',
    question: (
      <div>
        <p>Create a paragraph describing yourself.</p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
    <body>
        <p>My name is [Your Name]. I am a student studying in [College Name]. I am interested in [Your Interests].</p>
        <hr/>
        <p>Artificial intelligence is the intelligence of machines or software, as opposed to the intelligence of humans or animals.</p>
    </body>
</html>
`,
  },
  {
    exerciseId: 'html-paragraph-2',
    taskHeading: 'AI Unveiled',
    question: (
      <div>
        <p>
          Display a paragraph about Artificial Intelligence."Artificial
          intelligence is the intelligence of machines or software, as opposed
          to the intelligence of humans or animals."
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `
<html>
    <body>
        <p>Artificial intelligence is the intelligence of machines or software, as opposed to the intelligence of humans or animals.</p>
    </body>
</html>
`,
  },
  {
    exerciseId: 'html-button-1',
    taskHeading: 'Click Congrats Code',
    question: (
      <div>
        <p>
          Use a heading "Artificial Intelligence" above the paragraph, and set
          the paragraph's font size to 60 pixels.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
    <head>
        <style>
            p {
                font-size: 60px;
            }
        </style>
    </head>
    <body>
        <h1>Artificial Intelligence</h1>
        <p>Artificial intelligence is the intelligence of machines or software.</p>
    </body>
</html>
`,
  },
  {
    exerciseId: 'html-button-2',
    taskHeading: 'Color Button Fun',
    question: (
      <div>
        <p>Add two buttons that display alerts when clicked.</p>
      </div>
    ),
    code: ``,
    idealSolution: `
<html>
    <body>
        <button onclick="alert('You clicked on the red button')">Red</button>
        <button onclick="alert('You clicked on the blue button')">Blue</button>
    </body>
</html>`,
  },
  {
    exerciseId: 'html-attribute-1',
    taskHeading: 'Hyperlink Belong Magic',
    question: (
      <div>
        <p>
          Create a hyperlink for "Belong Education" that directs to a specific
          URL.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
    <body>
        <a href="https://belongeducation.com/">Belong Education</a>
    </body>
</html>
`,
  },
  {
    exerciseId: 'html-attribute-2',
    taskHeading: 'Image Showcase',
    question: (
      <div>
        <p>Add an image to the webpage with specific dimensions.</p>
      </div>
    ),
    code: ``,
    idealSolution: `
<html>
    <body>
        <img src="path_to_your_image.jpg" alt="My Image" width="400" height="200">
    </body>
</html>`,
  },
  {
    exerciseId: 'html-image',
    taskHeading: 'Slow Network Sorry',
    question: (
      <div>
        <p>Display an image with a fallback message if it fails to load.</p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
    <body>
        <img src="path_to_your_image.jpg" width="400" height="600" onerror="this.style.display='none'; document.getElementById('fallback').style.display='block';">
        <p id="fallback" style="display:none;">Sorry, network is slow.</p>
    </body>
</html>`,
  },
  {
    exerciseId: 'html-hyperlink',
    taskHeading: 'Open In Style',
    question: (
      <div>
        <p>Make the text "Belong Education" open a URL in a new tab.</p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
    <body>
        <a href="https://belongeducation.com/">Belong Education</a>
    </body>
</html>`,
  },
  {
    exerciseId: 'html-lists-1',
    taskHeading: 'Friend List Love',
    question: (
      <div>
        <p>Generate an unordered list of friends' names.</p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
    <body>
        <ul>
            <li>Friend 1</li>
            <li>Friend 2</li>
            <li>Friend 3</li>
        </ul>
    </body>
</html>
`,
  },
  {
    exerciseId: 'html-lists-2',
    taskHeading: 'Top Subjects List',
    question: (
      <div>
        <p>Generate an ordered list of your top three favorite subjects.</p>
      </div>
    ),
    code: ``,
    idealSolution: `
<html>
    <body>
        <ol>
            <li>Math</li>
            <li>Science</li>
            <li>English</li>
        </ol>
    </body>
</html>

`,
  },
  {
    exerciseId: 'introduction',
    taskHeading: 'Align Right Magic',
    question: (
      <div>
        <p>
          Write a code to display a right aligned paragraph in black colour and
          font size=20.
        </p>
        <p>
          Explanation: To align a paragraph to the right, we can use the CSS
          property text-align: right;. The font color can be set using the color
          property, and the font size can be specified using font-size.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<p style="text-align: right; color: black; font-size: 20px;">
  This is a right-aligned paragraph in black color with a font size of 20px.
</p>`,
  },
  {
    exerciseId: 'css-color',
    taskHeading: 'Colorful Name Show',
    question: (
      <div>
        <p>
          Write a code to display your name in green color with a red background
          color and blue border.
        </p>
        <p>
          Explanation: We can apply multiple styles to an element. For this, we
          can use color for text color, background-color for the background, and
          border for the border styles.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<div style="color: green; background-color: red; border: 2px solid blue; padding: 10px;">
  Your Name Here
</div>`,
  },
  {
    exerciseId: 'css-units-1',
    taskHeading: 'Heading Style Flex',
    question: (
      <div>
        <p>
          Write a code to display your name in heading 2 format with font size
          of 1.5cm.
        </p>
        <p>
          Explanation: To create a heading in HTML, we can use the &lt;h2&gt;
          tag. We can adjust the font size using the font-size property.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<h2 style="font-size: 1.5cm;">
  Your Name Here
</h2>
`,
  },
  {
    exerciseId: 'css-units-2',
    taskHeading: 'Pica Spacing Fun',
    question: (
      <div>
        <p>Write a code to display a paragraph with spacing of 1 pica.</p>
        <p>
          Explanation: Picas are a unit of measurement used in typography. We
          can use the line-height property to adjust the spacing. To use a pica,
          we convert it to pixels (1 pica = 16 pixels approximately).
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<p style="line-height: 16px;">
  This paragraph has a spacing of 1 pica.
</p>
`,
  },
  {
    exerciseId: 'css-boxModel',
    taskHeading: 'Boxed Style Master',
    question: (
      <div>
        <p>
          Write a code to display content in a box of red color with border
          width 100px, background color lightgreen with margin of 30px and
          padding of 40px.
        </p>
        <p>
          Explanation: We can create a box using a div element, applying styles
          for background color, border width, margin, and padding.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<div style="border: 100px solid red; background-color: lightgreen; margin: 30px; padding: 40px;">
  This is a styled box with a red border, light green background, and specified margins and paddings.
</div>`,
  },
  {
    exerciseId: 'css-border',
    taskHeading: 'Double Border Charm',
    question: (
      <div>
        <p>Write a code to display your name with double border.</p>
        <p>
          Explanation: To create a double border, we can use the border-style
          property in conjunction with border-width.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<div style="border: 5px double black; padding: 10px;">
  Your Name Here
</div>`,
  },
  {
    exerciseId: 'css-margins',
    taskHeading: 'Margin Masterpiece Display',
    question: (
      <div>
        <p>
          Write a code to display "Full Stack Bootcamp" with top margin of
          100px, a right margin of 75px, a bottom margin of 50px, and a left
          margin of 80px.
        </p>
        <p>
          Explanation: To create margins around an element, we can use the
          margin property with specific values for top, right, bottom, and left.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `
<h1 style="margin: 100px 75px 50px 80px;">
  Full Stack Bootcamp
</h1>
`,
  },
  {
    exerciseId: 'css-padding',
    taskHeading: 'Padding Playground',
    question: (
      <div>
        <p>
          Write a code to display "Full Stack Bootcamp" with top padding of
          100px, a right padding of 75px, a bottom padding of 50px, and a left
          padding of 80px.
        </p>
        <p>
          Explanation: To create paddings around an element, we can use the
          padding property with specific values for top, right, bottom, and
          left.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<h1 style="padding: 100px 75px 50px 80px;">
  Full Stack Bootcamp
</h1>`,
  },
  {
    exerciseId: 'css-inlineBlock',
    taskHeading: 'Inline Block Magic',
    question: (
      <div>
        <p>
          Write a code to display your name inline and block line using CSS.
        </p>
        <p>
          Explanation: We can use the display property to set elements as inline
          or block.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<span style="display: inline;">Your Name Inline</span>
<br>
<span style="display: block;">Your Name Block</span>`,
  },
  {
    exerciseId: 'css-selector',
    taskHeading: 'Centered Red Perfection',
    question: (
      <div>
        <p>
          Write a code to display a paragraph and then using selectors assign it
          red colour and center alignment.
        </p>
        <p>
          Explanation: We can use CSS selectors to style the paragraph after
          defining it in HTML.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<p class="centered-red">This is a centered paragraph in red color.</p>

<style>
  .centered-red {
    color: red;
    text-align: center;
  }
</style>`,
  },
  {
    exerciseId: 'css-specificity',
    taskHeading: 'Belong Bold Finish',
    question: (
      <div>
        <p>
          Write a code to display "belong" in red colour and centre aligned. At
          the last add p element for your name in blue color.
        </p>
        <p>
          Explanation: We can combine text elements with specific styles and
          colors.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<p style="color: red; text-align: center;">belong</p>
<p style="color: blue;">Your Name Here</p>`,
  },
  {
    exerciseId: 'css-inheritance',
    taskHeading: 'AI Style Inherit',
    question: (
      <div>
        <p>
          Write a code to display a paragraph about AI with a heading Artificial
          Intelligence. The paragraph should inherit its color and margins from
          the heading.
        </p>
        <p>
          Explanation: We can style the heading and then use inheritance for the
          paragraph.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<h2 style="color: green; margin: 20px;">
  Artificial Intelligence
</h2>
<p style="color: inherit; margin: inherit;">
  AI refers to the simulation of human intelligence in machines that are programmed to think like humans and mimic their actions.
</chunk_to_edit_0>
<chunk_to_edit_1>
</p>`,
  },
  // {
  //   exerciseId: 'bootstrap-carousel',
  //   question:
  //     'Create a Bootstrap carousel that cycles through three images. Each slide should feature a unique image and a caption.',
  //   code: ``,
  // },
  // {
  //   exerciseId: 'bootstrap-embedding-videos',
  //   question:
  //     'Embed a responsive video into a webpage using Bootstrap. Ensure the video maintains its aspect ratio and is responsive to screen size changes.',
  //   code: ``,
  // },
  // {
  //   exerciseId: 'bootstrap-container',
  //   question:
  //     "Use Bootstrap's container class to build a webpage layout. Design one version with a fixed-width container and another with a full-width (fluid) container. Demonstrate the responsiveness of each container.",
  //     code: ``,
  //   },
  // {
  //   exerciseId: 'bootstrap-row',
  //   question:
  //     'Construct a Bootstrap row with multiple columns. Within this row, create a layout where columns adjust their number based on screen size (e.g., 4 columns on a large screen, 2 on a medium screen, and 1 on a small screen).',
  //     code: ``,
  //   },
  // {
  //   exerciseId: 'bootstrap-column',
  //   question:
  //     "Utilize Bootstrap's grid system to create a row divided into four equal-width columns. Populate each column with content of your choice, such as text, images, or buttons.",
  //     code: ``,
  //   },
  // {
  //   exerciseId: 'bootstrap-utilities',
  //   question:
  //     'Implement a design using Bootstrap Utilities to demonstrate the application of common CSS properties, such as color, display, and positioning, to various elements in a webpage.',
  //     code: ``,
  //   },
  // {
  //   exerciseId: 'bootstrap-display-utilities',
  //   question:
  //     "Use Bootstrap's display utility classes to create a layout where certain elements are visible only on specific screen sizes (e.g., an element that only displays on medium and large screens).",
  //     code: ``,
  //   },
  // {
  //   exerciseId: 'bootstrap-flex-utilities',
  //   question:
  //     "Create a flexbox layout using Bootstrap's flex utility classes. This layout should align items in various ways (e.g., centering items, spacing items evenly) to demonstrate the capabilities of Bootstrap's flex utilities.",
  //     code: ``,
  //   },
  // {
  //   exerciseId: 'bootstrap-sizing-utilities',
  //   question:
  //     "Apply Bootstrap's sizing utilities to set specific widths and heights for different elements on a webpage. Demonstrate how these utilities can be used to create a harmonious layout.",
  //     code: ``,
  //   },
  // {
  //   exerciseId: 'bootstrap-spacing-utilities',
  //   question:
  //     "Design a webpage layout using Bootstrap's spacing utilities to apply margin and padding to elements. Show how these utilities can be used to create a well-spaced and visually appealing layout.",
  //     code: ``,
  //   },
  {
    exerciseId: 'bootstrap-layout',
    taskHeading: 'Layout Mastery Starts',
    question: (
      <div>
        <p>Create a responsive layout with two columns that stack on small screens.</p>
      </div>
    ),
    code: ``,

    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-12 bg-light">Column 1</div>
              <div class="col-md-6 col-sm-12 bg-secondary text-white">Column 2</div>
            </div>
          </div>
        </body>
      </html>
      /* col-md-6: Each column takes up 50% width on medium and larger screens.
         col-sm-12: Columns stack (100% width) on small screens.
         bg-light and bg-secondary: Background colors for visual distinction. */
    `,
  },
  {
    exerciseId: 'bootstrap-typography',
    taskHeading: 'Text Styling Fun',
    question: (
      <div>
        <p>Use Bootstrap to display a heading and a paragraph with different text styles.</p>
      </div>
    ),
    
    code: ``,

    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <h1 class="text-danger">Big Bold Heading</h1>
            <p class="text-muted">This is a muted paragraph using Bootstrap typography.</p>
          </div>
        </body>
      </html>
      /* text-danger: Applies a red color to the heading text.
         text-muted: Applies a gray muted color to the paragraph text. */
    `,
  },
  {
    exerciseId: 'bootstrap-button',
    taskHeading: 'Button Style Fun',
    question: (
      <div>
        <p>Create two Bootstrap buttons, one red and one green.</p>
      </div>
    ),
    code: ``,

    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <button class="btn btn-danger">Red Button</button>
            <button class="btn btn-success">Green Button</button>
          </div>
        </body>
      </html>
      /* btn-danger: Creates a red button.
         btn-success: Creates a green button. */
    `,
  },
  {
    exerciseId: 'bootstrap-image',
    taskHeading: 'Image Styling Start',
    question: (
      <div>
        <p>Display a responsive image using Bootstrap classes.</p>
      </div>
    ),
    code: ``,

    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <img src="image.jpg" class="img-fluid" alt="Responsive image">
          </div>
        </body>
      </html>
      /* img-fluid: Makes the image responsive, scaling with the width of its parent container. */
    `,
  },
  {
    exerciseId: 'bootstrap-alert',
    taskHeading: 'Alert Magic Here',
    question: (
      <div>
        <p>Create a Bootstrap alert message with success and warning styles.</p>
      </div>
    ),
   
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <div class="alert alert-success">Success! You did it!</div>
            <div class="alert alert-warning">Warning! Check this out.</div>
          </div>
        </body>
      </html>
      /* alert-success: Styles the alert with a green background for success messages.
         alert-warning: Styles the alert with a yellow background for warning messages. */
    `,
  },
  {
    exerciseId: 'bootstrap-table',
    taskHeading: 'Table Magic Begins',
    question: (
      <div>
        <p>Create a simple Bootstrap table with three columns and a header row.</p>
      </div>
    ),
    
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <table class="table">
              <thead>
                <tr>
                  <th>Heading 1</th>
                  <th>Heading 2</th>
                  <th>Heading 3</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 3</td>
                </tr>
                <tr>
                  <td>Row 2, Col 1</td>
                  <td>Row 2, Col 2</td>
                  <td>Row 2, Col 3</td>
                </tr>
              </tbody>
            </table>
          </div>
        </body>
      </html>
      /* table: Applies basic Bootstrap styling to the table.
         thead: Wraps the header row.
         th: Defines each header cell.
         tbody: Contains the rows of the table body. */
    `,
  },
  {
    exerciseId: 'bootstrap-form',
    taskHeading: ' Form Fun Time',
    question: (
      <div>
        <p>Create a simple Bootstrap form with name and email input fields.</p>
      </div>
    ),
   
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <form>
              <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name">
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email">
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </body>
      </html>
      /* form-control: Adds Bootstrap styling to input fields.
         form-label: Styles the label elements.
         mb-3: Adds margin-bottom to space out form groups.
         btn btn-primary: Styles the submit button with a blue color. */
    `,
  },
  {
    exerciseId: 'bootstrap-badge',
    taskHeading: ' Badge Magic Done',
    question: (
      <div>
        <p>Create a Bootstrap button with a badge showing a notification count.</p>
      </div>
    ),
   
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <button class="btn btn-primary">
              Notifications <span class="badge bg-danger">4</span>
            </button>
          </div>
        </body>
      </html>
      /* btn-primary: Styles the button with Bootstrap's primary button color.
         badge: Styles the notification count.
         bg-danger: Applies a red background to the badge. */
    `,
  },
  {
    exerciseId: 'bootstrap-grid',
    taskHeading: ' Grid Your Way',
    question: (
      <div>
        <p>Create a grid with two rows. The first row should have two columns of equal width, and the second row should have three columns with custom widths (e.g., 4, 4, and 4 units).</p>
      </div>
    ),
   
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <div class="row">
              <div class="col-md-6">Column 1</div>
              <div class="col-md-6">Column 2</div>
            </div>
            <div class="row">
              <div class="col-md-4">Column 3</div>
              <div class="col-md-4">Column 4</div>
              <div class="col-md-4">Column 5</div>
            </div>
          </div>
        </body>
      </html>
      /* col-md-6: Each column in the first row takes up half the width.
         col-md-4: Each column in the second row takes up one-third of the width. */
    `,
  },
  {
    exerciseId: 'bootstrap-responsive-breakpoint',
    taskHeading: ' Breakpoint Wizardry',
    question: (
      <div>
        <p> Create a Bootstrap layout where the columns stack on small screens and display in two columns on medium screens and above.</p>
      </div>
    ),
   
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6">Column 1</div>
              <div class="col-sm-12 col-md-6">Column 2</div>
            </div>
          </div>
        </body>
      </html>
      /* col-sm-12: Each column takes full width on small screens.
         col-md-6: Each column takes half the width on medium screens and above. */
    `,
  },
  {
    exerciseId: 'bootstrap-navbar',
    taskHeading: 'Navigation Ninja',
    question: (
      <div>
        <p> Create a basic Bootstrap navbar with a brand name and three links (Home, About, Contact).</p>
      </div>
    ),
    
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <a class="navbar-brand" href="#">Brand</a>
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link" href="#">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#">About</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Contact</a></li>
              </ul>
            </div>
          </nav>
        </body>
      </html>
      /* navbar: Styles the navigation bar.
         navbar-expand-lg: Enables the navbar to expand on larger screens.
         navbar-light: Applies light styling to the navbar.
         bg-light: Sets a light background color.
         nav-link: Styles the navigation links. */
    `,
  },
  {
    exerciseId: 'bootstrap-card',
    taskHeading: 'Card Creation Fun',
    question: (
      <div>
        <p> Create a Bootstrap card with an image at the top, a card title, and some text in the body.</p>
      </div>
    ),
    
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <div class="card" style="width: 18rem;">
              <img src="image.jpg" class="card-img-top" alt="Card Image">
              <div class="card-body">
                <h5 class="card-title">Card Title</h5>
                <p class="card-text">This is a card with an image and some text.</p>
              </div>
            </div>
          </div>
        </body>
      </html>
      /* card: Styles the card component.
         card-img-top: Styles the image at the top of the card.
         card-body: Contains the content of the card.
         card-title: Styles the card title.
         card-text: Styles the card text. */
    `,
  },
  {
    exerciseId: 'bootstrap-modal',
    taskHeading: 'Modal Magic Moment',
    question: (
      <div>
        <p> Create a Bootstrap modal that pops up when a button is clicked.</p>
      </div>
    ),
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal">Open Modal</button>
  
            <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">Modal Heading</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    This is the content of the modal.
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
        </body>
      </html>
      /* modal: Styles the modal component.
         modal-dialog: Styles the dialog box of the modal.
         modal-content: Contains the content of the modal.
         modal-header: Styles the header of the modal.
         modal-title: Styles the modal title.
         btn-close: Styles the close button in the modal.
         modal-body: Contains the main content of the modal.
         modal-footer: Styles the footer of the modal, often contains action buttons. */
    `,
  },
  
  {
    exerciseId: 'bootstrap-tooltip',
    taskHeading: 'Tooltip Magic Time',
    question: (
      <div>
        <p> Add a tooltip to a button that displays the text 'Click me!' when hovered over.</p>
      </div>
    ),
    
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <button type="button" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Click me!">
              Hover over me
            </button>
          </div>
  
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
          <script>
            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
              return new bootstrap.Tooltip(tooltipTriggerEl);
            });
          </script>
        </body>
      </html>
      /* tooltip: Styles the tooltip component.
         btn-secondary: Styles the button with a secondary color scheme.
         data-bs-toggle: Activates the tooltip on the button.
         data-bs-placement: Specifies the position of the tooltip relative to the button.
         title: The text displayed in the tooltip. */
    `,
  },
  {
    exerciseId: 'bootstrap-collapsible-panel',
    taskHeading: 'Collapse Fun Begins',
    question: (
      <div>
        <p> Create a Bootstrap collapsible panel that toggles open and closed.</p>
      </div>
    ),
   
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <p>
              <a class="btn btn-primary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                Toggle Collapse
              </a>
            </p>
            <div class="collapse" id="collapseExample">
              <div class="card card-body">
                This is a collapsible panel using Bootstrap.
              </div>
            </div>
          </div>
  
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
        </body>
      </html>
      /* collapse: Styles the collapsible component.
         btn-primary: Styles the button with a primary color scheme.
         data-bs-toggle: Activates the collapse behavior.
         href: The target element to toggle, identified by its ID. */
    `,
  },
  {
    exerciseId: 'bootstrap-grid-alignment',
    taskHeading: 'Grid Align Magic',
    question: (
      <div>
        <p> Create a grid layout where items are centered both vertically and horizontally using Bootstrap classes.</p>
      </div>
    ),
    
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <div class="row justify-content-center align-items-center" style="height: 100vh;">
              <div class="col-4 bg-light text-center">Centered Content</div>
            </div>
          </div>
        </body>
      </html>
      /* justify-content-center: Horizontally centers the column within the row.
         align-items-center: Vertically centers the column within the row.
         style="height: 100vh;": Sets the row's height to 100% of the viewport height for vertical centering.
         bg-light: Adds a light background color to the column.
         text-center: Centers the text inside the column. */
    `,
  },
  {
    exerciseId: 'bootstrap-jumbotron',
    taskHeading: 'Jumbotron Fun Times',
    question: (
      <div>
        <p> Create a simple Bootstrap jumbotron with a heading and a paragraph.</p>
      </div>
    ),
    
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <div class="jumbotron p-5 bg-light">
              <h1 class="display-4">Hello, World!</h1>
              <p>This is a simple Bootstrap jumbotron.</p>
            </div>
          </div>
        </body>
      </html>
      /* p-5: Adds padding to the jumbotron for better spacing.
         bg-light: Applies a light background color.
         display-4: Sets the heading to a larger size suitable for jumbotrons. */
    `,
  },
  {
    exerciseId: 'bootstrap-pagination',
    taskHeading: 'Paging Done Right',
    question: (
      <div>
        <p> Create a Bootstrap pagination layout with three page links.</p>
      </div>
    ),
    idealSolution: `
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
        </head>
        <body>
          <div class="container">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
              </ul>
            </nav>
          </div>
        </body>
      </html>
      /* This pagination layout includes:
         - .pagination: Bootstrap class for pagination styling.
         - .page-item: Defines each pagination item.
         - .page-link: Styles the links inside the pagination items. */
    `,
  },
  {
    exerciseId: 'tailwind-colors',
    taskHeading: ' Colorful Hover Magic',
    question: (
      <div>
        <p>Create a div with a light blue background and white text. The text should change to a darker shade of blue when hovered.</p>
      </div>
    ),
    idealSolution: `
<div class="bg-blue-200 text-white hover:text-blue-800 p-4">
    Hover over me!
</div>
/* bg-blue-200: Light blue background.
    text-white: White text.
    hover:text-blue-800: Text changes to dark blue when hovered. */
`,
  },
  {
    exerciseId: 'tailwind-spacing',
    taskHeading: 'Spacing Sensation',
    question: (
      <div>
        <p>Create a box with 1rem padding on all sides and a 0.5rem margin at the top and bottom.</p>
      </div>
    ),
    idealSolution: `
<div class="p-4 mt-2 mb-2 bg-gray-200">
    This box has padding and margins!
</div>
/* p-4: 1rem padding.
    mt-2 mb-2: 0.5rem margin on top and bottom. */
`,
  },
  {
    exerciseId: 'tailwind-fonts',
    taskHeading: 'Bold Text Style',
    question: (
      <div>
        <p>Create a paragraph with bold, extra-large text and increase the letter spacing.</p>
      </div>
    ),
    idealSolution: `
<p class="text-xl font-bold tracking-wide">
    This is bold, large text with wide letter spacing!
</p>
/* text-xl: Extra-large text.
    font-bold: Bold font weight.
    tracking-wide: Increases letter spacing. */
`,
  },
  {
    exerciseId: 'tailwind-layout',
    taskHeading: 'Flexbox Fun Time',
    question: (
      <div>
        <p>Create a flexbox layout with two equally spaced boxes that are centered horizontally and vertically.</p>
      </div>
    ),
    idealSolution: `
<div class="flex items-center justify-center h-screen">
    <div class="flex-1 bg-red-200 p-4">Box 1</div>
    <div class="flex-1 bg-blue-200 p-4">Box 2</div>
</div>
/* flex: Enables flexbox.
    items-center justify-center: Centers items horizontally and vertically.
    flex-1: Each box takes up equal space. */
`,
  },
  {
    exerciseId: 'tailwind-borders',
    taskHeading: 'Border Beauty',
    question: (
      <div>
        <p>Create a box with a 4px green border and rounded corners.</p>
      </div>
    ),
    idealSolution: `
<div class="border-4 border-green-500 rounded-lg p-4">
    Box with green border and rounded corners!
</div>
/* border-4: 4px border.
    border-green-500: Green border color.
    rounded-lg: Rounded corners. */
`,
  },
  {
    exerciseId: 'tailwind-shadows',
    taskHeading: 'Shadow Playtime',
    question: (
      <div>
        <p>How can you apply a large shadow to an element using Tailwind CSS, and how would you customize it to create a more intense shadow effect?</p>
      </div>
    ),
    idealSolution: `
<div class="shadow-lg p-4 bg-white">
    This box has a large shadow!
</div>

/* Custom Shadow Example in tailwind.config.js:
    module.exports = {
        theme: {
            extend: {
                boxShadow: {
                'custom': '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                }
            }
        }
    } 
*/
`,
  },
  {
    exerciseId: 'tailwind-zIndex',
    taskHeading: 'Z-Index Power',
    question: (
      <div>
        <p>What Tailwind CSS class would you use to set a z-index of 10 on an element, and how would you define a custom z-index of 100 in the configuration?</p>
      </div>
    ),
    idealSolution: `
<div class="relative z-10 p-4 bg-blue-200">
    This box has a z-index of 10!
</div>

/* Custom Z-Index Example in tailwind.config.js:
    module.exports = {
        theme: {
            extend: {
                zIndex: {
                '100': '100',
                }
            }
        }
    } 
*/
`,
  },
  {
    exerciseId: 'tailwind-transitions',
    taskHeading: ' Transition Smoothly',
    question: (
      <div>
        <p>How do you apply a smooth background color transition and scale effect on hover using Tailwind CSS, and how can you control the transition duration?</p>
      </div>
    ),
    idealSolution: `
<div class="bg-blue-500 p-4 hover:bg-blue-700 hover:scale-110 transition-all duration-500">
    Hover to see color and scale change smoothly!
</div>
/* transition-all: Applies smooth transitions.
    duration-500: Sets transition duration to 500ms.
    hover:scale-110: Scales element by 10% on hover. */
`,
  },

  {
    exerciseId: 'js-introduction',
    taskHeading: 'Hello World',
    question: (
      <div>
        <p>
        Write a simple JavaScript program that prints "Hello, JavaScript!" to the console.
        </p>
                
      </div>
    ),
    idealSolution: `<html>
<body>
  <script>
    console.log("Hello, JavaScript!");
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-basics-1',
    taskHeading: 'Mix & Match Variables',
    question: (
      <div>
        <p>
          Write a JavaScript code where you declare two variables: one for your
          name (as a string) and another for your age (as a number). Then create
          a new variable that combines both values in a sentence like "My name
          is John and I am 25 years old." Finally, log this sentence to the
          console
        </p>
        <p>
          Hint: Use let or const for variable declarations, and the + operator
          for string concatenation.
        </p>
                
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    // Mix & Match Variables
    let name = 'John';
    let age = 25;
    let sentence = 'My name is ' + name + ' and I am ' + age + ' years old.';
    console.log(sentence);
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-basics-2',
    taskHeading: 'Type Detective',
    question: (
      <div>
        <p>
          Declare a variable with an array that includes the names of 3 of your
          favorite books. Then, log the type of this variable using the typeof
          operator. What does JavaScript say the type of your array is?
        </p>
        
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    // Type Detective
    let favoriteBooks = ['Harry Potter', 'The Hobbit', '1984'];
    console.log(typeof favoriteBooks);  // Outputs: "object"

  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-basics-3',
    taskHeading: 'Data Type Switcheroo',
    question: (
      <div>
       <p>Declare a variable dynamicVar and assign it a number value. After
          that, change its value to a string and then to a boolean. Log the type
          of dynamicVar after each change using the typeof operator.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    // Data Type Switcheroo
    let dynamicVar = 42;
    console.log(typeof dynamicVar);  // Outputs: "number"

    dynamicVar = 'Hello!';
    console.log(typeof dynamicVar);  // Outputs: "string"

    dynamicVar = true;
    console.log(typeof dynamicVar);  // Outputs: "boolean"
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-control-flow-1',
    taskHeading: 'Temperature Check',
    question: (
      <div>
        <p>
          Write a JavaScript program to determine if the temperature (stored in
          a variable) is hot, cold, or moderate. Print the message accordingly.
        </p>
       
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    // Temperature Check
    let temperature = 30;

    if (temperature > 30) {
      console.log("It's hot!");
    } else if (temperature < 15) {
      console.log("It's cold!");
    } else {
      console.log("It's moderate!");
    }

  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-control-flow-2',
    taskHeading: '"Pass or Fail"',
    question: (
      <div>       
        <p>Given a student's exam score, write a program that prints "Pass" if
          the score is 50 or higher, and "Fail" if it's below 50.
        </p>
        
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    // Pass or Fail
    let score = 45;

    if (score >= 50) {
      console.log('Pass');
    } else {
      console.log('Fail');
    }   
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-control-flow-3',
    taskHeading: 'Countdown Fun',
    question: (
      <div>
        <p>
          Write a for loop that counts down from 10 to 1, printing each number.
          When the loop ends, print "Liftoff!"
        </p>
        
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    // Countdown Fun
    for (let i = 10; i > 0; i--) {
      console.log(i);
    }
    console.log('Liftoff!');
 
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-control-flow-4',
    taskHeading: '"Odd or Even?"',
    question: (
      <div>
        <p>
          Write a for loop that iterates from 1 to 10. For each number, print
          whether it's "Odd" or "Even."
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    // Odd or Even?
    for (let i = 1; i <= 10; i++) {
      if (i % 2 === 0) {
        console.log(i + ' is even');
      } else {
        console.log(i + ' is odd');
      }
    }
  </script>
</body>
</html>`,
  },
    
  {
    exerciseId: 'js-functions',
    taskHeading: ' Sum It Up',
    question: (
      <div>
        <p>
          Develop a JavaScript function that takes two numbers as arguments and returns their sum. Test this function with different sets of numbers.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    function addNumbers(a, b) {
      return a + b;
    }

    console.log(addNumbers(5, 10));  // Outputs: 15
    console.log(addNumbers(2, 3));   // Outputs: 5
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-arrays-and-objects',
    taskHeading: 'Array Object Fun',
    question: (
      <div>
        <p>
          Create an array of objects in JavaScript, where each object represents a person with properties for name and age. Write a function to log each person's name and age to the console.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    var people = [
      { name: "John", age: 25 },
      { name: "Jane", age: 30 },
      { name: "Alice", age: 22 }
    ];

    function logPeople(arr) {
      arr.forEach(function(person) {
        console.log(person.name + " is " + person.age + " years old");
      });
    }

    logPeople(people);
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-dom-manipulation',
    taskHeading: ' New Div Fun',
    question: (
      <div>
        <p>
          Write JavaScript code to create a new &lt;div&gt; element with some text inside it, and append it to the body of an HTML document.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <script>
    var newDiv = document.createElement("div");
    newDiv.textContent = "Hello from the new div!";
    document.body.appendChild(newDiv);
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-dom-event',
    taskHeading: 'Hover Button Magic',
    question: (
      <div>
        <p>
          Implement JavaScript code that changes the color of a button when the mouse hovers over it. Restore the original color when the mouse leaves the button area.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <button id="colorButton">Hover over me!</button>

  <script>
    var button = document.getElementById("colorButton");

    button.onmouseover = function() {
      button.style.backgroundColor = "yellow";
    }

    button.onmouseout = function() {
      button.style.backgroundColor = "";
    }
  </script>
</body>
</html>`,
  },
  {
    exerciseId: 'js-dom-event-listener',
    taskHeading: 'Form Submit Alert',
    question: (
      <div>
        <p>
          Add an event listener to a form's submit button in JavaScript. When the button is clicked, it should display an alert with a message "Form submitted!" and prevent the default form submission action.
        </p>
      </div>
    ),
    code: ``,
    idealSolution: `<html>
<body>
  <form id="myForm">
    <input type="text" name="username">
    <button type="submit">Submit</button>
  </form>

  <script>
    document.getElementById("myForm").addEventListener("submit", function(event) {
      event.preventDefault();
      alert("Form submitted!");
    });
  </script>
</body>
</html>`,
  },
];

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const GridAlignment = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'> Grid Alignment Options</span>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>Aligning Content Within the Grid</span>
        <p>
          Bootstrap offers several alignment options that make it easy to
          control the position of content within the grid. You can align content
          vertically, horizontally, or use utility classes like align-items,
          justify-content, and align-self.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Grid Alignment Example'}
          codeStr={`<div class="row align-items-center">
  <div class="col">
    First Column
  </div>
  <div class="col">
    Second Column
  </div>
</div>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>align-items-center:</strong> Vertically centers all items in
            the row.
          </li>
        </ul>
        <p>
          These utilities help you control how the content is positioned and
          displayed within its container, providing flexibility in layout
          design.
        </p>
      </div>
    </div>
  );
};

export default GridAlignment;

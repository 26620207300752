import React from 'react';

import SelfDiagnostic from './selfDiagnostic';
import TestAnalysis from './testAnalysis';

const Index = ({ topic, setTopic, enrollData, setReFetch }) => {
  
  return (
    <div>
      {topic === 'self-diagnostic-test' && (
        <SelfDiagnostic
          enrollData={enrollData}
          setReFetch={setReFetch}
          setTopic={setTopic}
        />
      )}
      {topic === 'test-analysis' && (
        <TestAnalysis enrollData={enrollData} setReFetch={setReFetch} />
      )}{' '}
    </div>
  );
};

export default Index;

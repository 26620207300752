import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import { exerciseData } from './ExerciseData';

const IdealSolution = ({ exerciseid }) => {
  const exercise = exerciseData.find((el) => el.exerciseId === exerciseid);
  const idealSolution =
    exercise?.idealSolution || 'No ideal solution available.';
  const [solutionShown, setSolutionShown] = useState(false);

  return (
    <div className='pb-2 space-y-4'>
      {solutionShown && (
        <React.Fragment>
          <h3 className='font-semibold text-lg'>Ideal Solution:</h3>
          <Editor
            height='30vh'
            defaultLanguage='html'
            value={idealSolution}
            options={{
              readOnly: true,
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
            }}
            theme='vs-dark'
          />
        </React.Fragment>
      )}

      <button
        className='px-4 py-2 bg-primary rounded-lg text-white font-medium'
        onClick={() => {
          setSolutionShown(!solutionShown);
        }}>
        {solutionShown ? 'Hide Solution' : 'Show Solution'}
      </button>
    </div>
  );
};

export default IdealSolution;

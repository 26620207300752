import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const TailwindLayout = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Layout</span>
      <div className='flex flex-col gap-2'>
        <span>
          Tailwind CSS offers powerful classes to facilitate layout management,
          making it easy to create responsive and organized designs using
          Flexbox and Grid systems. Below are detailed explanations and examples
          for each:
        </span>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>1. Flexbox Layouts</span>
          <span>
            Flexbox is a one-dimensional layout method that allows you to
            arrange items in a row or column. With Tailwind, you can quickly set
            up a flexbox layout using the flex class.
          </span>
          <span>Key Flexbox Classes:</span>
          <ul className='list-disc pl-5'>
            <li>flex: Enables flexbox on a container.</li>
            <li>flex-row: Arranges items in a row (default).</li>
            <li>flex-col: Arranges items in a column.</li>
            <li>flex-1: Makes a flex item grow to fill available space.</li>
            <li>
              justify-{'{value}'}: Aligns items along the main axis (e.g.,
              justify-center, justify-between).
            </li>
            <li>
              items-{'{value}'}: Aligns items along the cross axis (e.g.,
              items-center, items-start).
            </li>
          </ul>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Flexbox Layout Example'}
            codeStr={`<div class="flex">
<div class="flex-1 p-4 bg-red-200">Box 1</div>
<div class="flex-1 p-4 bg-blue-200">Box 2</div>
</div>`}
          />
          <p>In this example:</p>
          <ul className='list-disc pl-5'>
            <li>The container uses the flex class to enable flexbox.</li>
            <li>
              Each box has the flex-1 class, allowing them to grow equally and
              share the available space. The p-4 class adds padding, while the
              background colors differentiate the boxes.
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>2. Grid Layouts</span>
          <span>
            Grid is a two-dimensional layout system that enables you to create
            complex layouts with rows and columns. Tailwind simplifies grid
            creation with its utility classes.
          </span>
          <span>Key Grid Classes:</span>
          <ul className='list-disc pl-5'>
            <li>grid: Enables grid on a container.</li>
            <li>
              grid-cols-{'{n}'}: Defines the number of columns (e.g.,
              grid-cols-3 for three columns).
            </li>
            <li>
              grid-rows-{'{n}'}: Defines the number of rows (e.g., grid-rows-2
              for two rows).
            </li>
            <li>
              col-span-{'{n}'}: Specifies how many columns an item should span
              (e.g., col-span-2).
            </li>
            <li>
              row-span-{'{n}'}: Specifies how many rows an item should span.
            </li>
          </ul>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Grid Layout Example'}
            codeStr={`<div class="grid grid-cols-2 gap-4">
<div class="p-4 bg-green-200">Item 1</div>
<div class="p-4 bg-yellow-200">Item 2</div>
<div class="p-4 bg-purple-200">Item 3</div>
<div class="p-4 bg-orange-200">Item 4</div>
</div>`}
          />
          <p>In this example:</p>
          <ul className='list-disc pl-5'>
            <li>
              The container uses the grid class to enable the grid layout.
            </li>
            <li>
              The grid-cols-2 class specifies that the layout will have two
              columns.
            </li>
            <li>The gap-4 class adds spacing between the grid items.</li>
            <li>
              Each item has padding applied with p-4 and different background
              colors.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TailwindLayout;

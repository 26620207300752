import React from 'react';
import { SingleCode } from '../../CodeDisplay';

const TailwindIntroduction = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Tailwind Introduction</span>
      <div className='flex flex-col gap-2'>
        <p>
          Tailwind CSS is a utility-first CSS framework for quickly building
          custom designs. It provides low-level utility classes that can be
          combined to build complex user interfaces efficiently.
        </p>
        <ul className='list-disc pl-5 space-y-2'>
          <li>
            <strong>Utility-first approach:</strong> Instead of writing custom
            CSS for each design, Tailwind provides utility classes like{' '}
            <SingleCode codeLine={`p-4`} />,{' '}
            <SingleCode codeLine={`text-center`} />, and{' '}
            <SingleCode codeLine={`bg-blue-500`} /> to style elements directly
            in the HTML.
          </li>
          <li>
            <strong>Customization:</strong> Tailwind allows you to customize the
            design system (spacing, colors, fonts) through its configuration
            file.
          </li>
          <li>
            <strong>Responsive design:</strong> Built-in responsive utilities (
            <SingleCode codeLine={`md:`} />, <SingleCode codeLine={`lg:`} />,
            etc.) make it easy to adjust styles for different screen sizes.
          </li>
          <li>
            <strong>Component-friendly:</strong> Tailwind integrates well with
            modern component-based frameworks like React, Vue, and Angular.
          </li>
        </ul>
        <h3 className='font-semibold mt-4'>Installation and Setup</h3>
        <p>
          To use Tailwind, you can either install it via npm or include it via
          CDN in your HTML file.
        </p>
        <h4 className='font-semibold mt-2'>Via CDN:</h4>
        <p>
          Add the following link tag to your HTML file's{' '}
          <SingleCode codeLine={`<head>`} /> section to quickly use Tailwind CSS
          without additional setup:
        </p>
        <SingleCode
          codeLine={`<head>
<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.1.2/dist/tailwind.min.css" rel="stylesheet">
</head>`}
        />
        <h4 className='font-semibold mt-4'>Via npm:</h4>
        <p>For more control over your project, install Tailwind via npm:</p>
        <SingleCode
          codeLine={`npm install tailwindcss
npx tailwindcss init`}
        />
        <p className='mt-2'>
          The <SingleCode codeLine={`tailwind.config.js`} /> file allows you to
          customize the default Tailwind settings.
        </p>
      </div>
    </div>
  );
};

export default TailwindIntroduction;

import React from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const Containers = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Containers</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/UG45sVvR6GU'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          Containers are used for styling and layout purposes, providing a way
          to apply styles or structure content.
        </span>
        <span>
          Container elements like <SingleCode codeLine={`<div>`} /> and{' '}
          <SingleCode codeLine={`<span>`} /> are used to group and style
          content. <SingleCode codeLine={`<div>`} /> is a block-level container,
          typically used for grouping elements, while{' '}
          <SingleCode codeLine={`<span>`} /> is an inline container often used
          for styling specific parts of text.
        </span>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<div>
  <p>This is inside a division.</p>
</div>
<span>This is inside a span.</span>
`}
      />
    </div>
  );
};

export default Containers;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const Badges = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Badges</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/p0jXDEXd_FI?si=hkt0-zcspXXG48i6'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>What are Bootstrap Badges?</span>
        <p>
          Badges are small circles or rectangles that display information like
          counts or status labels. You often see them next to items in a list or
          on buttons to show notifications, updates, or categories.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Badge Example'}
          codeStr={`<span class="badge badge-primary">New</span>
<span class="badge badge-danger">3</span>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>Badge:</strong> A small indicator used for showing counts or
            status.
          </li>
          <li>
            <strong>badge-primary:</strong> A blue badge (can change based on
            the context).
          </li>
        </ul>
        <p>
          Badges are a great way to visually convey extra information in a
          compact space.
        </p>
      </div>
    </div>
  );
};

export default Badges;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const TailwindShadows = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Shadows</span>
      <div className='flex flex-col gap-2'>
        <span>
          Shadows are an effective way to create depth and dimension in web
          design, helping elements stand out and adding a sense of hierarchy.
          Tailwind CSS simplifies the process of adding shadows to elements with
          its utility classes. Here's a detailed overview of how to use box
          shadow utilities in Tailwind:
        </span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/1SaVT0E13Gs?si=EnAaZk0TOZa_kevE'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowFullScreen></iframe>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>1. Box Shadow Classes</span>
          <span>
            Tailwind CSS provides several predefined classes for different
            shadow sizes, ranging from subtle shadows to more pronounced
            effects.
          </span>
          <span>Key Box Shadow Classes:</span>
          <ul className='list-disc pl-5'>
            <li>shadow-sm: Applies a small shadow.</li>
            <li>shadow: Applies a default shadow.</li>
            <li>shadow-md: Applies a medium shadow.</li>
            <li>shadow-lg: Applies a large shadow.</li>
            <li>shadow-xl: Applies an extra-large shadow.</li>
            <li>shadow-2xl: Applies a more intense shadow.</li>
            <li>
              shadow-inner: Applies an inner shadow, which makes it look like
              the element is inset.
            </li>
            <li>shadow-none: Removes any shadow.</li>
          </ul>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>2. Example of Box Shadows</span>
          <span>Here's how to implement box shadows in a simple example:</span>
          <CodeDisplay
            title={'Tailwind Box Shadow Example'}
            codeStr={`<div class="shadow-lg p-4 bg-white">
  This box has a large shadow!
</div>`}
          />
          <p>In this example:</p>
          <ul className='list-disc pl-5'>
            <li>
              shadow-lg: Applies a large shadow to the div, creating a
              noticeable depth effect.
            </li>
            <li>
              p-4: Adds padding of 1rem (16px) inside the box, ensuring the
              content has space from the edges.
            </li>
            <li>
              bg-white: Sets the background color of the div to white, making
              the shadow more visible.
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>3. Customizing Shadows</span>
          <span>
            If the predefined shadows do not meet your design needs, you can
            customize the shadows using Tailwind's configuration file
            (tailwind.config.js). Here's how to add custom shadows:
          </span>
          <span>Example of Custom Shadow:</span>
          <CodeDisplay
            title={'Tailwind Custom Shadow Configuration'}
            codeStr={`module.exports = {
  theme: {
    extend: {
      boxShadow: {
        'custom': '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      }
    }
  }
}`}
          />
        </div>
      </div>
    </div>
  );
};

export default TailwindShadows;

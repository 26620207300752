import React from 'react';

const SelfDiagnosticInstructions = () => {
  return (
    <div className='space-y-6'>
    <div >
      <h1 class="text-2xl font-semibold">Self Diagnostic Test</h1>
      <p>
        This test is designed to help you assess your skill level post the bootcamp. Through a combination of skill-based MCQs
        and reflective activities, you'll gain valuable insights into your strengths and identify areas for improvement. The results
        will provide a clear understanding of your progress and guide your next steps.
      </p>
    </div>
  
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <div class="border rounded-lg p-3">
        <p class="text-lg">Time</p>
        <p class="text-xl font-semibold">30 mins</p>
      </div>
      <div class="border rounded-lg p-3">
        <p class="text-lg">Questions</p>
        <p class="text-xl font-semibold">30</p>
      </div>
      <div class="border rounded-lg p-3">
        <p class="text-lg">Sections</p>
        <p className='text-xl font-semibold'>2</p>
      </div>
    </div>
  
    <div class="mb-6">
      <h2 class="text-xl font-semibold mb-2">Section 1: Skill-based Questions</h2>
      <p class="mb-4">
        This section evaluates your knowledge across key front-end development areas.
      </p>
      <ul class="list-decimal list-inside ">
        <li>HTML & CSS: 6 questions</li>
        <li>Bootstrap & JavaScript: 6 questions</li>
        <li>Hosting & GitHub: 6 questions</li>
      </ul>
    </div>
  
    <div class="mb-6">
      <h2 class="text-xl font-semibold mb-2">Section 2: Reflective Activity</h2>
      <p class="mb-4">
        This section is designed to help you reflect on your journey and understand your confidence in your skill expertise
        compared to where you started.
      </p>
    </div>
  
    <div class="bg-[#F0F8D1] border border-[#78BC28] rounded-xl p-4 mb-6">
      <p class="font-semibold ">Instructions</p>
      <ul class="list-disc list-outside pl-5 space-y-2">
        <li>Click on "Start Test" to begin</li>
        <li>Once the test begins, you cannot use the sidebar navigation menu. If you do so, your test will be auto-submitted.</li>
        <li>Remember to finish on time and click on the submit button. If the timer runs out while you're still finishing the test, your answers will be auto-submitted.</li>
        <li>After submitting the quiz, you will be able to review your results.</li>
      </ul>
    </div>
  
    <div >
      <button class="bg-primary text-white px-12 py-2 rounded-md font-semibold hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50">
        Start test
      </button>
    </div>
  </div>
  
  );
};

export default SelfDiagnosticInstructions;

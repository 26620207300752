import React from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const Specificity = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Specificity</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/CHyPGSpIhSs'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>

        <span>
          When conflicting CSS rules arise, the browser determines which style
          to apply based on specificity, where the more specific rule takes
          precedence over others. Specificity acts as a hierarchy, assigning
          importance to different types of selectors used in CSS.
        </span>
        <span>
          Various selectors possess distinct specificity weights, influencing
          their priority in style application:
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Inline Styles`} />: Carry the highest
            specificity, directly applied to an element within its HTML tag.
          </li>
          <li>
            <SingleCode codeLine={`ID Selectors`} />: Hold a high specificity
            weight, targeting unique elements using the `#` symbol.
          </li>
          <li>
            <SingleCode
              codeLine={`Class Selectors, Attributes, and Pseudo-classes`}
            />
            : Possess moderate specificity, allowing styling for multiple
            elements sharing the same class, attribute, or state.
          </li>
          <li>
            <SingleCode codeLine={`Element Selectors`} />: Have lower
            specificity, targeting HTML elements universally by their tag names.
          </li>
        </ul>

        <span>
          Understanding specificity is crucial to predict which styles will take
          precedence when conflicting rules exist.
        </span>
        <span>
          The cascade and specificity of CSS rules govern how styles are applied
          and overridden, ensuring the intended styles are displayed.
        </span>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* Styles using different selectors */
/* Inline Style */
#unique {
  color: blue; /* Color applied using ID selector */
}
/* ID Selector */
.box {
  color: red; /* Color applied using class selector */
}
/* Class Selector */
div {
  color: green; /* Color applied using element selector */
}
`}
      />

      <span>
        In this example, the text color of the <SingleCode codeLine={`<div>`} />{' '}
        element will appear blue because the ID selector has higher specificity
        than the class selector or the element selector.
      </span>
    </div>
  );
};

export default Specificity;

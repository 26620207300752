import React from 'react';
import SelfDiagnosticInstructions from './SelfDiagnosticInstructions';

const Index = () => {
  return (
    <div>
      <SelfDiagnosticInstructions />
    </div>
  );
};

export default Index;
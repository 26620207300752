import React from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const ArrayObjects = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Array and objects</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/-oVdqCaL3DQ?si=w8z6jdHRj4-KAIAH'
          title='JavaScript Array and Objects'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen></iframe>
        <span>Objects are variables and they can contain many values.</span>
        <CodeDisplay
          title={``}
          codeStr={`let person= "Tom";
const person = { firstName: "Tom", lastName: "Bill", age: 23 };`}
        />
        <span>
          The values are expressed in the form of name-value pairs, with the
          name and value separated by a colon.
        </span>
        <span>Objects may also possess methods.</span>
        <span>Methods are operations that can be executed on objects.</span>
        <span>
          These methods are stored in properties as function definitions.
        </span>
        <CodeDisplay
          title={``}
          codeStr={`const person = {
  firstName: "Tom",
  lastName : "Bill",
  id   	: 1234,
  fullName : function() {
    return this.firstName + " " + this.lastName;
  }
};`}
        />
        <span>
          Arrays are also variable and they can hold multiple values under a
          single name.
        </span>
        <span>
          Array value can be retrieved by referencing an index number.
        </span>
        <CodeDisplay
          title={`Example:`}
          codeStr={`const cars = ["Honda", "Maruti", "Tata"];`}
        />
        <span>Array index starts with 0</span>
        <span>
          In the above example Honda is at 0 index, tata is at 2nd index.
        </span>
        <span>
          The <SingleCode codeLine={`toString()`} /> method in JavaScript
          changes an array into a string, with array values separated by commas.
        </span>
        <CodeDisplay
          title={``}
          codeStr={`const students = ["Adam", "Ronaldo", "Jack", "Mario"];
document.getElementById("demo").innerHTML = fruits.toString();`}
        />
        <span>
          The length property of an array provides the array's length,
          indicating the number of elements it contains.
        </span>
        <CodeDisplay
          title={``}
          codeStr={`const students = ["Adam", "Ronaldo", "Jack", "Mario"];
let length = fruits.length;`}
        />
      </div>
    </div>
  );
};

export default ArrayObjects;

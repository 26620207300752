import React from 'react';
import Introduction from './Introduction';
import Basics from './Basics';
import ControlFlow from './ControlFlow';
import Functions from './Functions';
import ArrayObjects from './ArrayObjects';
import DomManipulation from './DomManipulation';
import DomEvents from './DomEvents';
import DomEventListener from './DomEventListener';
import { supabase } from '../../../../../config/supabaseConfig';

import IdealSolution from '../../../../htmlEditor/IdealSolution';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import { javascriptTopicsObj } from '../../topics';

const Index = ({
  setTopic,
  topic,
  enrollData,
  setReFetch,
  nextMilestone,
  isExerciseOpen,
  setExerciseOpen,
  showIdealSolution,
  setShowIdealSolution,
  currExerciseIdx,
  setCurrExerciseIdx,
}) => {
  const updateJsData = async (topic) => {
    const jsPreData = enrollData?.milestone_2?.js || [];
    if (!jsPreData.includes(topic)) {
      jsPreData.push(topic);
      const milestone_2 = enrollData?.milestone_2;
      await supabase
        .from('full_stack_users')
        .update({
          milestone_2: {
            ...milestone_2,
            js: jsPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };
  const jsData = enrollData?.milestone_2?.js || [];
  const topicIsCompleted = jsData.includes(topic);

  console.log(javascriptTopicsObj[topic].exercise_id)

  return (
    <div className='flex flex-col'>
      {!isExerciseOpen ? (
        <React.Fragment>
          {topic === 'introduction' && (
            <Introduction
              setTopic={setTopic}
              updateJsData={updateJsData}
              isCompleted={jsData.includes('introduction')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'basics' && (
            <Basics
              setTopic={setTopic}
              updateJsData={updateJsData}
              isCompleted={jsData.includes('basics')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'control-flow' && (
            <ControlFlow
              setTopic={setTopic}
              updateJsData={updateJsData}
              isCompleted={jsData.includes('control-flow')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'functions' && (
            <Functions
              setTopic={setTopic}
              updateJsData={updateJsData}
              isCompleted={jsData.includes('functions')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'array-object' && (
            <ArrayObjects
              setTopic={setTopic}
              updateJsData={updateJsData}
              isCompleted={jsData.includes('array-object')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'DomManipulation' && (
            <DomManipulation
              setTopic={setTopic}
              updateJsData={updateJsData}
              isCompleted={jsData.includes('DomManipulation')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'DomEvents' && (
            <DomEvents
              setTopic={setTopic}
              updateJsData={updateJsData}
              isCompleted={jsData.includes('DomEvents')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'DomEventListener' && (
            <DomEventListener
              nextMilestone={nextMilestone}
              setTopic={setTopic}
              updateJsData={updateJsData}
              isCompleted={jsData.includes('DomEventListener')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <EditorPanel
            exerciseid={javascriptTopicsObj[topic].exercise_id[currExerciseIdx]}
          />
          <IdealSolution
            exerciseid={javascriptTopicsObj[topic].exercise_id[currExerciseIdx]}
          />
        </React.Fragment>
      )}
      <div className='flex w-fit gap-3 pt-2'>
        <button
          onClick={() => {
            // 1: If topic doesnt have any exercise, switch to next topic
            if (javascriptTopicsObj[topic].exercise_id.length === 0) {
              // Topic doesnt have any exercise
              updateJsData(topic);
              if (topic === 'DomEventListener') {
                setExerciseOpen(false);
                nextMilestone();
              } else {
                setTopic(javascriptTopicsObj[topic].next_topic_id);
              }
              return;
            }

            // 2: If ex not open, open it
            if (!isExerciseOpen) {
              setExerciseOpen(true);
              return;
            }

            // 3: Ex open, increment index until it reaches max size.
            // If max reached, update data and change topic
            if (isExerciseOpen) {
              // Last ex in array
              if (
                currExerciseIdx ===
                javascriptTopicsObj[topic].exercise_id.length - 1
              ) {
                setCurrExerciseIdx(0);
                updateJsData(topic);
                setExerciseOpen(false);
                if (topic === 'DomEventListener') {
                  nextMilestone();
                } else {
                  setTopic(javascriptTopicsObj[topic].next_topic_id);
                }
                return;
              } else {
                setCurrExerciseIdx(currExerciseIdx + 1);
              }
            }
            // updateJsData(topic);
            // setTopic(javascriptTopicsObj[topic].next_topic_id);
            // setExerciseOpen(true);
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {topicIsCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Index;

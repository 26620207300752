import React, { useState } from 'react';
import GitAndGithub from './gitAndGithub';
import Submission from './submission';
import { FaAngleUp } from 'react-icons/fa';
import TopicRow from '../TopicRow';
import TimeTracker from '../TimeTracker';

import { gitTopics, submissionTopics } from '../topics';

const Day3 = ({ enrollData, setReFetch, nextMilestone }) => {
  const [tutorial, setTutorial] = useState('git');
  const [topic, setTopic] = useState('introduction');
  const [prevTutorialTopic, setPrevTutorialTopic] = useState('details');
  const [gitOpen, setGitOpen] = useState(true);
  const [submissionOpen, setSubmissionOpen] = useState(false);
  // eslint-disable-next-line
  const [exerciseOpen, setExerciseOpen] = useState(false);
  const [currExerciseIdx, setCurrExerciseIdx] = useState(0);

  const gitData = enrollData?.milestone_3?.git || [];

  return (
    <div className='flex flex-col md:flex-row h-[80vh] items-center md:items-stretch'>
      <TimeTracker milestone_id='milestone_3' userId={enrollData?.user_id} />
      <div className='w-full md:w-1/4 min-w-64 p-6 md:border-r-2 md:border-b-2 border-gray-200 md:overflow-y-auto md:h-[80vh]'>
        <div className='flex flex-col gap-4 overflow-auto md:sticky top-0 select-none'>
          <div className='border-2 rounded-t-lg border-gray-200'>
            <div
              onClick={() => {
                if (tutorial === 'git') {
                  setGitOpen((pre) => !pre);
                } else {
                  if (submissionOpen) {
                    setSubmissionOpen(false);
                    setExerciseOpen(false);
                  }
                  setTutorial('git');
                  const temp = topic;
                  setTopic(prevTutorialTopic);
                  setPrevTutorialTopic(temp);
                  setGitOpen(true);
                }
              }}
              className='w-full flex flex-row justify-between items-center cursor-pointer gap-5 bg-stone-100 rounded-t-md px-5 py-4'>
              <span className='font-semibold'>Git</span>
              <FaAngleUp
                className={`text-2xl duration-300 ${
                  gitOpen ? ' rotate-0 ' : 'rotate-180'
                }`}
              />
            </div>
            {gitOpen
              ? gitTopics.map((data, idx) => (
                  <TopicRow
                    key={idx}
                    idx={idx}
                    prevChecked={
                      idx === 0 ? true : gitData.includes(gitTopics[idx - 1].id)
                    }
                    isChecked={gitData.includes(data.id)}
                    isSelected={topic === data.id && tutorial === 'git'}
                    onClick={() => setTopic(data.id)}
                    title={data.title}
                    exercise_id={data.exercise_id}
                    exercise_task={data.exercise_task}
                    setExerciseOpen={setExerciseOpen}
                    currExerciseId={currExerciseIdx}
                    exerciseIsOpen={exerciseOpen}
                    setCurrExerciseId={setCurrExerciseIdx}
                  />
                ))
              : ''}
          </div>
          <div className='border-2 rounded-t-lg border-gray-200'>
            <div
              onClick={() => {
                if (tutorial === 'submission') {
                  setSubmissionOpen((pre) => !pre);
                } else {
                  if (gitOpen) {
                    setGitOpen(false);
                    setExerciseOpen(false);
                  }
                  setTutorial('submission');
                  const temp = topic;
                  setTopic(prevTutorialTopic);
                  setPrevTutorialTopic(temp);
                  setSubmissionOpen(true);
                }
              }}
              className='w-full flex flex-row justify-between items-center cursor-pointer gap-5 bg-stone-100 rounded-t-md px-5 py-4'>
              <span className='font-semibold'>Submission</span>
              <FaAngleUp
                className={`text-2xl duration-300 ${
                  submissionOpen ? ' rotate-0 ' : 'rotate-180'
                }`}
              />
            </div>
            {submissionOpen
              ? submissionTopics.map((data, idx) => (
                  <TopicRow
                    key={idx}
                    idx={idx}
                    prevChecked={true}
                    isChecked={false}
                    isSelected={topic === data.id && tutorial === 'submission'}
                    onClick={() => setTopic(data.id)}
                    title={data.title}
                    exercise_id={data.exercise_id}
                    exercise_task={data.exercise_task}
                    setExerciseOpen={setExerciseOpen}
                    currExerciseId={currExerciseIdx}
                    exerciseIsOpen={exerciseOpen}
                    setCurrExerciseId={setCurrExerciseIdx}
                  />
                  //   <div
                  //     key={idx}
                  //     onClick={() => setTopic(data.id)}
                  //     className={`flex flex-row justify-between items-center cursor-pointer gap-5 px-5 py-4 ${
                  //       topic === data.id && tutorial === 'submission'
                  //         ? ' bg-primary-lighter '
                  //         : ' bg-white'
                  //     }`}>
                  //     <span
                  //       className={`${topic === data.id ? 'font-semibold ' : ''}`}>
                  //       {idx + 1}. {data.title}
                  //     </span>
                  //     {/* {javascriptData.includes(data.id) ? (
                  //       <FaCheck className='bg-primary text-white text-2xl p-1 rounded-3xl' />
                  //     ) : (
                  //       ""
                  //     )} */}
                  //   </div>
                ))
              : ''}
          </div>
        </div>
      </div>
      <div
        id='main-content'
        className='px-4 pl-8 md:px-8 py-4 md:py-6 md:w-3/4 border-b-0 md:border-b-2 border-gray-200 md:overflow-y-auto md:h-[80vh]'>
        {tutorial === 'git' && (
          <GitAndGithub
            setTutorialSubmission={() => {
              setTopic('details');
              setTutorial('submission');
              setPrevTutorialTopic('ci-github-pages');
              setSubmissionOpen(true);
              setGitOpen(false);
            }}
            enrollData={enrollData}
            setReFetch={setReFetch}
            setTopic={setTopic}
            topic={topic}
          />
        )}
        {tutorial === 'submission' && (
          <Submission
            nextMilestone={nextMilestone}
            enrollData={enrollData}
            setReFetch={setReFetch}
            setTopic={setTopic}
            topic={topic}
          />
        )}
      </div>
    </div>
  );
};

export default Day3;

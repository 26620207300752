import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const BootstrapImages = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Bootstrap Images</span>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Making Images Responsive with Bootstrap
        </span>
        <span>
          Bootstrap provides an easy way to make images responsive,
          automatically adjusting their size to fit the screen or container they
          are in.
        </span>
        <span>
          By adding the <SingleCode codeLine={`img-fluid`} /> class to an image,
          it will scale properly on all devices, from mobile phones to large
          desktops.
        </span>
        <CodeDisplay
          title={'Responsive Image Example'}
          codeStr={`<img src="image.jpg" class="img-fluid" alt="Responsive Image">`}
        />
        <span>
          This ensures that the image won't overflow its container, and it will
          shrink or expand to fit the available space.
        </span>
        <span>
          The <SingleCode codeLine={`img-fluid`} /> class applies{' '}
          <SingleCode codeLine={`max-width: 100%`} /> and{' '}
          <SingleCode codeLine={`height: auto`} /> to the image, making it
          responsive.
        </span>
        <span>
          This approach is particularly useful in responsive layouts where you
          want images to adapt to different screen sizes without manual
          resizing.
        </span>
      </div>
    </div>
  );
};

export default BootstrapImages;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const TailwindColors = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Colors</span>
      <div className='flex flex-col gap-2'>
        <span>
          Tailwind CSS provides a variety of utility classes to easily manage
          colors for both text and backgrounds. These classes are based on a
          consistent naming convention and cover a range of shades.
        </span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/hA7IfPjXeoI?si=1IDG7oVgmwHfGiGX'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowFullScreen></iframe>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>1. Text Color</span>
          <span>
            You can change the color of text by using text-color-name classes,
            where the color-name is followed by a number that represents the
            intensity (from 100 to 900).
          </span>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Text Color Example'}
            codeStr={`<h1 class="text-blue-500">Hello, World!</h1>
<p class="text-red-400">Red Text</p>
<p class="text-green-700">Dark Green Text</p>`}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>2. Background Color</span>
          <span>
            The background color is set using bg-color-name classes, following
            the same pattern as text colors.
          </span>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Background Color Example'}
            codeStr={`<div class="bg-yellow-300 p-4">
Welcome to Tailwind CSS!
</div>
<div class="bg-gray-800 text-white p-4">Dark Background</div>
<div class="bg-pink-200 p-6">Light Pink Background</div>`}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>3. Hover and Focus States</span>
          <span>
            You can modify the colors during hover or focus states by prefixing
            the color class with hover: or focus:.
          </span>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Hover State Example'}
            codeStr={`<button class="bg-blue-500 hover:bg-blue-700 text-white">
Hover me!
</button>`}
          />
          <p>
            <span className='font-semibold'>hover:bg-blue-700</span> changes the
            background to a darker blue on hover.
          </p>
          <iframe
            className='md:w-[560px] md:h-[315px] w-[100%]'
            src='https://www.youtube.com/embed/5_BPDve5-3M?si=zdt1Q5LpDq3ciuMV'
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowFullScreen></iframe>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>4. Customizing Colors</span>
          <span>
            Tailwind allows easy customization of the color palette through the
            tailwind.config.js file, letting you add your own colors to fit the
            needs of your project.
          </span>
          <p>
            This basic overview of text and background colors in Tailwind shows
            how flexible and easy it is to style elements without writing custom
            CSS.
          </p>
        </div>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/6yFazMin-d0?si=aSLzZHQ6xByQ_UIc'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default TailwindColors;

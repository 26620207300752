import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const BoxModel = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Box Model</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/rIO5326FgPE'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          In CSS, every HTML element can be considered as a box, comprising
          content, padding, border, and margins. This concept of the box model
          plays a pivotal role in web page design and layout construction.
        </span>
        <span>
          Comprehending the box model is essential for accurately establishing
          element sizes, taking into account the entirety of space occupied,
          including padding and borders.
        </span>
        <span>The box model breakdown:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Content`} />: Houses the actual element
            content, such as text or images.
          </li>
          <li>
            <SingleCode codeLine={`Padding`} />: The space between the content
            and the border.
          </li>
          <li>
            <SingleCode codeLine={`Border`} />: Surrounds the padding and
            content.
          </li>
          <li>
            <SingleCode codeLine={`Margins`} />: Space between the border of an
            element and adjacent elements.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* Styling the box */
.box {
  width: 200px; /* Width of the box */
  height: 100px; /* Height of the box */
  padding: 20px; /* Padding inside the box */
  border: 2px solid #333; /* Border around the box */
  margin: 30px; /* Margin outside the box */
}
`}
      />
    </div>
  );
};

export default BoxModel;

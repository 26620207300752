import React from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const DomEvents = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>DOM Events</span>
      <div className='flex flex-col gap-2'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/0fy9TCcX8Uc?si=XxTubZlxL-JPQEgc'
          title='JavaScript DOM Events'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen></iframe>
        <span>
          JavaScript DOM (Document Object Model) events are interactions or
          occurrences that happen in the browser, such as user actions or
          changes in the document.
        </span>
        <span>
          Event handling allows you to define scripts that respond to these
          events, creating dynamic and interactive web pages.
        </span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Click Event`} />: Triggered when an element
            is clicked.{' '}
            <CodeDisplay
              title={``}
              codeStr={`<button id="myButton">Click me</button>
<script>
document.getElementById("myButton").addEventListener("click", function() {
alert("Button clicked!");
});
</script>`}
            />
          </li>
          <li>
            <SingleCode codeLine={`Mouseover and Mouseout Events`} />: Fired
            when the mouse pointer enters or leaves an element.{' '}
            <CodeDisplay
              title={``}
              codeStr={`<div id="myDiv" onmouseover="changeColor()" onmouseout="restoreColor()">Hover over me</div>
<script>
function changeColor() {
document.getElementById("myDiv").style.backgroundColor = "lightblue";
}
function restoreColor() {
document.getElementById("myDiv").style.backgroundColor = "";
}
</script>`}
            />
          </li>
          <li>
            <SingleCode codeLine={`Change Event`} />: Triggered when the value
            of a form element changes.
            <CodeDisplay
              title={``}
              codeStr={`<input type="text" id="myInput" placeholder="Type something">
<script>
document.getElementById("myInput").addEventListener("change", function() {
alert("Value changed to: " + this.value);
});
</script>`}
            />
          </li>
          <li>
            <SingleCode codeLine={`Keydown Event`} />: When a key on the
            keyboard is pressed.
            <CodeDisplay
              title={``}
              codeStr={`<input type="text" id="myInput">
<script>
document.getElementById("myInput").addEventListener("keydown", function(event) {
alert("Key pressed: " + event.key);
});
</script>`}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DomEvents;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const BootstrapTypography = () => {
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-5'>
        <span className='font-semibold text-lg'>Typography</span>
        <div className='flex flex-col gap-2'>
          <span className='font-bold'>Text Styles with Bootstrap</span>
          <span>
            Bootstrap makes it easy to style text by providing predefined
            classes. You can apply these classes directly to your HTML elements,
            and they will automatically look good.
          </span>
          <span className='font-semibold'>Headings:</span>
          <span>
            Bootstrap offers various heading styles from h1 (largest) to h6
            (smallest). Example:
          </span>
          <CodeDisplay
            title={'Heading Example'}
            codeStr={`<h1 class="display-4">Main Heading</h1>`}
          />
          <span className='font-semibold mt-2'>Paragraphs:</span>
          <span>
            Using the .lead class gives your paragraphs a slightly larger and
            more readable look, making them perfect for introducing sections of
            your site.
          </span>
          <CodeDisplay
            title={'Paragraph Example'}
            codeStr={`<p class="lead">This is a lead paragraph. It stands out from regular paragraphs.</p>`}
          />
        </div>
      </div>
    </div>
  );
};

export default BootstrapTypography;

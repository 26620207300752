import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const BootstrapPagination = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Pagination</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/lgmjKfk4Vmk?si=hPWYRxV7rd5oBnPe'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Creating Easy Navigation with Bootstrap's Pagination
        </span>
        <p>
          Pagination is used to navigate through multiple pages of content.
          Bootstrap's pagination component is easy to implement and provides a
          clean, modern look for navigation links.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Pagination Example'}
          codeStr={`<nav>
<ul class="pagination">
<li class="page-item"><a class="page-link" href="#">Previous</a></li>
<li class="page-item"><a class="page-link" href="#">1</a></li>
<li class="page-item"><a class="page-link" href="#">2</a></li>
<li class="page-item"><a class="page-link" href="#">3</a></li>
<li class="page-item"><a class="page-link" href="#">Next</a></li>
</ul>
</nav>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>pagination:</strong> This class styles the navigation links
            for multiple pages.
          </li>
          <li>
            <strong>page-item and page-link:</strong> These classes handle
            individual page links.
          </li>
        </ul>
        <p>
          Pagination helps users easily navigate long lists or articles divided
          into multiple pages.
        </p>
      </div>
    </div>
  );
};

export default BootstrapPagination;

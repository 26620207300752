import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Basics = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Basics</span>
      <div className='flex flex-col gap-5'>
        <div className='flex flex-col gap-2'>
          <iframe
            className='md:w-[560px] md:h-[315px] w-[100%]'
            src='https://www.youtube.com/embed/xv82yODVXqo?si=7ZcQHDDTWr9naq9O'
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen></iframe>
          <span className='font-semibold text-lg'>
            Variables in JavaScript are containers for data storage.
          </span>
          <ul className='list-disc px-5 space-y-1'>
            <li>Declared using var, let, or const. For example:</li>
            <li>
              Use const for unchangeable values, let when values can change, and
              var for compatibility with older browsers.
            </li>
          </ul>
          <CodeDisplay
            title={''}
            codeStr={`let x = 5;
let y = 6;
let z = x + y;`}
          />
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>JavaScript Data Types</span>
          <span>
            JavaScript supports various data types including String, Number,
            BigInt, Boolean, Undefined, Null, Symbol, and Object.
          </span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`Objects`} />: Can include other objects,
              arrays, and dates.
            </li>
            <li>
              <SingleCode codeLine={`Strings`} />: Series of characters,
              enclosed in quotes. For example, "John Doe".
            </li>
            <li>
              <SingleCode codeLine={`Numbers`} />: Stored as floating-point
              decimals. Can be written with or without decimals.
            </li>
            <li>
              <SingleCode codeLine={`BigInt`} />: For integer values too large
              for a normal Number.
            </li>
            <li>
              <SingleCode codeLine={`Booleans`} />: Represent true or false
              values.
            </li>
            <li>
              <SingleCode codeLine={`Arrays`} />: List-like objects. Example:{' '}
              <CodeDisplay
                title={``}
                codeStr={`const cars = ["Saab", "Volvo", "BMW"];`}
              />
            </li>
            <li>
              <SingleCode codeLine={`Objects`} />: Collections of properties.
              Example:{' '}
              <CodeDisplay
                title={``}
                codeStr={`const person = { firstName: "John", lastName: "Doe", age: 50, eyeColor: "blue" };`}
              />
            </li>
            <li>
              <SingleCode codeLine={`Typeof Operator`} />: Determines the type
              of a variable. For example,{' '}
              <CodeDisplay
                title={``}
                codeStr={`typeof "Hello" returns "string"`}
              />
            </li>
          </ul>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>
            Introduction to JavaScript Data Types:
          </span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              JavaScript, being a dynamically typed language, offers several
              data types to handle different kinds of values and information
              within a program.
            </li>
            <li>
              Understanding these data types is crucial for beginners as they
              form the building blocks of JavaScript programming.
            </li>
          </ul>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>Primitive Data Types:</span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`Number`} />: Represents numeric values,
              including integers and floating-point numbers. Example:{' '}
              <CodeDisplay title={``} codeStr={`let age = 25;`} /> (Here, age is
              assigned the value 25, which is a numeric data type.)
            </li>
            <li>
              <SingleCode codeLine={`String`} />: Represents text or sequences
              of characters enclosed in single or double quotes. Example:{' '}
              <CodeDisplay title={``} codeStr={`let name = 'John';`} /> (In this
              example, name is assigned the string value 'John'.)
            </li>
            <li>
              <SingleCode codeLine={`Boolean`} />: Represents true or false
              values, often used for logical comparisons. Example:{' '}
              <CodeDisplay title={``} codeStr={`let isStudent = true;`} />{' '}
              (Here, isStudent is assigned the boolean value true.)
            </li>
            <li>
              <SingleCode codeLine={`Undefined`} />: Represents a variable that
              has been declared but not assigned any value yet. Example:{' '}
              <CodeDisplay title={``} codeStr={`let score;`} /> (In this case,
              score is declared but not assigned any value, so its data type is
              undefined.)
            </li>
          </ul>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>
            Non-Primitive Data Types:
          </span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`Object`} />: Represents complex data
              structures consisting of key-value pairs, where each value is
              accessed using its associated key. Example:{' '}
              <CodeDisplay
                title={``}
                codeStr={`let person = {
 name: 'Alice',
 age: 30
};
`}
              />{' '}
              (In this example, person is an object with two properties: name
              and age. )
            </li>
            <li>
              <SingleCode codeLine={`Array`} />: Represents a collection of
              elements, where each element can be of any data type, and is
              accessed using its index. Example:{' '}
              <CodeDisplay
                title={``}
                codeStr={`let numbers = [1, 2, 3, 4, 5];`}
              />{' '}
              (Here, numbers is an array containing five elements of numeric
              data type.)
            </li>
            <li>
              <SingleCode codeLine={`Dynamic Typing`} />: JavaScript employs
              dynamic typing, meaning variables do not require explicit
              declaration of data types. Example:{' '}
              <CodeDisplay
                title={``}
                codeStr={`let message = 'Hello'; // message is automatically assigned the string data type
message = 10; // message is now assigned the number data type
`}
              />{' '}
              (Here, message initially holds a string value 'Hello', but later
              it is reassigned to a number value 10 without explicitly
              specifying its data type.)
            </li>
          </ul>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold text-lg'>Operators in JavaScript</span>
          <span>
            Includes arithmetic, assignment, comparison, string, logical,
            bitwise, ternary, and type operators.
          </span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`Arithmetic Operators`} />: Perform
              arithmetic on numbers.
            </li>
            <li>
              <SingleCode codeLine={`Assignment Operators`} />: Assign values to
              variables, like +=.
            </li>
            <li>
              <SingleCode codeLine={`String Operators`} />: Concatenate strings.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Basics;

import React from 'react';

const Introduction = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Introduction</span>
      <div className='flex flex-col gap-2'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/upDLs1sn7g4'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen></iframe>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            JavaScript is a high-level, interpreted programming language
            integral to the World Wide Web, along with HTML and CSS.
          </li>
          <li>
            It's used primarily for client-side scripting, enabling dynamic and
            interactive experiences on web pages.
          </li>
          <li>
            Interactivity: JavaScript allows the creation of interactive
            elements like form validations and dynamic content updates.
          </li>
          <li>
            Asynchronous Programming: It supports asynchronous operations,
            crucial for non-blocking operations such as event handling and AJAX
            requests.
          </li>
          <li>
            Object-Oriented: JavaScript's object-oriented nature allows for
            versatile programming paradigms.
          </li>
          <li>
            JavaScript manipulates the Document Object Model (DOM) to
            dynamically update web page content and structure.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Introduction;

import React from 'react';
// import { FaCheckCircle } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
import { FaCopy } from 'react-icons/fa';

const SuccessPage = () => {
  const handleCopy = () => {
    navigator.clipboard.writeText(
      'https://chat.whatsapp.com/JFknPuYYggX4hTSGvxGyvU'
    );
  };
  return (
    <div className=' mx-auto my-10 font-semibold  px-4 sm:px-16 lg:px-24 '>
      {/* <FaCheckCircle className='text-5xl mb-4 text-green-500 mx-auto' /> */}

      <div className='font-semibold text-xl mb-4 text-primary'>
        Congratulations on taking the first step toward building your web
        development skills!
      </div>
      <div className='mb-2'>
        We’re thrilled to have you on board for our Full Stack Bootcamp with a
        focus on Front-End development.
      </div>
      <div>
        To stay updated on important program details, please{' '}
        <span className='font-bold'>join our WhatsApp community</span> for
        announcements and resources.
      </div>
      <div className='mt-2'>
        {' '}
        You can use the link below or scan the QR code:
      </div>
      <div className='flex justify-around items-center my-10'>
        <div className='flex'>
          <button
            onClick={handleCopy}
            className='flex items-center p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300'>
            <FaCopy className='mx-2 text-2xl' />
          </button>
          <a
            href='https://chat.whatsapp.com/JFknPuYYggX4hTSGvxGyvU'
            target='_blank'
            rel='noreferrer'
            className='text-blue-500 underline ml-2 mt-1'>
            Join Community
          </a>
        </div>

        <div>OR</div>
        <div>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1722580389/uploaded_resources/WhatsApp_Image_2024-08-02_at_11.59.41_AM_sstti9.jpg'
            alt='Login'
            className='mx-auto h-32 w-32 object-contain'
          />
        </div>
      </div>
      <div className='mb-2'>
        Be sure to check your inbox for emails from{' '}
        <span className='font-bold'>connect@belong.education</span> if you don't
        see them, check your spam or junk folder. In case of any questions, feel
        free to reach out to us at the same email.
      </div>
      <div className='italic'>
        Note: We recommend taking a screenshot of this page so you have the QR
        code handy.
      </div>
      {/* <div className='mt-8'>
        <Link className='bg-primary rounded-xl px-8 py-2' to='/signup/belong'>
          Signup
        </Link>
      </div> */}
    </div>
  );
};

export default SuccessPage;

import React, { useState } from 'react';
import Day1 from './day1/Day1';
import Day2 from './day2/Day2';
import Day3 from './day3/Day3';

import Day4 from './day4/Day4';
import Header from './Header';
import { useParams } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import { milestonesData } from '../../../config/JourneyConfig';

const Bootcamp = ({ enrollData, setReFetch }) => {
  const htmltasks = enrollData?.milestone_1?.html || [];
  const csstasks = enrollData?.milestone_1?.css || [];
  const jstasks = enrollData?.milestone_2?.js || [];
  const bootstraptasks = enrollData?.milestone_2?.bootstrap || [];
  const gittasks = enrollData?.milestone_3?.git || [];

  const isHtmlDone = htmltasks.length >= milestonesData.html;
  const isCssDone = csstasks.length >= milestonesData.css;
  const isJsDone = jstasks.length >= milestonesData.js;
  const isBootstrapDone = bootstraptasks.length >= milestonesData.bootstrap;
  const isGitDone = gittasks.length >= milestonesData.git;
  const isUrlDone = enrollData.webpageUrl && enrollData.githubUrl;
  console.log(isGitDone, isUrlDone);

  const milestones1Done = isHtmlDone && isCssDone;
  const milestones2Done = isJsDone && isBootstrapDone;
  // const milestones3Done = isGitDone && isUrlDone;

  const milestones3Done = false;
  const maxDay = milestones3Done
    ? 4
    : milestones2Done
      ? 3
      : milestones1Done
        ? 2
        : 1;

  const { day } = useParams();

  const currentDay = [1, 2, 3, 4].includes(Number(day)) ? Number(day) : 1;

  const [dayNumber, setDayNumber] = useState(Math.min(currentDay, maxDay));

  return (
    <React.Fragment>
      <div className='md:sticky top-0 z-50 bg-white max-h-[20vh]'>
        <div className='flex flex-col sm:flex-row justify-between p-6 border-b-2 border-gray-200 gap-2 sm:gap-3'>
          <div className='flex flex-row gap-3 justify-center sm:justify-start items-center'>
            <span className='font-semibold text-lg sm:text-2xl'>Bootcamp</span>
            <div className='pt-1'>
              <FaAngleRight className='text-xl sm:text-2xl' />
            </div>
            <span className='font-semibold text-lg sm:text-2xl'>
              Journey Roadmap
            </span>
          </div>
          <button className='rounded-lg border-2 border-primary font-semibold bg-cyan-50 bg-opacity-50 py-2 px-2 sm:px-5 text-sm md:text-base'>
            <a href='mailto:connect@belong.education;'>
              <span>Have a question? Write to us </span>
            </a>{' '}
          </button>
        </div>
        <div className=''>
          <Header
            dayNumber={dayNumber}
            setDayNumber={setDayNumber}
            enrollData={enrollData}
            milestones3Done={milestones3Done}
            milestones2Done={milestones2Done}
            milestones1Done={milestones1Done}
          />
        </div>
      </div>

      {dayNumber === 1 && (
        <Day1
          enrollData={enrollData}
          setReFetch={setReFetch}
          nextMilestone={() => {
            setDayNumber(2);
          }}
        />
      )}
      {dayNumber === 2 && (
        <Day2
          enrollData={enrollData}
          setReFetch={setReFetch}
          nextMilestone={() => {
            setDayNumber(3);
          }}
        />
      )}
      {dayNumber === 3 && (
        <Day3
          enrollData={enrollData}
          setReFetch={setReFetch}
          nextMilestone={() => {
            setDayNumber(4);
          }}
        />
      )}
      {dayNumber === 4 && (
        <Day4
          enrollData={enrollData}
          setReFetch={setReFetch}
          nextMilestone={() => {}}
        />
      )}
    </React.Fragment>
  );
};

export default Bootcamp;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const Tooltips = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'> Tooltips</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/WTrW-1JsDYE?si=s3k7ABEO2M-QI28K'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Adding Tooltips for Better User Interaction
        </span>
        <p>
          Tooltips are small pop-ups that appear when the user hovers over or
          focuses on an element. They provide additional information without
          cluttering the interface.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Tooltip Example'}
          codeStr={`<button type="button" class="btn btn-secondary" data-toggle="tooltip" title="Tooltip Text">
Hover over me
</button>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>data-toggle="tooltip":</strong> This attribute activates the
            tooltip functionality.
          </li>
          <li>
            <strong>title:</strong> This attribute contains the text that will
            be displayed in the tooltip.
          </li>
        </ul>
        <p>
          Tooltips are a subtle way to provide additional context or information
          to users without cluttering the main interface.
        </p>
      </div>
    </div>
  );
};

export default Tooltips;

import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Buttons = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Buttons</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/tDqTXipQmBU'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            The <SingleCode codeLine={`<button>`} /> tag is used to create
            clickable buttons, which can trigger actions or events when
            interacted with. It's special because, unlike buttons made with{' '}
            <SingleCode codeLine={`<input>`} />, you can put different things
            inside it, like words and pictures.
          </li>
          <li>
            Buttons are essential for user interaction within forms or for
            triggering JavaScript functions and they can be styled using CSS.
          </li>
          <li>
            Buttons can do different things, and you can choose what a button
            does when someone clicks on it. For example, it could simply be a
            button ('button'), a button that resets something ('reset'), or a
            button that submits information ('submit').
          </li>
          <li>
            You can also use the <SingleCode codeLine={`onClick`} /> attribute
            to tell the button what action to perform when it gets clicked.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<body>
    <h1>Interactive Button Example</h1>
    <!-- Button with Text -->
    <button>This is a Button</button>
    <!-- Button with Image -->
    <button>
        <img src="button-icon.png" alt="Button Icon"> Click me!
    </button>
    <!-- Different Button Types -->
    <button type="button" onclick="alert('Button Clicked')">Click Me (type="button")</button>
    <button type="reset">Reset Form (type="reset")</button>
    <button type="submit">Submit Form (type="submit")</button>
</body>
`}
      />
    </div>
  );
};

export default Buttons;

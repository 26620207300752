export const milestonesData = {
  html: 10,
  css: 14,
  bootstrap: 29,
  js: 8,
  git: 5,
  total: 115,
};

/*
export const milestonesData = {
  html: 10,
  css: 14,
  bootstrap: 11,
  js: 8,
  git: 5,
  total: 49,
};

*/

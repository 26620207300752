import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';


const ResponsiveBreakpoints = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'> Responsive Breakpoints</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/ab5MVZQpGEA?si=XN8p50hpaE9vdtuq'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>What are Responsive Breakpoints?</span>
        <p>
          Bootstrap uses predefined "breakpoints" to adapt layouts to different
          screen sizes. Breakpoints are specific screen widths at which your
          design can change to fit better on different devices. Understanding
          these breakpoints allows you to create responsive designs that look
          good on mobile, tablet, and desktop devices.
        </p>
        <p>The key breakpoints are:</p>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>sm:</strong> For small devices (576px and up, like phones).
          </li>
          <li>
            <strong>md:</strong> For medium devices (768px and up, like
            tablets).
          </li>
          <li>
            <strong>lg:</strong> For large devices (992px and up, like laptops
            and desktops).
          </li>
        </ul>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Responsive Breakpoint Example'}
          codeStr={`<div class="col-md-4">This is visible on medium devices and larger</div>`}
        />
        <p>
          The col-md-4 class means that this element will take up 4 out of 12
          columns on medium screens (768px or larger) but won't appear on
          smaller screens.
        </p>
      </div>
    </div>
  );
};

export default ResponsiveBreakpoints;

import React from 'react';

const Introduction = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Introduction to Bootstrap</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/eow125xV5-c?si=NKZf9tJuysOLU1n8'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>

      <span className='font-semibold text-lg'>What is Bootstrap?</span>
      <span>
        Bootstrap is a widely-used front-end framework designed to help
        developers build responsive, mobile-first websites quickly. "Responsive"
        means the website adapts to different screen sizes, from mobile phones
        to large desktop monitors, ensuring a consistent user experience.
        Bootstrap comes with pre-built CSS (styles) and JavaScript components
        like buttons, forms, grids, and more. You don’t need to write everything
        from scratch—it’s like a toolkit full of ready-to-use features.
      </span>
    </div>
  );
};

export default Introduction;

import React from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const Lists = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Lists</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/-kXZvKxs9oA'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          Lists are useful for presenting related items in a structured group.
        </span>
        <span>
          Lists organize content into ordered (<SingleCode codeLine={`<ol>`} />)
          or unordered (<SingleCode codeLine={`<ul>`} />) lists, and list items
          (<SingleCode codeLine={`<li>`} />) define individual items within the
          list.
        </span>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`<ul>
  <li>Item 1</li>
  <li>Item 2</li>
</ul>
<ol>
  <li>First</li>
  <li>Second</li>
</ol>
`}
      />
    </div>
  );
};

export default Lists;

import React from 'react';

import Introduction from './Introduction';
import IncludingBootstrap from './IncludingBootstrap';
import TailwindIntroduction from '../tailwind/TailwindIntroduction';
import { supabase } from '../../../../../config/supabaseConfig';
import ResponsiveLayouts from './ResponsiveLayouts';
import BootstrapTypography from './BootstrapTypography';
import Button from './Button';
import BootstrapImages from './BootstrapImages';
import BootstrapAlerts from './BootstrapAlerts';
import Tables from './Tables';
import Forms from './Forms';
import Badges from './Badges';
import CustomizingGrid from './CustomizingGrid';
import ResponsiveBreakpoints from './ResponsiveBreakpoints';
import Navbar from './Navbar';
import Cards from './Cards';
import Modals from './Modals';
import Tooltips from './Tooltips';
import CollapsiblePanels from './CollapsiblePanels';
import GridAlignment from './GridAlignment';
import Jumbotron from './Jumbotron';
import BootstrapPagination from './BootstrapPagination';
import TailwindColors from '../tailwind/TailwindColors';
import TailwindSpacing from '../tailwind/TailwindSpacing';
import TailwindFonts from '../tailwind/TailwindFonts';
import TailwindLayout from '../tailwind/TailwindLayout';
import TailwindBorders from '../tailwind/TailwindBorders';
import TailwindShadows from '../tailwind/TailwindShadows';
import TailwindZindex from '../tailwind/TailwindZindex';
import TailwindTransitions from '../tailwind/TailwindTransitions';

import { bootstrapTopicsObj } from '../../topics';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import IdealSolution from '../../../../htmlEditor/IdealSolution';

const Index = ({
  setTopic,
  topic,
  enrollData,
  setReFetch,
  setTutorialJS,
  isExerciseOpen,
  setExerciseOpen,
  currExerciseIdx,
  setCurrExerciseIdx,
}) => {
  const updateBootstrapData = async (topic) => {
    const bootstrapPreData = enrollData?.milestone_2?.bootstrap || [];
    if (!bootstrapPreData.includes(topic)) {
      bootstrapPreData.push(topic);
      const milestone_2 = enrollData?.milestone_2;
      await supabase
        .from('full_stack_users')
        .update({
          milestone_2: {
            ...milestone_2,
            bootstrap: bootstrapPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };
  const bootstrapData = enrollData?.milestone_2?.bootstrap || [];
  const topicIsCompleted = bootstrapData.includes(topic);

  return (
    <div className='flex flex-col'>
      {!isExerciseOpen ? (
        <React.Fragment>
          {topic === 'introduction' && (
            <Introduction
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('introduction')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'including-bootstrap' && (
            <IncludingBootstrap
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('including-bootstrap')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'responsive-layouts' && (
            <ResponsiveLayouts
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('responsive-layouts')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'bootstrap-typography' && (
            <BootstrapTypography
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('bootstrap-typography')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'button' && (
            <Button
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('button')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'bootstrap-images' && (
            <BootstrapImages
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('bootstrap-images')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'bootstrap-alerts' && (
            <BootstrapAlerts
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('bootstrap-alerts')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'tables' && (
            <Tables
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('tables')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'forms' && (
            <Forms
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('forms')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'badges' && (
            <Badges
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('badges')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'customizing-grid' && (
            <CustomizingGrid
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('customizing-grid')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'responsive-breakpoints' && (
            <ResponsiveBreakpoints
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('responsive-breakpoints')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'bootstrap-navbar' && (
            <Navbar
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('bootstrap-navbar')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'cards' && (
            <Cards
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('cards')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'modals' && (
            <Modals
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('modals')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'tooltips' && (
            <Tooltips
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('tooltips')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'collapsible-panels' && (
            <CollapsiblePanels
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('collapsible-panels')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'grid-alignment' && (
            <GridAlignment
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('grid-alignment')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'jumbotron' && (
            <Jumbotron
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('jumbotron')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'bootstrap-pagination' && (
            <BootstrapPagination
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('bootstrap-pagination')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}

          {topic === 'tailwind-introduction' && (
            <TailwindIntroduction
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              isCompleted={bootstrapData.includes('tailwind-introduction')}
            />
          )}
          {topic === 'tailwind-colors' && (
            <TailwindColors
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              isCompleted={bootstrapData.includes('tailwind-colors')}
            />
          )}
          {topic === 'tailwind-spacing' && (
            <TailwindSpacing
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              isCompleted={bootstrapData.includes('tailwind-spacing')}
            />
          )}
          {topic === 'tailwind-fonts' && (
            <TailwindFonts
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              isCompleted={bootstrapData.includes('tailwind-fonts')}
            />
          )}
          {topic === 'tailwind-layout' && (
            <TailwindLayout
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              isCompleted={bootstrapData.includes('tailwind-layout')}
            />
          )}
          {topic === 'tailwind-borders' && (
            <TailwindBorders
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              isCompleted={bootstrapData.includes('tailwind-borders')}
            />
          )}
          {topic === 'tailwind-shadows' && (
            <TailwindShadows
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              isCompleted={bootstrapData.includes('tailwind-shadows')}
            />
          )}
          {topic === 'tailwind-zIndex' && (
            <TailwindZindex
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              isCompleted={bootstrapData.includes('tailwind-zIndex')}
            />
          )}
          {/* In case this is the last topic, setTutorialJS will be called */}
          {topic === 'tailwind-transitions' && (
            <TailwindTransitions
              setTutorialJS={setTutorialJS}
              setTopic={setTopic}
              updateBootstrapData={updateBootstrapData}
              isCompleted={bootstrapData.includes('tailwind-transitions')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <EditorPanel
            exerciseid={bootstrapTopicsObj[topic].exercise_id[currExerciseIdx]}
          />
          <IdealSolution
            exerciseid={bootstrapTopicsObj[topic].exercise_id[currExerciseIdx]}
          />
        </React.Fragment>
      )}
      <div className='flex w-fit gap-3 pt-2'>
        <button
          onClick={() => {
            // 1: If topic doesnt have any exercise, switch to next topic
            if (bootstrapTopicsObj[topic].exercise_id.length === 0) {
              // Topic doesnt have any exercise
              updateBootstrapData(topic);
              if (topic === 'tailwind-transitions') {
                setExerciseOpen(false);
                setTutorialJS();
              } else {
                setTopic(bootstrapTopicsObj[topic].next_topic_id);
              }
              return;
            }

            // 2: If ex not open, open it
            if (!isExerciseOpen) {
              setExerciseOpen(true);
              return;
            }

            // 3: Ex open, increment index until it reaches max size.
            // If max reached, update data and change topic
            if (isExerciseOpen) {
              // Last ex in array
              if (
                currExerciseIdx ===
                bootstrapTopicsObj[topic].exercise_id.length - 1
              ) {
                setCurrExerciseIdx(0);
                updateBootstrapData(topic);
                setExerciseOpen(false);
                if (topic === 'tailwind-transitions') {
                  setTutorialJS();
                } else {
                  setTopic(bootstrapTopicsObj[topic].next_topic_id);
                }
                return;
              } else {
                setCurrExerciseIdx(currExerciseIdx + 1);
              }
            }
            // updateBootstrapData(topic);
            // setTopic(bootstrapTopicsObj[topic].next_topic_id);
            // setExerciseOpen(true);
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {topicIsCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Index;

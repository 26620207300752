import React from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const Elements = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>HTML Elements</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/xR-dcJNRyVs'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            HTML elements are the fundamental units of an HTML document, each
            represented by a pair of tags that enclose content.
          </li>
          <li>
            Tags include both an opening tag and a closing tag. For example,{' '}
            <SingleCode codeLine={`<tagname>content</tagname>`} />.
          </li>
          <li>
            Elements in HTML can be nested, meaning one element can contain
            another.
          </li>
          <li>
            Some elements, like line breaks (
            <span className='font-medium'>{`<br>`}</span>), don't require
            closing tags.
          </li>
        </ul>
      </div>

      <CodeDisplay
        codeStr={`<!DOCTYPE html>
    <html>
      <head>
        <title>My Web Page</title>
      </head>
      <body>
        <h1>Main Heading</h1>
        <p>This is a paragraph of text.</p>
        <br>
        <hr>
      </body>
    </html>`}
      />

      <div className='flex flex-col gap-3'>
        <span className='italic'>In this example,</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`<br>`} /> creates a line break.
          </li>
          <li>
            <SingleCode codeLine={`<hr>`} /> adds a horizontal line.
          </li>
          <li>
            <SingleCode codeLine={`<p>`} /> defines a paragraph.
          </li>
          <li>
            <SingleCode codeLine={`<h1>`} /> element defines a large heading.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Elements;

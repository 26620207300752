import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const TailwindZindex = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Z-Index</span>
      <div className='flex flex-col gap-2'>
        <span>
          Z-index is an essential CSS property used to control the stacking
          order of elements on the page. It determines which elements are
          layered above or below others, particularly when elements overlap.
        </span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/Lmvp7k1lg6I?si=5mTk1c8Sd9-ZN5kq'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowFullScreen></iframe>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>1. Understanding Z-Index</span>
          <span>
            In CSS, elements with a higher z-index value are positioned above
            those with lower z-index values. This property only works on
            elements with a position other than the default (static), such as
            relative, absolute, fixed, or sticky.
          </span>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>2. Z-Index Classes in Tailwind</span>
          <span>
            Tailwind CSS provides utility classes to quickly apply z-index
            values to elements without writing custom CSS.
          </span>
          <span>Predefined Z-Index Classes:</span>
          <ul className='list-disc pl-5'>
            <li>z-0: Sets a z-index of 0.</li>
            <li>z-10: Sets a z-index of 10.</li>
            <li>z-20: Sets a z-index of 20.</li>
            <li>z-30: Sets a z-index of 30.</li>
            <li>z-40: Sets a z-index of 40.</li>
            <li>z-50: Sets a z-index of 50.</li>
            <li>
              z-auto: Removes any z-index value, reverting it to the default
              auto behavior.
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>3. Example of Z-Index Usage</span>
          <span>
            Here's a practical example of how to use z-index in Tailwind:
          </span>
          <CodeDisplay
            title={'Tailwind Z-Index Example'}
            codeStr={`<div class="relative z-10 p-4 bg-red-200">
  This box is layered above others!
</div>
<div class="relative z-0 p-4 bg-blue-200">
  This box is layered below.
</div>`}
          />
          <p>Explanation:</p>
          <ul className='list-disc pl-5'>
            <li>
              The first div (z-10) has a z-index of 10, so it will be placed
              above the second div if they overlap.
            </li>
            <li>
              The second div (z-0) has a z-index of 0, meaning it will be
              layered beneath the first div.
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>4. Customizing Z-Index Values</span>
          <span>
            Tailwind's default z-index values are useful for most situations,
            but if you need more control, you can add custom values to the
            z-index scale through the Tailwind configuration file.
          </span>
          <span>Example of Custom Z-Index:</span>
          <CodeDisplay
            title={'Tailwind Custom Z-Index Configuration'}
            codeStr={`module.exports = {
  theme: {
    extend: {
      zIndex: {
        '100': '100',
        '200': '200',
      }
    }
  }
}`}
          />
          <span>Once defined, you can use these custom classes like so:</span>
          <CodeDisplay
            title={'Using Custom Z-Index Classes'}
            codeStr={`<div class="relative z-100 p-4 bg-green-200">
  This box has a custom z-index of 100.
</div>
<div class="relative z-200 p-4 bg-yellow-200">
  This box has a custom z-index of 200 and will appear above the green box.
</div>`}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>5. Positioning Context</span>
          <span>
            For z-index to work, the element must have a position value of
            relative, absolute, fixed, or sticky. By default, elements are
            positioned as static, which doesn't support z-index.
          </span>
        </div>
      </div>
    </div>
  );
};

export default TailwindZindex;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const CustomizingGrid = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>
        {' '}
        Customizing the Grid System
      </span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/Wqu-d_b3K-0?si=fiUuON7OxiI7Mkp6'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Advanced Layouts with Bootstrap's Grid System
        </span>
        <p>
          The grid system in Bootstrap is highly flexible, allowing for more
          than just basic layouts. You can customize how many columns each
          section of your page should occupy depending on the screen size. This
          is crucial for creating responsive designs that adapt to different
          devices like phones, tablets, and laptops.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Advanced Grid Example'}
          codeStr={`<div class="row">
  <div class="col-sm-6 col-md-4 col-lg-2">Column 1</div>
  <div class="col-sm-6 col-md-4 col-lg-2">Column 2</div>
  <div class="col-sm-12 col-md-4 col-lg-8">Column 3</div>
</div>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>Small screens (col-sm-):</strong> Columns 1 and 2 each take
            half the row's width, Column 3 takes full width.
          </li>
          <li>
            <strong>Medium screens (col-md-):</strong> All three columns divide
            the space evenly.
          </li>
          <li>
            <strong>Large screens (col-lg-):</strong> Columns 1 and 2 take less
            space, while Column 3 becomes larger.
          </li>
        </ul>
        <p>
          This level of control ensures that your webpage looks good and
          functions well on any screen size, providing an optimal user
          experience across devices.
        </p>
      </div>
    </div>
  );
};

export default CustomizingGrid;

import React from 'react';
// import classNames from 'classnames';

import Introduction from './Introduction';
import Elements from './Elements';
import Headings from './Headings';
import Paragraphs from './Paragraphs';
import Buttons from './Buttons';
import Attributes from './Attributes';
import Images from './Images';
import Hyperlinks from './Hyperlinks';
import Containers from './Containers';
import Lists from './Lists';
import { supabase } from '../../../../../config/supabaseConfig';
import Basics from './Basics';
import EditorPanel from '../../../../htmlEditor/EditorPanel';
import IdealSolution from '../../../../htmlEditor/IdealSolution';

import { htmlTopicsObj } from '../../topics';

const Index = ({
  setTopic,
  setExerciseOpen,
  topic,
  enrollData,
  setReFetch,
  setTutorialCSS,
  isExerciseOpen,
  showIdealSolution,
  setShowIdealSolution,
  currExerciseIdx,
  setCurrExerciseIdx,
}) => {
  const updateHtmlData = async (topic) => {
    const htmtPreData = enrollData?.milestone_1?.html || [];
    if (!htmtPreData.includes(topic)) {
      htmtPreData.push(topic);
      const milestone_1 = enrollData?.milestone_1;
      await supabase
        .from('full_stack_users')
        .update({
          milestone_1: {
            ...milestone_1,
            html: htmtPreData,
          },
        })
        .match({ id: enrollData.id });
      setReFetch((pre) => !pre);
    }
  };

  const htmlData = enrollData?.milestone_1?.html || [];
  const topicIsCompleted = htmlData.includes(topic);

  

  return (
    <div className='flex flex-col'>
      {!isExerciseOpen ? (
        <React.Fragment>
          {topic === 'introduction' && (
            <Introduction
              updateHtmlData={updateHtmlData}
              setTopic={setTopic}
              isCompleted={htmlData.includes('introduction')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'basics' && (
            <Basics
              updateHtmlData={updateHtmlData}
              setTopic={setTopic}
              isCompleted={htmlData.includes('basics')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'elements' && (
            <Elements
              setTopic={setTopic}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('elements')}
            />
          )}
          {topic === 'headings' && (
            <Headings
              setTopic={setTopic}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('headings')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'paragraphs' && (
            <Paragraphs
              setTopic={setTopic}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('paragraphs')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'buttons' && (
            <Buttons
              setTopic={setTopic}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('buttons')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'attributes' && (
            <Attributes
              setTopic={setTopic}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('attributes')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'images' && (
            <Images
              setTopic={setTopic}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('images')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'hyperlinks' && (
            <Hyperlinks
              setTopic={setTopic}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('hyperlinks')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
          {topic === 'containers' && (
            <Containers
              setTopic={setTopic}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('containers')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
            />
          )}
          {topic === 'lists' && (
            <Lists
              setTopic={setTopic}
              setTutorialCSS={setTutorialCSS}
              updateHtmlData={updateHtmlData}
              isCompleted={htmlData.includes('lists')}
              isExerciseOpen={isExerciseOpen}
              setExerciseOpen={setExerciseOpen}
              showIdealSolution={showIdealSolution}
              setShowIdealSolution={setShowIdealSolution}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <EditorPanel
            exerciseid={htmlTopicsObj[topic].exercise_id[currExerciseIdx]}
          />
          <IdealSolution
            exerciseid={htmlTopicsObj[topic].exercise_id[currExerciseIdx]}
          />
        </React.Fragment>
      )}
      <div className='flex w-fit gap-3'>
        <button
          onClick={() => {
            // 1: If topic doesnt have any exercise, switch to next topic
            if (htmlTopicsObj[topic].exercise_id.length === 0) {
              // Topic doesnt have any exercise
              updateHtmlData(topic);
              if (topic === 'lists') {
                setExerciseOpen(false);
                setTutorialCSS();
              } else {
                setTopic(htmlTopicsObj[topic].next_topic_id);
              }
              return;
            }

            // 2: If ex not open, open it
            if (!isExerciseOpen) {
              setExerciseOpen(true);
              return;
            }

            // 3: Ex open, increment index until it reaches max size.
            // If max reached, update data and change topic
            if (isExerciseOpen) {
              // Last ex in array
              if (
                currExerciseIdx ===
                htmlTopicsObj[topic].exercise_id.length - 1
              ) {
                setCurrExerciseIdx(0);
                updateHtmlData(topic);
                setExerciseOpen(false);
                if (topic === 'lists') {
                  setTutorialCSS();
                } else {
                  setTopic(htmlTopicsObj[topic].next_topic_id);
                }
                return;
              } else {
                setCurrExerciseIdx(currExerciseIdx + 1);
              }
            }
            // updateHtmlData(topic);
            // setTopic(htmlTopicsObj[topic].next_topic_id);
            // setExerciseOpen(true);
          }}
          className='px-6 py-1 border rounded-md border-primary text-primary font-semibold text-lg '>
          {topicIsCompleted ? 'Next' : 'Mark Completed & Next'}
        </button>
      </div>
    </div>
  );
};

export default Index;

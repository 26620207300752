import React, { useEffect, useState } from 'react';
import { useGetUserQuery } from '../profile/userSlice';
import { supabase } from '../../config/supabaseConfig';
import Overview from './Overview';
import Loading from '../layout/Loading';
import { partnerId } from '../../config/PartnerIds';

const Index = () => {
  const { data: userData } = useGetUserQuery();
  const user_id = userData?._id;
  const userPartnerId = userData?.partnerId?._id;
  const [enrollData, setEnrollData] = useState(null);
  const [reFetch, setReFetch] = useState(false);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('full_stack_users')
        .select()
        .match({ user_id });
      if (fetchedData[0]) {
        setEnrollData(fetchedData[0]);
        setLoading(false);
      } else {
        const preData = {
          user_id,
          bootcamp_id:
            userPartnerId === partnerId.innovationMissionPunjab ? 4 : 6,
          milestone_1: { html: [], css: [] },
          milestone_2: { bootstrap: [], js: [] },
          milestone_3: { git: [] },
        };
        await supabase.from('full_stack_users').insert(preData);
        setReFetch((pre) => !pre);
      }
    };
    if (user_id) {
      setDataFn();
    }
  }, [user_id, reFetch, userPartnerId]);

  const [peer1Done, setPeer1Done] = useState(false);
  const [peer2Done, setPeer2Done] = useState(false);

  useEffect(() => {
    const setDataFn = async () => {
      // setPeerScore(0);
      const { data: fetchedData } = await supabase
        .from('peer_evaluation_score')
        .select('user_id')
        .match({ peer_1_user_id: user_id });
      if (fetchedData[0]) {
        setPeer1Done(true);
        // setPeerScore((pre) => pre + 1);
      }

      const { data: fetchedData2 } = await supabase
        .from('peer_evaluation_score')
        .select('user_id')
        .match({ peer_2_user_id: user_id });
      if (fetchedData2[0]) {
        setPeer2Done(true);
        // setPeerScore((pre) => pre + 1);
      }
      setLoading(false);
    };
    if (user_id) {
      setDataFn();
    }
  }, [user_id]);

  let peerScore = 0;
  if (peer1Done) {
    peerScore++;
  }
  if (peer2Done) {
    peerScore++;
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        enrollData && <Overview enrollData={enrollData} peerScore={peerScore} />
      )}
    </>
  );
};

export default Index;

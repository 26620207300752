import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const ResponsiveLayouts = () => {
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-5'>
        <span className='font-semibold text-lg'> Responsive Layouts</span>
        <div className='flex flex-col gap-1'>
          <span className='font-semibold'>
            Understanding Bootstrap's Grid System
          </span>
          <span>
            Bootstrap divides your webpage into a grid of 12 columns. This makes
            it easy to organize content in different ways across various screen
            sizes.
          </span>
          <span>
            For example, on a desktop, you might want two columns side-by-side,
            but on a mobile screen, you'd prefer them stacked.
          </span>
          <span>Here's a basic example:</span>
          <CodeDisplay
            title={'Basic Grid Example'}
            codeStr={`<div class="container">
  <div class="row">
    <div class="col-md-6">Left Column</div>
    <div class="col-md-6">Right Column</div>
  </div>
</div>`}
          />
          <span>
            <SingleCode codeLine={`container`} />: Holds your content and keeps
            it centered.
          </span>
          <span>
            <SingleCode codeLine={`row`} />: Groups columns together in a
            horizontal row.
          </span>
          <span>
            <SingleCode codeLine={`column`} />: Divides the row into sections,
            each one taking a portion of the 12-column grid.
          </span>
          <span>
            This system ensures that your content looks great on all devices.
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveLayouts;

import { useEffect, useState } from 'react';
import { supabase } from '../../../config/supabaseConfig';

const TimeTracker = ({ userId, milestone_id }) => {
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    const updateTimeinDatabase = async (timeSpent) => {
      // Fetch the current total_time_spent from the database
      const { data: preData, error } = await supabase
        .from('full_stack_users')
        .select('total_time_spent')
        .eq('user_id', userId);

      if (error) {
        console.error('Error fetching user data:', error);
        return;
      }

      if (preData && preData[0]) {
        let totalTimeSpent = preData[0].total_time_spent || {};

        // If the milestone_id field doesn't exist, create it
        if (!totalTimeSpent.hasOwnProperty(milestone_id)) {
          totalTimeSpent[milestone_id] = 0;
        }

        // Update the time spent for this milestone
        totalTimeSpent[milestone_id] += timeSpent;

        // Update the total_time_spent object in the database
        const { error: updateError } = await supabase
          .from('full_stack_users')
          .update({ total_time_spent: totalTimeSpent })
          .eq('user_id', userId);

        if (updateError) {
          console.error('Error updating total_time_spent:', updateError);
        }
      }
    };

    const interval = setInterval(() => {
      if (startTime) {
        const endTime = new Date();
        const timeSpent = Math.round(
          (endTime.getTime() - startTime.getTime()) / 60000
        ); // Time spent in minutes
        if (timeSpent > 0) {
          updateTimeinDatabase(timeSpent); // Update time spent in minutes
        }
        setStartTime(new Date()); // Reset start time after each interval
      }
    }, 60000); // Update time spent every 60 seconds

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setStartTime(new Date());
      } else if (document.visibilityState === 'hidden') {
        clearInterval(interval);
      }
    };

    if (!startTime) {
      setStartTime(new Date());
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(interval);
    };
  }, [userId, startTime, milestone_id]);

  return null;
};

export default TimeTracker;

import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const BlockVsInline = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Block vs. Inline</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/PVBqZRAOZL8'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>

        <span className='font-medium'>Block Elements:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Display Behavior`} />: Blocks occupy the full
            width available and start on a new line, pushing subsequent content
            downwards.
          </li>
          <li>
            Examples: <SingleCode codeLine={`<div>`} />
            , <SingleCode codeLine={`<p>`} />
            , <SingleCode codeLine={`<h1>`} />
            -
            <SingleCode codeLine={`<h6>`} />
            , <SingleCode codeLine={`<ul>`} />
            ,
            <SingleCode codeLine={`<li>`} />
            ,
            <SingleCode codeLine={`<header>`} />
            ,
            <SingleCode codeLine={`<footer>`} />
          </li>
          <li>
            CSS Properties: Often styled with properties like `
            <SingleCode codeLine={`width`} />
            `, `<SingleCode codeLine={`padding`} />
            `, `<SingleCode codeLine={`margin`} />
            `, and `<SingleCode codeLine={`display: block`} />
            ;` (although it's the default for most block-level elements).
          </li>
        </ul>

        <span className='font-medium'>Inline Elements:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Display Behavior`} />: Inlines only take up
            as much width as necessary and do not start on a new line. They flow
            within the content.
          </li>
          <li>
            Examples:
            <SingleCode codeLine={`<span>`} />,
            <SingleCode codeLine={`<a>`} />,
            <SingleCode codeLine={`<strong>`} />,
            <SingleCode codeLine={`<em>`} />,
            <SingleCode codeLine={`<img>`} />,
          </li>
          <li>
            <SingleCode codeLine={`CSS Properties`} />:{' '}
            <SingleCode codeLine={`color`} />
            `, `<SingleCode codeLine={`font-size`} />
            `, `<SingleCode codeLine={`text-decoration`} />
            `, `<SingleCode codeLine={`display: inline;`} />
            `, or `<SingleCode codeLine={`display: inline-block;`} /> for more
            flexible styling
          </li>
        </ul>

        <span className='font-medium'>Key Differences:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Layout Behavior`} />: Block elements create
            block-level structures, while inline elements flow within text or
            other inline elements.
          </li>
          <li>
            <SingleCode codeLine={`Sizing`} />: Block elements occupy the full
            width available by default, while inline elements occupy only the
            space needed by their content.
          </li>
          <li>
            <SingleCode codeLine={`Default Display`} />: Most HTML elements are
            block-level by default, while some, like{' '}
            <SingleCode codeLine={`<span>`} /> or{' '}
            <SingleCode codeLine={`<a>`} />, are inline by default.
          </li>
        </ul>

        <span className='font-medium'>Inline-Block Elements:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`Display Behavior`} />: Combines aspects of
            both block and inline elements, allowing for block-like styling
            (e.g., setting width and height) while flowing inline.
          </li>
          <li>
            <SingleCode codeLine={`Usage`} />: Ideal for creating elements that
            need block-like styling but should appear inline, such as navigation
            links or grid items.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={`Example`}
        codeStr={`/* Styling block elements */
.block {
  width: 200px; /* Set width */
  margin: 10px; /* Set margins */
  padding: 15px; /* Set padding */
  background-color: #f0f0f0; /* Set background color */
  display: block; /* Explicitly setting display as block (though most block-level elements default to block) */
}
/* Styling inline elements */
.inline {
  color: blue; /* Set text color */
  font-size: 16px; /* Set font size */
  text-decoration: underline; /* Add underline */
  display: inline; /* Explicitly setting display as inline (though some elements default to inline) */
}
/* Styling inline-block elements */
.inline-block {
  width: 150px; /* Set width */
  height: 50px; /* Set height */
  margin: 5px; /* Set margins */
  padding: 10px; /* Set padding */
  background-color: #ccc; /* Set background color */
  display: inline-block; /* Explicitly setting display as inline-block */
}
`}
      />
    </div>
  );
};

export default BlockVsInline;

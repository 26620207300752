import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const BootstrapAlerts = () => {
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-5'>
        <span className='font-semibold text-lg'>Bootstrap Alerts</span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/StFObj54aHI?si=7yCZ2CuUKd1wF8wG'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen></iframe>
        <div className='flex flex-col gap-2'>
          <span className='font-bold'>
            Displaying Messages with Bootstrap Alerts
          </span>
          <p>
            Alerts are great for drawing attention to important messages like
            success, errors, or warnings. Bootstrap offers different alert
            styles using predefined classes.
          </p>
          <CodeDisplay
            title={'Bootstrap Alert Examples'}
            codeStr={`<div class="alert alert-success">Success! Your action was successful.</div>
<div class="alert alert-danger">Error! Something went wrong.</div>`}
          />
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`alert-success`} />: Green alert, typically
              used for positive messages.
            </li>
            <li>
              <SingleCode codeLine={`alert-danger`} />: Red alert, used for
              errors or critical messages.
            </li>
          </ul>
          <p>
            Alerts are a quick and easy way to improve user feedback on your
            website.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BootstrapAlerts;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const TailwindSpacing = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Spacing</span>
      <div className='flex flex-col gap-2'>
        <span>
          In web design, proper spacing is crucial for creating visually
          appealing layouts and ensuring good readability. Tailwind CSS provides
          an intuitive way to manage both margins and paddings through its
          utility classes. These utilities follow a consistent pattern, allowing
          developers to easily apply spacing to elements without writing custom
          CSS.
        </span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/OYdkBxbJFks?si=57pkRpYaUm5_hjQV'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowFullScreen></iframe>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>
            1. Padding (p-{'{'}size{'}'} )
          </span>
          <span>
            Padding controls the space between the content inside an element and
            its border. Tailwind uses a class pattern p-{'{'}size{'}'} to
            represent different padding sizes.
          </span>
          <p>
            <span className='font-semibold'>Padding Sizes: </span>Tailwind’s
            padding classes range from very small to large, and they can be
            applied uniformly or to specific sides of an element (e.g., top,
            bottom, left, or right). The sizes are generally based on a 4px
            scale. For instance:
          </p>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Padding Example'}
            codeStr={`<div class="p-0">No padding</div>
<div class="p-1">0.25rem (4px) padding</div>
<div class="p-4">1rem (16px) padding</div>
<div class="p-6">1.5rem (24px) padding</div>
<div class="pt-4 pr-2 bg-gray-200">This box has padding on top and right!</div>
<div class="px-4 py-2 bg-blue-100">This box has horizontal and vertical padding!</div>`}
          />
          <div className='flex flex-col gap-2'>
            <span className='font-semibold'>Individual Side Padding</span>
            <span>
              Tailwind allows you to control padding on specific sides of an
              element. The syntax follows pt-{'{size}'}, pr-{'{size}'}, pb-
              {'{size}'}, and pl-{'{size}'} for padding-top, padding-right,
              padding-bottom, and padding-left respectively.
            </span>
            <span>For example:</span>
            <ul className='list-disc pl-5'>
              <li>pt-4: 1rem padding on top.</li>
              <li>pr-2: 0.5rem padding on the right.</li>
            </ul>
            <CodeDisplay
              title={'Tailwind Individual Side Padding Example'}
              codeStr={`<div class="pt-4 pr-2 bg-gray-200">This box has padding on top and right!</div>`}
            />
          </div>
          <div className='flex flex-col gap-2'>
            <span className='font-semibold'>
              Horizontal and Vertical Padding
            </span>
            <span>
              Tailwind also supports padding on the horizontal and vertical
              axes:
            </span>
            <ul className='list-disc pl-5'>
              <li>px-{'{size}'}: Horizontal padding (left and right).</li>
              <li>py-{'{size}'}: Vertical padding (top and bottom).</li>
            </ul>
            <CodeDisplay
              title={'Tailwind Horizontal and Vertical Padding Example'}
              codeStr={`<div class="px-4 py-2 bg-blue-100">This box has horizontal and vertical padding!</div>`}
            />
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>
            2. Margin (m-{'{'}size{'}'} )
          </span>
          <span>
            Margins create space between the outside of an element and other
            elements. Tailwind provides similar utilities for margins as it does
            for padding, using the class pattern m-{'{'}size{'}'}.
          </span>
          <span>
            Margin Sizes: Tailwind margins work in the same scale as padding.
            For example:
          </span>
          <ul className='list-disc pl-5'>
            <li>m-0: No margin.</li>
            <li>m-1: 0.25rem (4px) margin.</li>
            <li>m-4: 1rem (16px) margin.</li>
          </ul>
          <CodeDisplay
            title={'Tailwind Margin Example'}
            codeStr={`<div class="m-4 bg-blue-200">This box has a 1rem margin all around!</div>`}
          />
          <span>
            Individual Side Margins: Just like padding, you can apply margins to
            specific sides of an element:
          </span>
          <ul className='list-disc pl-5'>
            <li>mt-4: 1rem margin on top.</li>
            <li>mb-2: 0.5rem margin at the bottom.</li>
          </ul>
          <CodeDisplay
            title={'Tailwind Individual Side Margin Example'}
            codeStr={`<div class="mt-4 mb-2 bg-gray-100">This box has top and bottom margins!</div>`}
          />
          <span>
            Negative Margins: Tailwind supports negative margins, which can be
            useful for overlapping elements:
          </span>
          <ul className='list-disc pl-5'>
            <li>-m-2: -0.5rem margin.</li>
            <li>-mt-4: -1rem margin on top.</li>
          </ul>
          <CodeDisplay
            title={'Tailwind Negative Margin Example'}
            codeStr={`<div class="-mt-4 bg-red-100">This box has a negative top margin!</div>`}
          />
          <span>
            Auto Margin: Tailwind also provides m-auto to automatically center
            an element horizontally within its container.
          </span>
          <CodeDisplay
            title={'Tailwind Auto Margin Example'}
            codeStr={`<div class="m-auto w-1/2 bg-green-100">This box is centered horizontally!</div>`}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>3. Combined Example</span>
          <span>
            You can use padding and margin together for better spacing control.
          </span>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Combined Spacing Example'}
            codeStr={`<div class="m-4 p-6 bg-blue-100">
  This box has a 1rem margin and 1.5rem padding!
</div>`}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>4. Responsive Spacing</span>
          <span>
            Tailwind also provides responsive control over spacing. You can
            apply different spacing values at different screen sizes using
            responsive prefixes like sm:, md:, lg:, and xl:.
          </span>
          <CodeDisplay
            title={'Tailwind Responsive Spacing Example'}
            codeStr={`<div class="m-2 sm:m-4 lg:m-8 p-2 sm:p-4 lg:p-8 bg-purple-100">
  Responsive spacing!
</div>`}
          />
          <p>
            On small screens, the margin and padding are 0.5rem and 1rem,
            respectively.
          </p>
          <p>
            On medium screens, the margin and padding are 1rem and 2rem,
            respectively.
          </p>

          <p>
            This example shows how spacing can change responsively based on
            screen size.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TailwindSpacing;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const Jumbotron = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'> Jumbotron</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/ddXzqJ7x3Gk?si=Cdo_zA5Ey0nZEAzk'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Creating Attention-Grabbing Sections with a Jumbotron
        </span>
        <p>
          The jumbotron is a large, full-width component that draws attention to
          important content. It is often used for headlines, call-to-action
          sections, or announcements.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Jumbotron Example'}
          codeStr={`<div class="jumbotron">
<h1 class="display-4">Hello, World!</h1>
<p>This is a simple hero unit to call attention to content.</p>
<a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
</div>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>display-4:</strong> Makes the heading larger and more
            prominent.
          </li>
        </ul>
        <p>
          Jumbotrons are great for showcasing key messages or actions you want
          users to focus on.
        </p>
      </div>
    </div>
  );
};

export default Jumbotron;

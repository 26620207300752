import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Units = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Units</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/-GR52czEd-0'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          CSS utilizes diverse length units to define measurements crucial in
          properties like width and margin, providing versatility in design.
        </span>
        <span>Length units fall into two main categories</span>
        <ul className='list-disc px-5 space-y-3'>
          <li>
            <SingleCode codeLine={`Absolute Units`} />: Retain a fixed size
            regardless of context or screen dimensions. These units maintain
            consistency but lack adaptability across varying screen sizes.
            Examples include pixels (`px`), inches (`in`), centimetres (`cm`),
            etc.
            <CodeDisplay
              title={'Example'}
              codeStr={`.example {
	width: 200px; /* Fixed width of 200 pixels */
	font-size: 16px; /* Text size set to 16 pixels */
	margin: 10px; /* Margin around the element set to 10 pixels */
	border-width: 2px; /* Border width of 2 pixels */
}
`}
            />
          </li>
          <li>
            <SingleCode codeLine={`Relative Units`} />: Scale in relation to
            other elements or viewport dimensions. These units offer
            adaptability, resizing proportionally based on context. Examples
            include percentages (`%`), viewport units (`vw`, `vh`, `vmin`,
            `vmax`), etc.
            <CodeDisplay
              title={'Example'}
              codeStr={`.example {
	width: 50%; /* Width taking up 50% of its containing element */
	padding: 10%; /* Padding relative to the width of the element */
	font-size: 120%; /* Text size increased by 20% from the parent element's font size */
}
              `}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Units;

import React from 'react';

const Index = () => {
  return (
    <div >
  {/* <!-- Analysis Section --> */}
  <div class="mb-6">
    <h1 class="text-2xl font-semibold text-gray-800">Analysis</h1>
    <p class="text-gray-600 mb-4">
      Based on your self-assessment and performance in the skill test, we've grouped your skills into four categories: 
      <strong> Under-Confidence</strong>, <strong>Area for Improvement</strong>, <strong>Strength</strong>, and <strong>Over-Confidence</strong>.
    </p>
    <p class="text-gray-600">
      These categories are designed to offer a balanced understanding of where your strengths lie and where there's potential for 
      further growth. Each section provides insights into how your self-perception aligns with your actual performance, along with 
      tailored feedback to help guide your development and next steps.
    </p>
  </div>

  {/* <!-- Grid Layout for Four Categories --> */}
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
    
    {/* <!-- Under-Confidence --> */}
    <div class="p-6 bg-[#F1FDFE] rounded-lg shadow-md">
      <div className='flex justify-between'>
      <div>
        <h2 class="text-lg font-semibold  mb-2">Under-Confidence</h2>
      <p class=" mb-4">
        You have more skill than you realize; focus on building confidence through advanced practice.
      </p>
      </div>
      {/* image part */}
      <div>
        <img src="/images/logo1.png" className='object-contain w-20' alt="logo"></img>
      </div>
      </div>
      <div class="">
        <p><strong>Skills:</strong> [Value], [Value]</p>
        <p><strong>Feedback:</strong> [Value]</p>
        <p><strong>Next Steps:</strong> [Value]</p>
      </div>
    </div>
    
    {/* <!-- Area for Improvement --> */}
    <div class="p-6 bg-[#FFFBE6] rounded-lg shadow-md">
    <div className='flex justify-between'>
      <div>
      <h2 class="text-lg font-semibold  mb-2">Area for Improvement</h2>
      <p class=" mb-4">
        There are gaps in your understanding; review key concepts and practice more to strengthen your foundation.
      </p>
      </div>
      {/* image part */}
      <div>
        <img src="/images/logo2.png" className='object-contain w-24' alt="logo"></img>
      </div>
      </div>
     
      <div class="">
        <p><strong>Skills:</strong> [Value], [Value]</p>
        <p><strong>Feedback:</strong> [Value]</p>
        <p><strong>Next Steps:</strong> [Value]</p>
      </div>
    </div>
    
    {/* <!-- Strength --> */}
    <div class="p-6 bg-[#F8FCE9] rounded-lg shadow-md">
    <div className='flex justify-between'>
      <div>
      <h2 class="text-lg font-semibold  mb-2">Strength</h2>
      <p class=" mb-4">
        You have a strong understanding in this area; challenge yourself with more complex projects to continue growing.
      </p>
      </div>
      {/* image part */}
      <div>
      <img src="/images/logo3.png" className='object-contain w-24' alt="logo"></img>
      </div>
      </div>
      
      <div class="">
        <p><strong>Skills:</strong> [Value], [Value]</p>
        <p><strong>Feedback:</strong> [Value]</p>
        <p><strong>Next Steps:</strong> [Value]</p>
      </div>
    </div>
    
    {/* <!-- Over-Confidence --> */}
    <div class="p-6 bg-[#FEF1F0] rounded-lg shadow-md">
    <div className='flex justify-between'>
      <div>
      <h2 class="text-lg font-semibold  mb-2">Over-Confidence</h2>
      <p class=" mb-4">
        Your self-perception is higher than your performance; revisit foundational concepts to address gaps in your knowledge.
      </p>
      </div>
      {/* image part */}
      <div>
      <img src="/images/logo4.png" className='object-contain w-24' alt="logo"></img>
      </div>
      </div>
     
      <div class="">
        <p><strong>Skills:</strong> [Value], [Value]</p>
        <p><strong>Feedback:</strong> [Value]</p>
        <p><strong>Next Steps:</strong> [Value]</p>
      </div>
    </div>

  </div>
</div>

  );
};

export default Index;
import React, { useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';

import PhoneInput from 'react-phone-input-2';
import TakePayment from './TakePayment';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { API_URL } from '../../config/API_LINKS';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '../layout/FormElement';

const BasicDetails = ({ setCurrentScreen }) => {
  const [showPayment, setShowPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+91');
  const [orderCollectionId, setOrderCollectionId] = useState(null);
  const [amount, setAmount] = useState('2499');
  const [couponCode, setCouponCode] = useState('');
  const [showCouponMessage, setShowCouponMessge] = useState(false);

  const offers = {
    FSP5299: '299',
    SYN5088: '299',
    WEB0299: '299',
    BRND088: '299',
    CODE299: '299',
    FSP5499: '499',
    SYN5084: '399',
    WEB0499: '499',
    BRND084: '399',
  };

  return (
    <div className='p-4 sm:p-8'>
      <div className='sm:w-3/4  mx-auto  space-y-6'>
        <Link to='/'>
          <div className='flex items-center text-gray-600 cursor-pointer'>
            <FaAngleLeft />
            <button className='ml-2'> Back</button>
          </div>
        </Link>
        <div className='space-y-3'>
          <p className='font-bold text-lg'>
            Register, Pay, and Secure Your Seat
          </p>
          <p>
            Complete the form below to not miss out on your spot in the online
            Full Stack Bootcamp (Front-End) and proceed to payment.
          </p>
        </div>
        <div>
          <Formik
            // innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              name: '',
              email: '',
              phone: '',
              orderType: 'FSD',
              couponCode: '',
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Name is required'),
              email: Yup.string().email().required(),
              phone: Yup.string()
                .required()
                .matches(
                  new RegExp('^[0-9]{12,13}$'),
                  'Phone number is not valid'
                ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setEmail(values.email);
              setPhone(values.phone);

              if (Object.keys(offers).includes(values.couponCode)) {
                setCouponCode(values.couponCode);
                setShowCouponMessge(true);
                setAmount(offers[values.couponCode]);
              }

              const updatedData = { ...values };

              axios({
                method: 'POST',
                url: API_URL + '/order/add-order',
                data: updatedData,
              })
                .then(async function (response) {
                  if (response?.data?.data?.status === 'Paid') {
                    setCurrentScreen('SUCCESS');
                  } else {
                    setOrderCollectionId(response?.data?.data?._id);
                    setShowPayment(true);
                    setPaymentLoading(true);
                    setSubmitting(false);
                  }
                })
                .catch(function (error) {
                  setSubmitting(false);
                });
            }}>
            {({ isSubmitting, setFieldValue, errors }) => (
              <Form>
                <div className='space-y-4 border-2 shadow-md p-4 rounded-2xl mb-6 font-medium'>
                  {/* Student details */}
                  <div className='space-y-4'>
                    <p className='text-2xl font-semibold'>Student Details</p>
                    <div className='space-y-1 w-[90%] md:w-2/3'>
                      <p className='ml-2'>Name</p>
                      <TextInput name='name' type='text' label='Name' />
                    </div>
                    <div className='items-center space-y-4 md:space-y-0 md:space-x-10'>
                      <div className='space-y-1  w-[90%]  md:w-2/3'>
                        <p className='ml-2'>Email</p>
                        <TextInput name='email' type='text' label='Email' />
                      </div>
                    </div>
                    <div className='mt-8 ml-2 md:w-2/3 lg:w-1/3'>
                      <label className='block mb-2 text-sm text-left'>
                        Contact Number
                      </label>
                      <div className='w-56'>
                        <PhoneInput
                          preferredCountries={['in', 'za']}
                          country={'in'}
                          inputStyle={{ width: '100%' }}
                          onChange={(phone) => setFieldValue('phone', phone)}
                          countryCodeEditable={false}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                          }}
                        />
                        <p className='text-sm text-red-700'>{errors.phone} </p>
                      </div>
                    </div>
                    <div className='space-y-1 w-[90%] md:w-2/3'>
                      <p className='ml-2'>Coupon Code (if you Have)</p>
                      <TextInput
                        name='couponCode'
                        type='text'
                        label='couponCode'
                      />
                    </div>
                  </div>
                  {showCouponMessage && (
                    <div className='text-green-500'>
                      {' '}
                      Coupon applied successfully! You’ve saved Rs.{' '}
                      {2499 - amount} with the limited-time early bird offer.{' '}
                    </div>
                  )}
                  <div className='ml-2'>
                    <button
                      disabled={isSubmitting}
                      type='submit'
                      className='border px-4 text-lg font-medium py-1 text-white bg-primary rounded-lg '>
                      {isSubmitting ? 'Submitting' : 'Next'}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className='mt-10 mx-auto sm:w-3/4'>
        {' '}
        <span>
          Still deciding? No worries—just fill out our{' '}
          <a
            href='https://forms.gle/wpCqNeY45kUdAqPF6'
            target='_blank'
            rel='noreferrer'
            className='text-blue-500 underline'>
            interest form
          </a>{' '}
          , and our team will be in touch to help you explore your options!
        </span>{' '}
      </div>

      {showPayment && orderCollectionId && (
        <TakePayment
          couponCode={couponCode}
          email={email}
          phone={phone}
          // amount={2499}
          amount={amount}
          setCurrentScreen={setCurrentScreen}
          setPaymentLoading={setPaymentLoading}
          setShowPayment={setShowPayment}
          orderCollectionId={orderCollectionId}
        />
      )}
      {paymentLoading && (
        <div className='justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-gray-50 bg-opacity-80 z-50'>
          <div className=' w-screen my-6 mx-auto '>
            <div className='border-0 p-8 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none'>
              {' '}
              Loading{' '}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicDetails;

// Exercise id either '' or has some value
const htmlTopics = [
  {
    id: 'introduction',
    title: 'Introduction',
    next_topic_id: 'elements',
    exercise_id: [],
    exercise_task: [],
  },
  {
    id: 'elements',
    title: 'Elements',
    next_topic_id: 'headings',
    exercise_id: [],
    exercise_task: [],
  },
  {
    id: 'headings',
    title: 'Headings',
    next_topic_id: 'paragraphs',
    exercise_id: ['html-heading'],
    exercise_task: ['Task: HTML Heading'],
  },
  {
    id: 'paragraphs',
    title: 'Paragraphs',
    next_topic_id: 'buttons',
    exercise_id: ['html-paragraph-1', 'html-paragraph-2'],
    exercise_task: ['Task: HTML Paragraph 1', 'Task: HTML Paragraph 2'],
  },
  {
    id: 'buttons',
    title: 'Buttons',
    next_topic_id: 'attributes',
    exercise_id: ['html-button-1', 'html-button-2'],
    exercise_task: ['Task: HTML Button 1', 'Task: HTML Button 2'],
  },
  {
    id: 'attributes',
    title: 'Attributes',
    next_topic_id: 'images',
    exercise_id: ['html-attribute-1', 'html-attribute-2'],
    exercise_task: ['Task: HTML Attributes 1', 'Task: HTML Attributes 2'],
  },
  {
    id: 'images',
    title: 'Images',
    next_topic_id: 'hyperlinks',
    exercise_id: ['html-image'],
    exercise_task: ['Task: HTML Image'],
  },
  {
    id: 'hyperlinks',
    title: 'Hyperlinks',
    next_topic_id: 'containers',
    exercise_id: ['html-hyperlink'],
    exercise_task: ['Task: HTML Hyperlink'],
  },
  {
    id: 'containers',
    title: 'Containers',
    next_topic_id: 'lists',
    exercise_id: [],
    exercise_task: [],
  },
  {
    id: 'lists',
    title: 'Lists',
    next_topic_id: '',
    exercise_id: ['html-lists-1', 'html-lists-2'],
    exercise_task: ['Task: HTML Lists 1', 'Task: HTML Lists 2'],
  },
];

const cssTopics = [
  {
    id: 'introduction',
    title: 'Introduction',
    next_topic_id: 'rulesets',
    exercise_id: ['introduction'],
    exercise_task: ['Task: CSS Introduction and Basics'],
  },
  {
    id: 'rulesets',
    title: 'Rulesets',
    next_topic_id: 'properties',
    exercise_id: [''],
    exercise_task: ['Task: Add CSS'],
  },
  {
    id: 'properties',
    title: 'Properties',
    next_topic_id: 'colors',
    exercise_id: [''],
    exercise_task: ['Task: Add CSS'],
  },
  {
    id: 'colors',
    title: 'Colors',
    next_topic_id: 'units',
    exercise_id: ['css-color'],
    exercise_task: ['Task: Add Color'],
  },
  {
    id: 'units',
    title: 'Units',
    next_topic_id: 'boxModel',
    exercise_id: ['css-units-1', 'css-units-2'],
    exercise_task: ['Task: Add Unit 1', 'Task: Add Unit 2'],
  },
  {
    id: 'boxModel',
    title: 'Box Model',
    next_topic_id: 'margins',
    exercise_id: ['css-boxModel'],
    exercise_task: ['Task: Add Box Model'],
  },
  {
    id: 'margins',
    title: 'Margins',
    next_topic_id: 'paddings',
    exercise_id: ['css-margins'],
    exercise_task: ['Task: Add Margins'],
  },
  {
    id: 'paddings',
    title: 'Paddings',
    next_topic_id: 'borders',
    exercise_id: ['css-padding'],
    exercise_task: ['Task: Add Paddings'],
  },
  {
    id: 'borders',
    title: 'Borders',
    next_topic_id: 'block-vs-inline',
    exercise_id: ['css-border'],
    exercise_task: ['Task: Add Borders'],
  },
  {
    id: 'block-vs-inline',
    title: 'Block vs Inline',
    next_topic_id: 'selectors',
    exercise_id: ['css-inlineBlock'],
    exercise_task: ['Task: Block and Inline'],
  },
  {
    id: 'selectors',
    title: 'Selectors',
    next_topic_id: 'specificity',
    exercise_id: ['css-selector'],
    exercise_task: ['Task: Add Selectors'],
  },
  {
    id: 'specificity',
    title: 'Specificity',
    next_topic_id: 'inheritance',
    exercise_id: ['css-specificity'],
    exercise_task: ['Task: Add Specificity'],
  },
  {
    id: 'inheritance',
    title: 'Inheritance',
    next_topic_id: 'cascading',
    exercise_id: ['css-inheritance'],
    exercise_task: ['Task: Add Inheritance'],
  },
  {
    id: 'cascading',
    title: 'Cascading',
    next_topic_id: '',
    exercise_id: [''],
    exercise_task: ['Task: Add Cascading'],
  },
];

const cssTopicsObj = {
  introduction: {
    id: 'introduction',
    title: 'Introduction',
    next_topic_id: 'rulesets',
    exercise_id: ['introduction'],
    exercise_task: ['Task: CSS Introduction and Basics'],
  },
  rulesets: {
    id: 'rulesets',
    title: 'Rulesets',
    next_topic_id: 'properties',
    exercise_id: [''],
    exercise_task: ['Task: Add CSS'],
  },
  properties: {
    id: 'properties',
    title: 'Properties',
    next_topic_id: 'colors',
    exercise_id: [''],
    exercise_task: ['Task: Add CSS'],
  },
  colors: {
    id: 'colors',
    title: 'Colors',
    next_topic_id: 'units',
    exercise_id: ['css-color'],
    exercise_task: ['Task: Add Color'],
  },
  units: {
    id: 'units',
    title: 'Units',
    next_topic_id: 'boxModel',
    exercise_id: ['css-units-1', 'css-units-2'],
    exercise_task: ['Task: Add Unit 1', 'Task: Add Unit 2'],
  },
  boxModel: {
    id: 'boxModel',
    title: 'Box Model',
    next_topic_id: 'margins',
    exercise_id: ['css-boxModel'],
    exercise_task: ['Task: Add Box Model'],
  },
  margins: {
    id: 'margins',
    title: 'Margins',
    next_topic_id: 'paddings',
    exercise_id: ['css-margins'],
    exercise_task: ['Task: Add Margins'],
  },
  paddings: {
    id: 'paddings',
    title: 'Paddings',
    next_topic_id: 'borders',
    exercise_id: ['css-padding'],
    exercise_task: ['Task: Add Paddings'],
  },
  borders: {
    id: 'borders',
    title: 'Borders',
    next_topic_id: 'block-vs-inline',
    exercise_id: ['css-border'],
    exercise_task: ['Task: Add Borders'],
  },
  'block-vs-inline': {
    id: 'block-vs-inline',
    title: 'Block vs Inline',
    next_topic_id: 'selectors',
    exercise_id: ['css-inlineBlock'],
    exercise_task: ['Task: Block and Inline'],
  },
  selectors: {
    id: 'selectors',
    title: 'Selectors',
    next_topic_id: 'specificity',
    exercise_id: ['css-selector'],
    exercise_task: ['Task: Add Selectors'],
  },
  specificity: {
    id: 'specificity',
    title: 'Specificity',
    next_topic_id: 'inheritance',
    exercise_id: ['css-specificity'],
    exercise_task: ['Task: Add Specificity'],
  },
  inheritance: {
    id: 'inheritance',
    title: 'Inheritance',
    next_topic_id: 'cascading',
    exercise_id: ['css-inheritance'],
    exercise_task: ['Task: Add Inheritance'],
  },
  cascading: {
    id: 'cascading',
    title: 'Cascading',
    next_topic_id: '',
    exercise_id: [''],
    exercise_task: ['Task: Add Cascading'],
  },
};

const htmlTopicsObj = {
  introduction: {
    id: 'introduction',
    title: 'Introduction',
    next_topic_id: 'elements',
    exercise_id: [],
    exercise_task: [],
  },
  elements: {
    id: 'elements',
    title: 'Elements',
    next_topic_id: 'headings',
    exercise_id: [],
    exercise_task: [],
  },
  headings: {
    id: 'headings',
    title: 'Headings',
    next_topic_id: 'paragraphs',
    exercise_id: ['html-heading'],
    exercise_task: ['Task: HTML Heading'],
  },
  paragraphs: {
    id: 'paragraphs',
    title: 'Paragraphs',
    next_topic_id: 'buttons',
    exercise_id: ['html-paragraph-1', 'html-paragraph-2'],
    exercise_task: ['Task: HTML Paragraph 1', 'Task: HTML Paragraph 2'],
  },
  buttons: {
    id: 'buttons',
    title: 'Buttons',
    next_topic_id: 'attributes',
    exercise_id: ['html-button-1', 'html-button-2'],
    exercise_task: ['Task: HTML Button 1', 'Task: HTML Button 2'],
  },
  attributes: {
    id: 'attributes',
    title: 'Attributes',
    next_topic_id: 'images',
    exercise_id: ['html-attribute-1', 'html-attribute-2'],
    exercise_task: ['Task: HTML Attributes 1', 'Task: HTML Attributes 2'],
  },
  images: {
    id: 'images',
    title: 'Images',
    next_topic_id: 'hyperlinks',
    exercise_id: ['html-image'],
    exercise_task: ['Task: HTML Image'],
  },
  hyperlinks: {
    id: 'hyperlinks',
    title: 'Hyperlinks',
    next_topic_id: 'containers',
    exercise_id: ['html-hyperlink'],
    exercise_task: ['Task: HTML Hyperlink'],
  },
  containers: {
    id: 'containers',
    title: 'Containers',
    next_topic_id: 'lists',
    exercise_id: [],
    exercise_task: [],
  },
  lists: {
    id: 'lists',
    title: 'Lists',
    next_topic_id: '',
    exercise_id: ['html-lists-1', 'html-lists-2'],
    exercise_task: ['Task: HTML Lists 1', 'Task: HTML Lists 2'],
  },
};

const bootstrapTopics = [
  {
    id: 'introduction',
    title: 'Bootstrap Introduction',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'including-bootstrap',
  },
  {
    id: 'including-bootstrap',
    title: 'Including Bootstrap',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'responsive-layouts',
  },
  {
    id: 'responsive-layouts',
    title: 'Responsive Layouts',
    exercise_id: ['bootstrap-layout'],
    exercise_task: ['Add: Bootstrap typography'],
    next_topic_id: 'bootstrap-typography',
  },
  {
    id: 'bootstrap-typography',
    title: 'Typography',
    exercise_id: ['bootstrap-typography'],
    exercise_task: ['Add: Bootstrap typography'],
    next_topic_id: 'button',
  },
  {
    id: 'button',
    title: 'Buttons',
    exercise_id: ['bootstrap-button'],
    exercise_task: ['Add: Bootstrap buttons'],
    next_topic_id: 'bootstrap-images',
  },
  {
    id: 'bootstrap-images',
    title: 'Images',
    exercise_id: ['bootstrap-image'],
    exercise_task: ['Add: Bootstrap images'],
    next_topic_id: 'bootstrap-alerts',
  },
  {
    id: 'bootstrap-alerts',
    title: 'Alerts',
    exercise_id: ['bootstrap-alert'],
    exercise_task: ['Add: Bootstrap alerts'],
    next_topic_id: 'tables',
  },
  {
    id: 'tables',
    title: 'Tables',
    exercise_id: ['bootstrap-table'],
    exercise_task: ['Add: Bootstrap tables'],
    next_topic_id: 'forms',
  },
  {
    id: 'forms',
    title: 'Forms',
    exercise_id: ['bootstrap-form'],
    exercise_task: ['Add: Bootstrap forms'],
    next_topic_id: 'badges',
  },
  {
    id: 'badges',
    title: 'Badges',
    exercise_id: ['bootstrap-badge'],
    exercise_task: ['Add: Bootstrap badges'],
    next_topic_id: 'customizing-grid',
  },
  {
    id: 'customizing-grid',
    title: 'Customizing Grid',
    exercise_id: ['bootstrap-grid'],
    exercise_task: ['Add: Bootstrap customizing grid'],
    next_topic_id: 'responsive-breakpoints',
  },
  {
    id: 'responsive-breakpoints',
    title: 'Responsive Breakpoints',
    exercise_id: ['bootstrap-responsive-breakpoint'],
    exercise_task: ['Add: Bootstrap responsive breakpoints'],
    next_topic_id: 'bootstrap-navbar',
  },
  {
    id: 'bootstrap-navbar',
    title: 'Navbar',
    exercise_id: ['bootstrap-navbar'],
    exercise_task: ['Add: Bootstrap navbar'],
    next_topic_id: 'cards',
  },
  {
    id: 'cards',
    title: 'Cards',
    exercise_id: ['bootstrap-card'],
    exercise_task: ['Add: Bootstrap cards'],
    next_topic_id: 'modals',
  },
  {
    id: 'modals',
    title: 'Modals',
    exercise_id: ['bootstrap-modal'],
    exercise_task: ['Add: Bootstrap modals'],
    next_topic_id: 'tooltips',
  },
  {
    id: 'tooltips',
    title: 'Tooltips',
    exercise_id: ['bootstrap-tooltip'],
    exercise_task: ['Add: Bootstrap tooltips'],
    next_topic_id: 'collapsible-panels',
  },
  {
    id: 'collapsible-panels',
    title: 'Collapsible Panels',
    exercise_id: ['bootstrap-collapsible-panel'],
    exercise_task: ['Add: Bootstrap collapsible panels'],
    next_topic_id: 'grid-alignment',
  },
  {
    id: 'grid-alignment',
    title: 'Grid Alignment',
    exercise_id: ['bootstrap-grid'],
    exercise_task: ['Add: Bootstrap grid alignment'],
    next_topic_id: 'jumbotron',
  },
  {
    id: 'jumbotron',
    title: 'Jumbotron',
    exercise_id: ['bootstrap-jumbotron'],
    exercise_task: ['Add: Bootstrap jumbotron'],
    next_topic_id: 'bootstrap-pagination',
  },
  {
    id: 'bootstrap-pagination',
    title: 'Pagination',
    exercise_id: ['bootstrap-pagination'],
    exercise_task: ['Add: Bootstrap pagination'],
    next_topic_id: 'tailwind-introduction',
  },
  {
    id: 'tailwind-introduction',
    title: 'Tailwind Introduction',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'tailwind-colors',
  },
  {
    id: 'tailwind-colors',
    title: 'Colors',
    exercise_id: ['tailwind-colors'],
    exercise_task: ['Task: Add Colors'],
    next_topic_id: 'tailwind-spacing',
  },
  {
    id: 'tailwind-spacing',
    title: 'Spacing',
    exercise_id: ['tailwind-spacing'],
    exercise_task: ['Task: Add Spacing'],
    next_topic_id: 'tailwind-fonts',
  },
  {
    id: 'tailwind-fonts',
    title: 'Fonts and Text',
    exercise_id: ['tailwind-fonts'],
    exercise_task: ['Task: Add Fonts and Text'],
    next_topic_id: 'tailwind-layout',
  },
  {
    id: 'tailwind-layout',
    title: 'Layout',
    exercise_id: ['tailwind-layout'],
    exercise_task: ['Task: Add Layout'],
    next_topic_id: 'tailwind-borders',
  },
  {
    id: 'tailwind-borders',
    title: 'Borders',
    exercise_id: ['tailwind-borders'],
    exercise_task: ['Task: Add Borders'],
    next_topic_id: 'tailwind-shadows',
  },
  {
    id: 'tailwind-shadows',
    title: 'Shadows',
    exercise_id: ['tailwind-shadows'],
    exercise_task: ['Task: Add Shadows'],
    next_topic_id: 'tailwind-zIndex',
  },
  {
    id: 'tailwind-zIndex',
    title: 'Z-Index',
    exercise_id: ['tailwind-zIndex'],
    exercise_task: ['Task: Add Z-Index'],
    next_topic_id: 'tailwind-transitions',
  },
  {
    id: 'tailwind-transitions',
    title: 'Transitions',
    exercise_id: ['tailwind-transitions'],
    exercise_task: ['Task: Add Transitions'],
    next_topic_id: '',
  },
];

const bootstrapTopicsObj = {
  introduction: {
    id: 'introduction',
    title: 'Bootstrap Introduction',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'including-bootstrap',
  },
  'including-bootstrap': {
    id: 'including-bootstrap',
    title: 'Including Bootstrap',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'responsive-layouts',
  },
  'responsive-layouts': {
    id: 'responsive-layouts',
    title: 'Responsive Layouts',
    exercise_id: ['bootstrap-layout'],
    exercise_task: ['Add: Bootstrap typography'],
    next_topic_id: 'bootstrap-typography',
  },
  'bootstrap-typography': {
    id: 'bootstrap-typography',
    title: 'Typography',
    exercise_id: ['bootstrap-typography'],
    exercise_task: ['Add: Bootstrap typography'],
    next_topic_id: 'button',
  },
  button: {
    id: 'button',
    title: 'Buttons',
    exercise_id: ['bootstrap-button'],
    exercise_task: ['Add: Bootstrap buttons'],
    next_topic_id: 'bootstrap-images',
  },
  'bootstrap-images': {
    id: 'bootstrap-images',
    title: 'Images',
    exercise_id: ['bootstrap-image'],
    exercise_task: ['Add: Bootstrap images'],
    next_topic_id: 'bootstrap-alerts',
  },
  'bootstrap-alerts': {
    id: 'bootstrap-alerts',
    title: 'Alerts',
    exercise_id: ['bootstrap-alert'],
    exercise_task: ['Add: Bootstrap alerts'],
    next_topic_id: 'tables',
  },
  tables: {
    id: 'tables',
    title: 'Tables',
    exercise_id: ['bootstrap-table'],
    exercise_task: ['Add: Bootstrap tables'],
    next_topic_id: 'forms',
  },
  forms: {
    id: 'forms',
    title: 'Forms',
    exercise_id: ['bootstrap-form'],
    exercise_task: ['Add: Bootstrap forms'],
    next_topic_id: 'badges',
  },
  badges: {
    id: 'badges',
    title: 'Badges',
    exercise_id: ['bootstrap-badge'],
    exercise_task: ['Add: Bootstrap badges'],
    next_topic_id: 'customizing-grid',
  },
  'customizing-grid': {
    id: 'customizing-grid',
    title: 'Customizing Grid',
    exercise_id: ['bootstrap-grid'],
    exercise_task: ['Add: Bootstrap customizing grid'],
    next_topic_id: 'responsive-breakpoints',
  },
  'responsive-breakpoints': {
    id: 'responsive-breakpoints',
    title: 'Responsive Breakpoints',
    exercise_id: ['bootstrap-responsive-breakpoint'],
    exercise_task: ['Add: Bootstrap responsive breakpoints'],
    next_topic_id: 'bootstrap-navbar',
  },
  'bootstrap-navbar': {
    id: 'bootstrap-navbar',
    title: 'Navbar',
    exercise_id: ['bootstrap-navbar'],
    exercise_task: ['Add: Bootstrap navbar'],
    next_topic_id: 'cards',
  },
  cards: {
    id: 'cards',
    title: 'Cards',
    exercise_id: ['bootstrap-card'],
    exercise_task: ['Add: Bootstrap cards'],
    next_topic_id: 'modals',
  },
  modals: {
    id: 'modals',
    title: 'Modals',
    exercise_id: ['bootstrap-modal'],
    exercise_task: ['Add: Bootstrap modals'],
    next_topic_id: 'tooltips',
  },
  tooltips: {
    id: 'tooltips',
    title: 'Tooltips',
    exercise_id: ['bootstrap-tooltip'],
    exercise_task: ['Add: Bootstrap tooltips'],
    next_topic_id: 'collapsible-panels',
  },
  'collapsible-panels': {
    id: 'collapsible-panels',
    title: 'Collapsible Panels',
    exercise_id: ['bootstrap-collapsible-panel'],
    exercise_task: ['Add: Bootstrap collapsible panels'],
    next_topic_id: 'grid-alignment',
  },
  'grid-alignment': {
    id: 'grid-alignment',
    title: 'Grid Alignment',
    exercise_id: ['bootstrap-grid'],
    exercise_task: ['Add: Bootstrap grid alignment'],
    next_topic_id: 'jumbotron',
  },
  jumbotron: {
    id: 'jumbotron',
    title: 'Jumbotron',
    exercise_id: ['bootstrap-jumbotron'],
    exercise_task: ['Add: Bootstrap jumbotron'],
    next_topic_id: 'bootstrap-pagination',
  },
  'bootstrap-pagination': {
    id: 'bootstrap-pagination',
    title: 'Pagination',
    exercise_id: ['bootstrap-pagination'],
    exercise_task: ['Add: Bootstrap pagination'],
    next_topic_id: 'tailwind-introduction',
  },
  'tailwind-introduction': {
    id: 'tailwind-introduction',
    title: 'Tailwind Introduction',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'tailwind-colors',
  },
  'tailwind-colors': {
    id: 'tailwind-colors',
    title: 'Colors',
    exercise_id: ['tailwind-colors'],
    exercise_task: ['Task: Add Colors'],
    next_topic_id: 'tailwind-spacing',
  },
  'tailwind-spacing': {
    id: 'tailwind-spacing',
    title: 'Spacing',
    exercise_id: ['tailwind-spacing'],
    exercise_task: ['Task: Add Spacing'],
    next_topic_id: 'tailwind-fonts',
  },
  'tailwind-fonts': {
    id: 'tailwind-fonts',
    title: 'Fonts and Text',
    exercise_id: ['tailwind-fonts'],
    exercise_task: ['Task: Add Fonts and Text'],
    next_topic_id: 'tailwind-layout',
  },
  'tailwind-layout': {
    id: 'tailwind-layout',
    title: 'Layout',
    exercise_id: ['tailwind-layout'],
    exercise_task: ['Task: Add Layout'],
    next_topic_id: 'tailwind-borders',
  },
  'tailwind-borders': {
    id: 'tailwind-borders',
    title: 'Borders',
    exercise_id: ['tailwind-borders'],
    exercise_task: ['Task: Add Borders'],
    next_topic_id: 'tailwind-shadows',
  },
  'tailwind-shadows': {
    id: 'tailwind-shadows',
    title: 'Shadows',
    exercise_id: ['tailwind-shadows'],
    exercise_task: ['Task: Add Shadows'],
    next_topic_id: 'tailwind-zIndex',
  },
  'tailwind-zIndex': {
    id: 'tailwind-zIndex',
    title: 'Z-Index',
    exercise_id: ['tailwind-zIndex'],
    exercise_task: ['Task: Add Z-Index'],
    next_topic_id: 'tailwind-transitions',
  },
  'tailwind-transitions': {
    id: 'tailwind-transitions',
    title: 'Transitions',
    exercise_id: ['tailwind-transitions'],
    exercise_task: ['Task: Add Transitions'],
    next_topic_id: '',
  },
};

const javascriptTopics = [
  {
    id: 'introduction',
    title: 'Introduction',
    next_topic_id: 'basics',
    exercise_id: ['js-introduction'],
    exercise_task: ['Javascript Introduction '],
  },
  {
    id: 'basics',
    title: 'Basics',
    next_topic_id: 'control-flow',
    exercise_id: ['js-basics'],
    exercise_task: ['Javascript Basics'],
  },
  {
    id: 'control-flow',
    title: 'Control Flow',
    next_topic_id: 'functions',
    exercise_id: ['js-control-flow'],
    exercise_task: ['Javascript Control Flow'],
  },
  {
    id: 'functions',
    title: 'Functions',
    next_topic_id: 'array-object',
    exercise_id: ['js-functions'],
    exercise_task: ['Javascript Functions'],
  },
  {
    id: 'array-object',
    title: 'Array and Object',
    next_topic_id: 'DomManipulation',
    exercise_id: ['js-arrays-and-objects'],
    exercise_task: ['Javascript Array and Object'],
  },
  {
    id: 'DomManipulation',
    title: 'Dom Manipulation',
    next_topic_id: 'DomEvents',
    exercise_id: ['js-dom-manipulation'],
    exercise_task: ['Javascript Dom Manipulation'],
  },
  {
    id: 'DomEvents',
    title: 'Dom Events',
    next_topic_id: 'DomEventListener',
    exercise_id: ['js-dom-event'],
    exercise_task: ['Javascript Dom Events'],
  },
  {
    id: 'DomEventListener',
    title: 'Dom Event Listener',
    next_topic_id: '',
    exercise_id: ['js-dom-event-listener'],
    exercise_task: ['Javascript Dom Event Listener'],
  },
];

const javascriptTopicsObj = {
  introduction: {
    id: 'introduction',
    title: 'Introduction',
    next_topic_id: 'basics',
    exercise_id: ['js-introduction'],
    exercise_task: ['Javascript Introduction'],
  },
  basics: {
    id: 'basics',
    title: 'Basics',
    next_topic_id: 'control-flow',
    exercise_id: ['js-basics'],
    exercise_task: ['Javascript Basics'],
  },
  'control-flow': {
    id: 'control-flow',
    title: 'Control Flow',
    next_topic_id: 'functions',
    exercise_id: ['js-control-flow'],
    exercise_task: ['Javascript Control Flow'],
  },
  functions: {
    id: 'functions',
    title: 'Functions',
    next_topic_id: 'array-object',
    exercise_id: ['js-functions'],
    exercise_task: ['Javascript Functions'],
  },
  'array-object': {
    id: 'array-object',
    title: 'Array and Object',
    next_topic_id: 'DomManipulation',
    exercise_id: ['js-arrays-and-objects'],
    exercise_task: ['Javascript Array and Object'],
  },
  DomManipulation: {
    id: 'DomManipulation',
    title: 'Dom Manipulation',
    next_topic_id: 'DomEvents',
    exercise_id: ['js-dom-manipulation'],
    exercise_task: ['Javascript Dom Manipulation'],
  },
  DomEvents: {
    id: 'DomEvents',
    title: 'Dom Events',
    next_topic_id: 'DomEventListener',
    exercise_id: ['js-dom-event'],
    exercise_task: ['Javascript Dom Events'],
  },
  DomEventListener: {
    id: 'DomEventListener',
    title: 'Dom Event Listener',
    next_topic_id: '',
    exercise_id: ['js-dom-event-listener'],
    exercise_task: ['Javascript Dom Event Listener'],
  },
};

const gitTopics = [
  {
    id: 'introduction',
    title: 'Introduction',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'creating-repo',
  },
  {
    id: 'creating-repo',
    title: 'Creating a Repository',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'github-basics',
  },
  {
    id: 'github-basics',
    title: 'Github Pages Basics',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'setting-github-pages',
  },
  {
    id: 'setting-github-pages',
    title: 'Setting Up Github Pages',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'ci-github-pages',
  },
  {
    id: 'ci-github-pages',
    title: 'Continuous Integration (CI) for Github Pages',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: '',
  },
];

const gitTopicsObj = {
  introduction: {
    id: 'introduction',
    title: 'Introduction',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'creating-repo',
  },
  'creating-repo': {
    id: 'creating-repo',
    title: 'Creating a Repository',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'github-basics',
  },
  'github-basics': {
    id: 'github-basics',
    title: 'Github Pages Basics',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'setting-github-pages',
  },
  'setting-github-pages': {
    id: 'setting-github-pages',
    title: 'Setting Up Github Pages',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'ci-github-pages',
  },
  'ci-github-pages': {
    id: 'ci-github-pages',
    title: 'Continuous Integration (CI) for Github Pages',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: '',
  },
};

const submissionTopics = [
  {
    id: 'details',
    title: 'Project Details',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'submission',
  },
  {
    id: 'submission',
    title: 'Project URL Submissions',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: '',
  },
];

const submissionTopicsObj = {
  details: {
    id: 'details',
    title: 'Project Details',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: 'submission',
  },
  submission: {
    id: 'submission',
    title: 'Project URL Submissions',
    exercise_id: [],
    exercise_task: [],
    next_topic_id: '',
  },
};

export {
  htmlTopics,
  htmlTopicsObj,
  cssTopics,
  cssTopicsObj,
  bootstrapTopics,
  bootstrapTopicsObj,
  javascriptTopics,
  javascriptTopicsObj,
  gitTopics,
  gitTopicsObj,
  submissionTopics,
  submissionTopicsObj,
};

import React from 'react';
import { CodeDisplay, SingleCode } from '../../CodeDisplay';

const Properties = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Properties</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/oAkPcpzjDBI'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          CSS properties determine the visual presentation and behavior of HTML
          elements. Here are a few examples of commonly used CSS properties
          categorized by their functionalities:
        </span>
        <div className='flex flex-col gap-3'>
          <span className='font-medium'>Text Styling</span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`color`} />: Sets the text color.
            </li>
            <li>
              <SingleCode codeLine={`font-size`} />: Determines the size of the
              font.
            </li>
            <li>
              <SingleCode codeLine={`font-family`} />: Specifies the typeface or
              font family.
            </li>
            <li>
              <SingleCode codeLine={`font-weight`} />: Controls the thickness of
              the font (e.g., boldness).
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-3'>
          <span className='font-medium'>Box Model</span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`width / height`} />: Defines the width and
              height of an element.
            </li>
            <li>
              <SingleCode codeLine={`margin`} />: Sets the space outside the
              element.
            </li>
            <li>
              <SingleCode codeLine={`padding`} />: Defines the space between the
              element's content and its border.
            </li>
            <li>
              <SingleCode codeLine={`border`} />: Specifies the border of an
              element.
            </li>
            <li>
              <SingleCode codeLine={`border-radius`} />: Rounds the corners of
              an element's border.
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-3'>
          <span className='font-medium'>Positioning and Layout</span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`position`} />: Specifies the positioning
              method for an element (e.g., relative, absolute, fixed).
            </li>
            <li>
              <SingleCode codeLine={`display`} />: Determines how an element is
              displayed (e.g., block, inline, flex).
            </li>
            <li>
              <SingleCode codeLine={`float`} />: Positions an element to the
              left or right within its container.
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-3'>
          <span className='font-medium'>Background and Visuals</span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`background-color`} />: Sets the background
              color of an element.
            </li>
            <li>
              <SingleCode codeLine={`background-image`} />: Specifies an image
              to use as the background.
            </li>
            <li>
              <SingleCode codeLine={`padding`} />: Defines the space between the
              element's content and its border.
            </li>
            <li>
              <SingleCode codeLine={`opacity`} />: Controls the transparency of
              an element.
            </li>
            <li>
              <SingleCode codeLine={`box-shadow`} />: Creates a shadow effect
              around an element.
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-3'>
          <span className='font-medium'>Responsive Design</span>
          <ul className='list-disc px-5 space-y-1'>
            <li>
              <SingleCode codeLine={`@media`} />: Allows for responsive design
              by applying styles based on device characteristics (e.g., screen
              size).
            </li>
            <li>
              <SingleCode codeLine={`Link to be embedded`} />:
              <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/dqaNvIJ4Fyk?si=5ampXJCCbBrAurce'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen></iframe>
            </li>
          </ul>
        </div>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* Apply a color profile to the image */
.image-container img {
  color-profile: sRGB; /* Specifies the color space for the image */
  image-rendering: optimizeQuality; /* Controls the quality of image rendering */
  color-adjust: exact; /* Specifies color adjustments for the element */
}
/* Media query for different screens */
@media screen and (max-width: 768px) {
  /* Adjust colors for smaller screens */
  .image-container img {
    color-profile: display-P3; /* Different color profile for devices supporting wide gamut */
    image-rendering: optimizeSpeed; /* Adjust rendering for speed */
    color-adjust: economy; /* Optimize color for different screen characteristics */
  }
}
`}
      />
    </div>
  );
};

export default Properties;

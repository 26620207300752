import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const Cards = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'> Cards</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/NRoET8-8cbw?si=NVfbmGM6noF0H4-z'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Using Bootstrap Cards for Content Containers
        </span>
        <p>
          Cards are flexible containers that can hold a mix of content like
          images, text, links, and more. They are often used for things like
          blog posts, product showcases, and user profiles. Cards are visually
          appealing because they come with padding, shadows, and easy-to-use
          layouts.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Card Example'}
          codeStr={`<div class="card" style="width: 18rem;">
  <img src="image.jpg" class="card-img-top" alt="...">
  <div class="card-body">
    <h5 class="card-title">Card Title</h5>
    <p class="card-text">Some quick example text.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>card-img-top:</strong> Adds an image at the top of the card.
          </li>
          <li>
            <strong>card-body:</strong> Contains the text and any buttons or
            links.
          </li>
        </ul>
        <p>
          Cards are easy to use and help organize content in a clean, readable
          way.
        </p>
      </div>
    </div>
  );
};

export default Cards;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const TailwindFonts = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Fonts and Text</span>
      <div className='flex flex-col gap-2'>
        <span>
          Tailwind CSS provides a comprehensive set of utility classes for
          managing fonts and text styling. These classes allow you to easily
          control font size, weight, spacing, and other typographic properties
          without writing custom CSS.
        </span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/sOnBG2wUm1s?si=_LI9ezcrBb-5o2tH'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowFullScreen></iframe>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>1. Font Size</span>
          <span>
            Tailwind provides several classes for changing the size of your
            text. You can use text-{'{size}'} to control the font size, where{' '}
            {'{size}'} could be values like sm, base, lg, xl, and others.
          </span>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Font Size Example'}
            codeStr={`<p class="text-lg">This is a large paragraph!</p>`}
          />
          <p>
            In this example, text-lg applies a larger font size to the
            paragraph.
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>2. Font Weight</span>
          <span>
            To change the weight of your text, use font-{'{weight}'}. Tailwind
            offers a range of font weights, from thin (font-thin) to extra-bold
            (font-extrabold).
          </span>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Font Weight Example'}
            codeStr={`<p class="font-bold">This is bold text!</p>`}
          />
          <p>Here, font-bold makes the text bold.</p>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>
            3. Combining Font Size and Weight
          </span>
          <span>
            You can easily combine these two classes to make text both large and
            bold.
          </span>
          <span>Example:</span>
          <CodeDisplay
            title={'Tailwind Combined Font Size and Weight Example'}
            codeStr={`<p class="text-lg font-bold">This is a big and bold paragraph!</p>`}
          />
          <p>
            In this case, text-lg increases the font size, and font-bold adds
            bold styling.
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>
            4. Additional Typography Options
          </span>
          <span>
            Tailwind also allows for fine-tuning other typography aspects such
            as:
          </span>
          <ul className='list-disc pl-5'>
            <li>
              Letter Spacing: tracking-{'{size}'} (e.g., tracking-wide for wider
              spacing)
            </li>
            <li>
              Line Height: leading-{'{size}'} (e.g., leading-loose for loose
              line spacing)
            </li>
            <li>
              Text Color: text-{'{color}'} (e.g., text-red-500 for red text)
            </li>
          </ul>
          <CodeDisplay
            title={'Tailwind Additional Typography Example'}
            codeStr={`<p class="tracking-wide leading-loose text-red-500">
This text has wide letter spacing, loose line height, and is red!
</p>`}
          />
        </div>
      </div>
    </div>
  );
};

export default TailwindFonts;

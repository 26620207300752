import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';


const Modals = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'> Modals</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/tt5uUMQgzl0?si=D9cKL-J7qAvX5Pus'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Creating Pop-up Dialogs with Bootstrap Modals
        </span>
        <p>
          Modals are like pop-up windows that appear on top of your webpage.
          They are great for displaying additional content like forms, alerts,
          or additional information without navigating away from the current
          page.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Modal Example'}
          codeStr={`<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  Open Modal
</button>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Modal Title</h5>
      </div>
      <div class="modal-body">
        Modal content goes here.
      </div>
    </div>
  </div>
</div>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>data-toggle="modal":</strong> This attribute is used to
            trigger the modal when the button is clicked.
          </li>
          <li>
            <strong>modal-body:</strong> Contains the content of the modal. It
            could be text, images, or even forms.
          </li>
        </ul>
        <p>
          Modals are a powerful way to enhance user interaction and display
          important information or forms without cluttering the main page.
        </p>
      </div>
    </div>
  );
};

export default Modals;

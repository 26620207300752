import React from 'react';
import { SingleCode, CodeDisplay } from '../../CodeDisplay';

const Borders = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>CSS Borders</span>
      <div className='flex flex-col gap-3'>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/2zcHiaHo4Jo'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
        <span>
          CSS border properties provide extensive control over the appearance of
          an element's border, enabling customization in style, width, and color
          to define its visual presentation.
        </span>
        <span>
          A wide array of border styles is at the designer's disposal, including
          options like dotted, dashed, solid, double, and more, offering diverse
          visual effects for borders.
        </span>
        <span>
          Among the border properties, `border-style` holds fundamental
          significance, as it serves as a foundational property upon which other
          border properties depend for their application and visibility.
        </span>

        <span>Key aspects of CSS border properties:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`border-style`} /> property: Dictates the
            style of the border, determining whether it appears as a solid line,
            dots, dashes, or other defined styles.
          </li>
          <li>
            <SingleCode codeLine={`border-width`} /> property: Controls the
            thickness or width of the border, specifying its visual prominence.
          </li>
          <li>
            <SingleCode codeLine={`border-color`} /> property: Specifies the
            color of the border, allowing for customization in hues or
            transparency.
          </li>
          <li>
            <SingleCode codeLine={`border`} /> shorthand property: Provides a
            concise way to set all border properties (style, width, color) in
            one declaration.
          </li>
        </ul>

        <span>Utilizing various border styles:</span>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <SingleCode codeLine={`dotted`} />: Creates a border with dots.
          </li>
          <li>
            <SingleCode codeLine={`dashed`} />: Generates a border with dashes.
          </li>
          <li>
            <SingleCode codeLine={`solid`} />: Produces a solid, continuous
            border.
          </li>
          <li>
            <SingleCode codeLine={`double`} />: Generates a border consisting of
            two solid lines.
          </li>
        </ul>
      </div>

      <CodeDisplay
        title={'Example'}
        codeStr={`/* Styling the box with border properties */
.box {
  width: 200px; /* Width of the box */
  height: 100px; /* Height of the box */
  padding: 20px; /* Padding inside the box */
  /* Border properties */
  border-style: dotted; /* Style of the border */
  border-width: 3px; /* Width of the border */
  border-color: #f00; /* Color of the border */
}
`}
      />
    </div>
  );
};

export default Borders;

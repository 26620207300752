import React, { useState, useEffect } from 'react';

import { useGetUserQuery } from '../profile/userSlice';
import { FaAngleRight } from 'react-icons/fa';
import { LiaCoffeeSolid } from 'react-icons/lia';
import { GrClose } from 'react-icons/gr';
import { format, addHours } from 'date-fns';
import Loading from '../layout/Loading';

import RegisterEventPopup from './RegisterEvent';
import { partnerId } from '../../config/PartnerIds';
// const obj = {
//   user_id: '',
//   feedback: {
//     topics: [''],
//     doubtQues: '',
//   },
// };

const Index = () => {
  const [events, setEvents] = useState([]);
  const [activeSessIdx, setActiveSessIdx] = useState(-1);

  const [registerSessTitle, setRegisterSessTitle] = useState('Session');
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  // const [currentTime, setCurrentTime] = useState(new Date()); // State to hold current time

  // Calculate time 24 hours from now
  const twentyFourHoursFromNow = addHours(new Date(), 24);

  // useEffect to update current time every minute
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 60000); // Update current time every minute
  //   return () => clearInterval(timer); // Clean up interval
  // }, []);

  // Function to check if session is within 24 hours from current time
  // sessionDateTime ko maine as a placeholder use kra tha yaha for date_time
  const isWithin24Hours = (sessionDateTime) => {
    if (sessionDateTime === null) {
      return false;
    }
    return new Date(sessionDateTime) < twentyFourHoursFromNow;
  };

  const { data: userData } = useGetUserQuery();
  const user_id = userData?._id;

  // let date = new Date(2024, 7, 2, 16, 30);
  // let isoString = date.toISOString(); // This will be in UTC

  useEffect(() => {
    const tempEventData = [
      //   {
      //     coach_id: null,
      //     date_time: null,
      //     description:
      //       "Join us in our initial session to kickstart your journey with Belong and we'll get you started on the program, and guide you through navigating the Dashboard. Let's embark on this learning adventure together!",
      //     id: 0,
      //     link: null,
      //     registered: false,
      //     title: 'How to get started?',
      //     type: 'Belong Team',
      //     users_registered: [],
      //   },
      //   {
      //     coach_id: null,
      //     date_time: null,
      //     description:
      //       "It's time to share your achievements on LinkedIn and reflect on the incredible journey you've been on with Belong. Join us for a special graduation where we'll reminisce about the highlights and your experience with us. Let's make this moment unforgettable!",
      //     id: 0,
      //     link: null,
      //     registered: false,
      //     title: 'Showcase your work and graduate',
      //     type: 'Belong Team',
      //     users_registered: [],
      //   },
      {
        coach_id: null,
        date_time: new Date('2024-10-16T12:30:00.000Z'),
        time: new Date('2024-10-16T12:30:00.000Z'),
        id: 2,
        link: '#',
        registered: false,
        title: 'Orientation',
        type: 'Belong Team',
        description: 'Welcome to the orientation.',
        users_registered: [],
      },
      {
        coach_id: null,
        date_time: new Date('2024-10-22T12:30:00.000Z'),
        time: new Date('2024-10-22T12:30:00.000Z'),

        id: 3,
        link: 'https://us02web.zoom.us/j/4243272773?pwd=cFBTbVJzclhoYkRaVUxWOXp6aUVGQT09',
        registered: false,
        title: 'Check-In 1',
        type: 'Coach',
        description: 'First Check-In.',
        users_registered: [],
      },
      {
        coach_id: null,
        date_time: null,
        // time key is new by AMAN, to show TBA in the UI
        time: null,
        id: 4,
        link: '#',
        registered: false,
        title:
          'Expert Session: Exploring the industry as a front-end developer',
        type: 'Expert',
        description: 'Get talking to the experts in the industry.',
        users_registered: [],
      },
      {
        coach_id: null,
        date_time: new Date('2024-11-04T11:00:00.000Z'),
        // time key is new by AMAN, to show TBA in the UI
        time: null,
        id: 5,
        link: '#',
        registered: false,
        title: 'Check-In 2',
        type: 'Coach',
        description: 'Second Check-In.',
        users_registered: [],
      },
      {
        coach_id: null,
        date_time: new Date('2024-11-08T11:00:00.000Z'),
        // time key is new by AMAN, to show TBA in the UI
        time: null,
        id: 6,
        link: '#',
        registered: false,
        title: 'Graduation',
        type: 'Belong Team',
        description: 'Graduation!',
        users_registered: [],
      },
    ];
    // Check if user has registered.
    // Update event.registered to true if they have
    setEvents(
      tempEventData.map((sess) => {
        sess.registered = sess.users_registered
          .map((user_obj) => user_obj.user_id)
          .includes(user_id);
        return sess;
      })
    );
    // fetchEvents()
    //   .then((data) => {
    //     // Adding an extra registered field to avoid refetching
    //     // If the user has registered it locally updates the state and does not refetch.
    //     const currDate = new Date();
    //     setEvents(
    //         data.filter((sess) => {
    //             const sessDate = new Date(sess.date_time);
    //             return sessDate >= currDate
    //         }).map((sess) => {
    //         sess.registered = sess.users_registered
    //           .map((user_obj) => user_obj.user_id)
    //           .includes(user_id);
    //         return sess;
    //       })
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [user_id]);

  //   const eventId = events[activeSessIdx].id;

  return (
    <div id='livesessions' className='flex  flex-col'>
      {events.length === 0 ? (
        <Loading />
      ) : (
        <React.Fragment>
          <RegisterEventPopup
            setModalOpen={setPopupIsOpen}
            return
            true
            events={events}
            activeSessIdx={activeSessIdx}
            setEvents={setEvents}
            modalOpen={popupIsOpen}
            registerSessTitle={registerSessTitle}
          />
          <div className='flex flex-row justify-between px-4 sm:px-6 lg:px-8 border-b-2 border-gray-200 py-6'>
            <span className='text-2xl font-semibold'>Live Sessions</span>
            <button className='bg-cyan-50 bg-opacity-50 border-2 rounded-lg border-primary py-2 px-5 font-semibold'>
              <a href='mailto:connect@belong.education;'>
                <span>Have a question? Write to us </span>
              </a>{' '}
            </button>
          </div>
          {userData?.partnerId?._id === partnerId.innovationMissionPunjab ||
          true ? (
            <div className='flex flex-col sm:flex-row'>
              <div className='p-6 sm:w-1/2'>
                <div className='flex flex-col gap-3'>
                  {events.map((sess, idx) => (
                    <div
                      key={idx}
                      onClick={() => {
                        setRegisterSessTitle(sess.title);
                        if (activeSessIdx === idx) {
                          setActiveSessIdx(-1);
                          return;
                        }
                        setActiveSessIdx(idx);
                      }}
                      className={`flex flex-row justify-between gap-3 p-5 border-2 rounded-lg cursor-pointer select-none ${
                        activeSessIdx === idx
                          ? ' border-primary '
                          : ' border-gray-200'
                      }`}>
                      <span className='font-semibold text-base md:text-lg'>
                        {sess.title}
                      </span>
                      <div className='flex flex-row gap-4 justify-center items-center'>
                        <span className='px-4 py-2 text-sm font-medium bg-stone-50 rounded-md'>
                          {sess.type}
                        </span>
                        {activeSessIdx === idx ? (
                          <GrClose className='text-primary font-semibold' />
                        ) : (
                          <FaAngleRight />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='p-6 sm:w-1/2'>
                {activeSessIdx === -1 ? (
                  <div className='flex flex-col justify-center items-center h-full gap-2'>
                    <LiaCoffeeSolid className='text-5xl' />
                    <span className='font-medium'>
                      Please select a session to view more details.
                    </span>
                  </div>
                ) : (
                  <div className='flex flex-col gap-5'>
                    <div className='flex flex-col gap-8'>
                      <div className='flex flex-col gap-2'>
                        <span className='font-semibold text-xl'>
                          {events[activeSessIdx].title}
                        </span>
                        <span className='font-medium text-homeText-lighter'>
                          {events[activeSessIdx].description}
                        </span>
                      </div>
                      <div className='flex flex-col gap-6'>
                        <div className='flex flex-col gap-1'>
                          <span className='text-sm text-homeText-lighter'>
                            Date
                          </span>
                          <span className='text-homeText-darker font-semibold'>
                            {events[activeSessIdx].date_time !== null
                              ? format(
                                  new Date(events[activeSessIdx].date_time),
                                  'do MMMM y'
                                )
                              : 'To be announced'}
                          </span>
                        </div>
                        <div className='flex flex-col gap-1'>
                          <span className='text-sm text-homeText-lighter'>
                            Time
                          </span>
                          <span className='text-homeText-darker font-semibold'>
                            {/* {events[activeSessIdx].date_time !== null
                              ? format(
                                  new Date(events[activeSessIdx].date_time),
                                  'h:mm a'
                                )
                              : '-'} */}
                            {events[activeSessIdx].time !== null
                              ? format(
                                  new Date(events[activeSessIdx].time),
                                  'h:mm a'
                                )
                              : 'To be announced'}
                          </span>
                        </div>
                        {events[activeSessIdx].coach_id === null ? (
                          ''
                        ) : (
                          <div className='flex flex-col gap-3 border-2 border-gray-200 rounded-lg p-5'>
                            <div className=''>
                              <img
                                className='h-24 w-auto rounded-full'
                                src={events[activeSessIdx].coach_id?.image}
                                alt='Speaker'
                              />
                            </div>
                            <div className='flex flex-col gap-0'>
                              <div className='flex justify-between'>
                                <span className='font-bold text-lg'>
                                  {events[activeSessIdx].coach_id?.name}
                                </span>
                                <a
                                  href={
                                    events[activeSessIdx].coach_id?.linkedin
                                  }
                                  target='_blank'
                                  rel='noreferrer'>
                                  <img
                                    className='w-8'
                                    src='https://res.cloudinary.com/belong/image/upload/v1710495160/fsd-landing/home/svg-icons/ysmgfvjczgoovfxdscfh.svg'
                                    alt='Linkedin'
                                  />
                                </a>
                              </div>
                              <span className='font-medium text-homeText-lighter'>
                                {events[activeSessIdx].coach_id?.designation}
                              </span>
                              <div className='flex flex-wrap gap-1 py-1'>
                                {events[activeSessIdx].coach_id?.domains.map(
                                  (domain, idx) => (
                                    <span
                                      key={idx}
                                      className='px-4 py-2 text-sm text-center text-homeText-darker bg-gray-100 rounded-3xl'>
                                      {domain}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                            <span className='font-medium text-sm text-homeText-lighter'>
                              {events[activeSessIdx].coach_id?.description}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        //   setPopupIsOpen(true);
                      }}
                      className={`bg-primary text-white rounded-lg w-full ${
                        // Disable the button if session is within 24 hours from current time
                        isWithin24Hours(events[activeSessIdx].date_time)
                          ? ''
                          : 'pointer-events-none opacity-50'
                      }`}
                      // disabled={isWithin24Hours(events[activeSessIdx].date_time)}
                      // Disable the button if session is within 24 hours from current time
                      // className={`bg-primary px-5 py-2 text-white rounded-lg w-full ${events[activeSessIdx].type === 'Belong Team' ? ' hidden pointer-events-none ' : ''} ${events[activeSessIdx].users_registered.map((data) => data.user_id).includes(user_id) || events[activeSessIdx].registered ? ' bg-stone-500 pointer-events-none ' : ''}`}
                    >
                      <a
                        href={events[activeSessIdx].link}
                        rel='noreferrer'
                        target='_blank'
                        className='px-5 py-2 rounded-lg w-full h-full flex justify-center items-center'>
                        Join Now
                      </a>
                      {/* {events[activeSessIdx].date_time === null
                      ? 'To be announced'
                      : events[activeSessIdx].users_registered
                            .map((data) => data.user_id)
                            .includes(user_id) ||
                          events[activeSessIdx].registered
                        ? 'Already registered'
                        : 'Register for Session'} */}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Index;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const Button = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Buttons</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/ZZXGmoQ4PdI?si=jB6XM8ansHo273pD'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>How to Use Bootstrap Buttons</span>
        <span>
          Buttons are important for user interaction, and Bootstrap provides
          various styles like primary, secondary, success, and more. These are
          ready-made styles that change the color, size, and behavior of the
          button.
        </span>
        <CodeDisplay
          title={'Bootstrap Button Example'}
          codeStr={`<button class="btn btn-primary">Primary Button</button>
<button class="btn btn-secondary">Secondary Button</button>`}
        />
        <span>Each class changes the button's appearance:</span>
        <ul className='list-disc list-inside'>
          <li>
            <code>.btn-primary</code>: Gives a blue button.
          </li>
          <li>
            <code>.btn-secondary</code>: Gives a grey button.
          </li>
        </ul>
        <span>
          You can also customize the size of the button using classes like{' '}
          <code>.btn-lg</code> for large buttons and <code>.btn-sm</code> for
          small ones.
        </span>
      </div>
    </div>
  );
};

export default Button;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const ControlFlow = () => {
  return (
    <div className='flex flex-col gap-3'>
      <span className='font-semibold text-lg'>Control Flow</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/erB8wqpyFck?si=YUi701rYMqcWGCuA'
        title='JavaScript Control Flow Statements'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <p>
        The control flow dictates the sequence in which a computer processes
        statements within a script. Code is executed sequentially from the
        initial line in the file to the final line, except when encountering
        common structures, such as conditionals and loops, which can alter the
        flow of control.
      </p>
      <ul className='list-disc px-5 space-y-1'>
        <li>
          Employ "if" to define a code block for execution when a specified
          condition is true.
        </li>
        <li>
          Utilize "else" to designate a code block for execution when the same
          condition is false.
        </li>
        <li>
          Use "else if" to introduce a new condition for testing if the initial
          condition is false.
        </li>
        <li>
          Loops are useful when we need to repeatedly execute the same code,
          each time with a different value.
        </li>
        <li>
          The "for" loop iterates through a block of code a specified number of
          times.
        </li>
        <li>The "for/in" loop traverses the properties of an object.</li>
        <li>
          The "for/of" loop iterates through the values of an iterable object.
        </li>
        <li>
          The "while" loop executes a block of code as long as a specified
          condition is true.
        </li>
        <li>
          The "do/while" loop similarly runs through a block of code while a
          specified condition is true.
        </li>
      </ul>
      <h3 className='font-semibold'>
        Introduction to Control Flow in JavaScript:
      </h3>
      <p>
        Control flow refers to the order in which statements are executed in a
        program. Conditional statements and loops are essential components of
        control flow, allowing you to make decisions and repeat tasks based on
        certain conditions.
      </p>
      <h3 className='font-semibold'>
        Conditional Statements (if, else if, else):
      </h3>
      <p>
        Conditional statements allow you to execute different blocks of code
        based on whether a condition is true or false.
      </p>
      <p>Example:</p>
      <CodeDisplay
        title=''
        codeStr={`let num = 10;
if (num > 0) {
console.log('Positive number');
} else if (num < 0) {
console.log('Negative number');
} else {
console.log('Zero');
}`}
      />
      <p>
        In this example, if num is greater than 0, it prints 'Positive number';
        if num is less than 0, it prints 'Negative number'; otherwise, it prints
        'Zero'.
      </p>
      <h3 className='font-semibold'>Loops (for, while, do-while):</h3>
      <p>
        Loops allow you to repeat a block of code multiple times until a
        condition is met or for a specified number of iterations.
      </p>
      <h4 className='font-medium'>For Loop:</h4>
      <CodeDisplay
        title=''
        codeStr={`for (let i = 0; i < 5; i++) {
console.log(i);
}`}
      />
      <p>This loop iterates from 0 to 4, printing the value of i each time.</p>
      <h4 className='font-medium'>While Loop:</h4>
      <CodeDisplay
        title=''
        codeStr={`let i = 0;
while (i < 5) {
console.log(i);
i++;
}`}
      />
      <p>
        This loop also iterates from 0 to 4, but the condition is checked before
        each iteration.
      </p>
      <h4 className='font-medium'>Do-While Loop:</h4>
      <CodeDisplay
        title=''
        codeStr={`let i = 0;
do {
console.log(i);
i++;
} while (i < 5);`}
      />
      <p>
        This loop is similar to a while loop, but it always executes the block
        of code at least once before checking the condition.
      </p>
      <h3 className='font-semibold'>Nested Loops:</h3>
      <p>
        You can also have loops inside other loops, known as nested loops, to
        perform more complex tasks.
      </p>
      <p>Example:</p>
      <CodeDisplay
        title=''
        codeStr={`for (let i = 0; i < 3; i++) {
for (let j = 0; j < 3; j++) {
console.log(i, j);
}
}`}
      />
      <p>
        This nested loop prints pairs of numbers ranging from 0 to 2,
        representing all possible combinations.
      </p>
      <h3 className='font-semibold'>
        Control Flow with Conditional Statements and Loops:
      </h3>
      <p>
        You can combine conditional statements with loops to create more dynamic
        and flexible programs.
      </p>
      <p>Example:</p>
      <CodeDisplay
        title=''
        codeStr={`for (let i = 0; i < 5; i++) {
if (i % 2 === 0) {
console.log(i + ' is even');
} else {
console.log(i + ' is odd');
}
}`}
      />
      <p>
        This loop iterates from 0 to 4 and checks if each number is even or odd
        before printing the result.
      </p>
    </div>
  );
};

export default ControlFlow;

import { FaCheck } from 'react-icons/fa';
import { RiBookmark3Line } from 'react-icons/ri';
import React from 'react';

const TopicRow = ({
  idx,
  onClick,
  isChecked,
  isSelected,
  title,
  prevChecked,
  exercise_id,
  exercise_task,
  setExerciseOpen,
  exerciseIsOpen,
  setCurrExerciseId,
  currExerciseId,
}) => {
  const exerciseInfo = [];
  for (let i = 0; i < exercise_id.length; i++) {
    exerciseInfo.push({
      ex_id: exercise_id[i],
      ex_task: exercise_task[i],
    });
  }

  return (
    <div className='flex flex-col'>
      <div
        onClick={() => {
          if (!isSelected) {
            onClick();
            return;
          }

          setExerciseOpen(false);
        }}
        className={`flex flex-row justify-between items-center cursor-pointer gap-5 px-5 py-4 ${
          (isSelected ? ' bg-cyan-50' : ' bg-white',
          !prevChecked
            ? ' pointer-events-none opacity-50 '
            : ' pointer-events-auto opacity-100 ')
        }`}>
        <span className={`${isSelected ? 'font-semibold ' : ''}`}>
          {idx + 1}. {title}
        </span>
        <div className='h-fit w-fit'>
          {isChecked ? (
            <FaCheck className='bg-primary text-white text-xl p-[3px] rounded-3xl' />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='flex flex-col'>
        {isSelected &&
          exerciseInfo.map((ex, idx) => (
            <div
              className={`flex flex-row justify-between items-center cursor-pointer gap-5 px-5 py-4`}
              onClick={() => {
                setCurrExerciseId(idx);
                setExerciseOpen(true);
              }}>
              <span
                className={`pl-2 text-black ${exerciseIsOpen && currExerciseId === idx && ' font-medium '}`}>
                {ex.ex_task}
              </span>
              <RiBookmark3Line className='text-stone-500 text-xl' />
            </div>
          ))}
      </div>
    </div>
  );
};

export default TopicRow;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const TailwindBorders = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Borders</span>
      <div className='flex flex-col gap-2'>
        <span>
          Borders are an essential aspect of web design, helping to visually
          separate and define elements on a page. Tailwind CSS makes it simple
          to apply borders with its utility classes. Here's a detailed breakdown
          of how to use border utilities in Tailwind:
        </span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/1g4W2U-l350?si=P9WQKCJqnIH62tYS'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowFullScreen></iframe>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>1. Border Width</span>
          <span>
            You can control the thickness of borders using classes that specify
            the border width.
          </span>
          <span>Key Border Width Classes:</span>
          <ul className='list-disc pl-5'>
            <li>border: Applies a default border width of 1px.</li>
            <li>
              border-{'{size}'}: Allows you to set specific widths. Common sizes
              include:
            </li>
            <li>border-0: No border.</li>
            <li>border-2: 2px border.</li>
            <li>border-4: 4px border.</li>
            <li>border-8: 8px border.</li>
          </ul>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>2. Border Color</span>
          <span>
            Tailwind provides a wide range of colors that you can apply to
            borders. You can easily customize the border color using utility
            classes.
          </span>
          <span>Key Border Color Classes:</span>
          <ul className='list-disc pl-5'>
            <li>
              border-{'{color}'}: Sets the border color. Examples include:
            </li>
            <li>
              border-red-500: Sets the border color to a specific shade of red.
            </li>
            <li>border-blue-200: Sets the border color to a light blue.</li>
            <li>border-green-400: Sets the border color to a medium green.</li>
          </ul>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>3. Border Style</span>
          <span>
            While Tailwind does not provide direct utility classes for border
            styles (like solid, dashed, or dotted), you can use custom CSS if
            needed. By default, borders are solid.
          </span>
          <span>Example:</span>
          <span>
            Here's how to put together border width and color in a simple div:
          </span>
          <CodeDisplay
            title={'Tailwind Border Example'}
            codeStr={`<div class="border-2 border-green-500 p-4">
This box has a green border!
</div>`}
          />
          <p>In this example:</p>
          <ul className='list-disc pl-5'>
            <li>border-2: Applies a 2px border to the div.</li>
            <li>
              border-green-500: Sets the border color to green (specifically a
              medium shade).
            </li>
            <li>
              p-4: Adds padding of 1rem (16px) inside the box, ensuring the
              content is not touching the border.
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>Additional Border Utilities</span>
          <span>
            Tailwind CSS also offers additional utilities that enhance border
            styling, including:
          </span>
          <ul className='list-disc pl-5'>
            <li>
              Rounded Borders: Use rounded classes to create rounded corners.
            </li>
            <li>rounded: Applies a default border-radius.</li>
            <li>
              rounded-lg: Applies a larger border-radius for more pronounced
              curves.
            </li>
            <li>
              Border Collapse: For tables, you can use border-collapse and
              border-separate to control how borders are rendered.
            </li>
          </ul>
          <span>Example with Rounded Borders:</span>
          <CodeDisplay
            title={'Tailwind Rounded Border Example'}
            codeStr={`<div class="border-2 border-blue-500 rounded-lg p-4">
This box has a blue border with rounded corners!
</div>`}
          />
          <iframe
            className='md:w-[560px] md:h-[315px] w-[100%]'
            src='https://www.youtube.com/embed/6iW2hYVserY?si=g5oNn0HzNu-Dy5Gy'
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowFullScreen></iframe>
        </div>
      </div>
    </div>
  );
};

export default TailwindBorders;

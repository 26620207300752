import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const DomManipulation = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>DOM</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/NO5kUNxGIu0?si=z0H7P32SUXcOsTux'
        title='JavaScript DOM'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <p>
        The Document Object Model (DOM) is a programming interface for web
        documents. It represents the structure of a document as a tree of
        objects, where each object corresponds to a part of the document, such
        as elements, attributes, and text. JavaScript can be used to manipulate
        the DOM, allowing you to dynamically update and modify the content,
        structure, and style of a web page.
      </p>

      <div className='flex flex-col gap-3'>
        <span className='font-semibold text-lg'>DOM Manipulation</span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/2IPEp_4obGw?si=c5WJhceuxOVst9wP'
          title='JavaScript DOM Manipulation'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen></iframe>
        <ul className='list-disc px-5 space-y-1'>
          <li>
            JavaScript, through the HTML DOM (Document Object Model), possesses
            the capability to access and modify all elements within an HTML
            document.
          </li>
          <li>
            When a web page loads, the browser constructs a Document Object
            Model representing the page. This object model grants JavaScript the
            authority to dynamically manipulate HTML elements, attributes, and
            styles, as well as respond to and create HTML events.
          </li>
          <li>
            In essence, the HTML DOM establishes a standard for obtaining,
            altering, adding, or deleting HTML elements, constituting the DOM
            Programming Interface.
          </li>
          <li>
            In this interface, HTML elements are defined as objects, and their
            properties and methods enable programming interactions.
          </li>
          <li>
            A property is a value that you can get or set (like changing the
            content of an HTML element).
          </li>
          <li>
            A method is an action you can do (like adding or deleting an HTML
            element).
          </li>
          <li>
            The most straightforward method to alter the content of an HTML
            element involves utilizing the innerHTML property.
          </li>
          <li>
            To update the content of an HTML element, employ the following
            syntax:{' '}
            <CodeDisplay
              title={``}
              codeStr={`document.getElementById(id).innerHTML = newHTML;`}
            />
          </li>
          <li>
            To modify the value of an HTML attribute, employ the following
            syntax:{' '}
            <CodeDisplay
              title={``}
              codeStr={`document.getElementById(id).attribute = newValue;`}
            />
          </li>
          <li>
            In JavaScript, document.write() can be utilized to directly write to
            the HTML output stream.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DomManipulation;

import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const CollapsiblePanels = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'> Collapsible Panels</span>
      <iframe
        className='md:w-[560px] md:h-[315px] w-[100%]'
        src='https://www.youtube.com/embed/dZ7Pxh-0h-Q?si=jGhfy0Ep70nAsNcL'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen></iframe>
      <div className='flex flex-col gap-2'>
        <span className='font-semibold'>
          Creating Expandable Panels with Bootstrap's Collapse Component
        </span>
        <p>
          Collapsible panels allow you to hide or show content when a user
          clicks a button. This is useful for FAQs, hidden sections, or showing
          additional details without overwhelming the user with too much
          information at once.
        </p>
        <span>Example:</span>
        <CodeDisplay
          title={'Bootstrap Collapsible Panel Example'}
          codeStr={`<button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample">
Toggle Content
</button>
<div class="collapse" id="collapseExample">
<div class="card card-body">
This content is collapsible.
</div>
</div>`}
        />
        <ul className='list-disc px-5 space-y-1'>
          <li>
            <strong>collapse:</strong> This class hides or shows the content
            when triggered by the button.
          </li>
          <li>
            <strong>data-target:</strong> Specifies which element should
            collapse or expand when the button is clicked.
          </li>
        </ul>
        <p>
          Collapsible panels are an effective way to organize content and
          improve user experience by allowing users to control what information
          they see.
        </p>
      </div>
    </div>
  );
};

export default CollapsiblePanels;

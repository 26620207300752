import React, { useEffect, useState } from 'react';
import HtmlTutorial from './html';
import CssTutorial from './css';
import { FaAngleUp } from 'react-icons/fa';
import TopicRow from '../TopicRow';

import { milestonesData } from '../../../../config/JourneyConfig';
import { htmlTopics, cssTopics } from '../topics';

// import Challenge from './challenge';
const Day1 = ({ enrollData, setReFetch, nextMilestone }) => {
  const [tutorial, setTutorial] = useState('html');
  const [topic, setTopic] = useState('introduction');
  const [prevTutorialTopic, setPrevTutorialTopic] = useState('introduction');
  const [exerciseOpen, setExerciseOpen] = useState(false);
  const [htmlOpen, setHtmlOpen] = useState(true);
  const [cssOpen, setCssOpen] = useState(false);
  const [showIdealSolution, setShowIdealSolution] = useState(false);
  const [currExerciseIdx, setCurrExerciseIdx] = useState(0);

  const htmlData = enrollData?.milestone_1?.html || [];
  const cssData = enrollData?.milestone_1?.css || [];

  const htmltasks = enrollData?.milestone_1?.html || [];
  //   const csstasks = enrollData?.milestone_1?.css || [];

  const isHtmlDone = htmltasks.length >= milestonesData.html;
  //   const isCssDone = csstasks.length >= milestonesData.css;

  // Reset the scrollbar of the main content after change in topic/tutorial
  useEffect(() => {
    document.getElementById('main-content').scrollTop = 0;
  }, [topic, tutorial]);

  return (
    <div className='flex flex-col md:flex-row h-[80vh] items-center md:items-stretch'>
      {/* <TimeTracker milestone_id='milestone_1' userId={enrollData?.user_id} /> */}
      <div
        id='topic-sidebar'
        className='w-full h-full md:w-1/4 min-w-64 p-6 md:border-r-2 md:border-b-2 border-gray-200 md:overflow-y-auto md:h-[80vh]'>
        <div className='flex flex-col gap-4 overflow-auto md:sticky top-0 select-none'>
          <div className='border-2 rounded-t-lg border-gray-200'>
            <div
              onClick={() => {
                if (tutorial === 'html') {
                  setHtmlOpen((pre) => !pre);
                } else {
                  if (cssOpen) {
                    setCssOpen(false);
                  }
                  setTutorial('html');
                  const temp = topic;
                  setTopic(prevTutorialTopic);
                  setPrevTutorialTopic(temp);
                  setHtmlOpen(true);
                }
              }}
              className={`w-full flex flex-row justify-between items-center cursor-pointer gap-5 rounded-t-md px-5 py-4 ${tutorial === 'html' ? 'bg-stone-100' : 'bg-white'}`}>
              <span className='font-semibold'>HTML</span>
              <FaAngleUp
                className={`text-2xl duration-300 ${
                  htmlOpen ? ' rotate-0 ' : 'rotate-180'
                }`}
              />
            </div>
            {htmlOpen
              ? htmlTopics.map((data, idx) => (
                  <TopicRow
                    key={idx}
                    idx={idx}
                    prevChecked={
                      idx === 0
                        ? true
                        : htmlData.includes(htmlTopics[idx - 1].id)
                    }
                    isChecked={htmlData.includes(data.id)}
                    onClick={() => {
                      setTopic(data.id);
                      setExerciseOpen(false);
                    }}
                    isSelected={topic === data.id && tutorial === 'html'}
                    title={data.title}
                    exercise_id={data.exercise_id}
                    exercise_task={data.exercise_task}
                    setExerciseOpen={setExerciseOpen}
                    currExerciseId={currExerciseIdx}
                    setCurrExerciseId={setCurrExerciseIdx}
                    exerciseIsOpen={exerciseOpen}
                  />
                ))
              : ''}
          </div>
          <div
            className={`border-2 rounded-t-lg border-gray-200 ${!isHtmlDone ? ' pointer-events-none opacity-50 ' : ' pointer-events-auto opacity-100 '}`}>
            <div
              onClick={() => {
                if (tutorial === 'css') {
                  setCssOpen((pre) => !pre);
                } else {
                  if (htmlOpen) {
                    setHtmlOpen(false);
                  }
                  setTutorial('css');
                  const temp = topic;
                  setTopic(prevTutorialTopic);
                  setPrevTutorialTopic(temp);
                  setCssOpen(true);
                  setExerciseOpen(false);
                }
              }}
              className={`w-full flex flex-row justify-between items-center cursor-pointer gap-5 rounded-t-md px-5 py-4 ${tutorial === 'css' ? 'bg-stone-100' : 'bg-white'}`}>
              <span className='font-semibold'>CSS</span>
              <FaAngleUp
                className={`text-2xl duration-300 ${
                  cssOpen ? ' rotate-0 ' : 'rotate-180'
                }`}
              />
            </div>
            {cssOpen
              ? cssTopics.map((data, idx) => (
                  <TopicRow
                    key={idx}
                    idx={idx}
                    prevChecked={
                      idx === 0
                        ? htmlData.includes(
                            htmlTopics[htmlTopics.length - 1].id
                          )
                        : cssData.includes(cssTopics[idx - 1].id)
                    }
                    isChecked={cssData.includes(data.id)}
                    onClick={() => {
                      setExerciseOpen(false);
                      setTopic(data.id);
                    }}
                    isSelected={topic === data.id && tutorial === 'css'}
                    title={data.title}
                    exercise_id={data.exercise_id}
                    exercise_task={data.exercise_task}
                    setExerciseOpen={setExerciseOpen}
                    currExerciseId={currExerciseIdx}
                    setCurrExerciseId={setCurrExerciseIdx}
                    exerciseIsOpen={exerciseOpen}
                  />
                ))
              : ''}
          </div>
        </div>
      </div>
      <div
        id='main-content'
        className='px-4 h-full md:px-8 py-4 md:py-6 md:w-3/4 border-b-0 md:border-b-2 border-gray-200 md:overflow-y-auto md:h-[80vh]'>
        {tutorial === 'html' && (
          <HtmlTutorial
            setTutorialCSS={() => {
              setTopic('introduction');
              setTutorial('css');
              setPrevTutorialTopic('lists');
              setCssOpen(true);
              setHtmlOpen(false);

              document.getElementById('topic-sidebar').scrollTop = 0;
            }}
            setTopic={setTopic}
            topic={topic}
            enrollData={enrollData}
            setReFetch={setReFetch}
            isExerciseOpen={exerciseOpen}
            setExerciseOpen={setExerciseOpen}
            showIdealSolution={showIdealSolution}
            setShowIdealSolution={setShowIdealSolution}
            currExerciseIdx={currExerciseIdx}
            setCurrExerciseIdx={setCurrExerciseIdx}
          />
        )}
        {tutorial === 'css' && (
          <CssTutorial
            nextMilestone={nextMilestone}
            setTopic={setTopic}
            topic={topic}
            enrollData={enrollData}
            setReFetch={setReFetch}
            isExerciseOpen={exerciseOpen}
            setExerciseOpen={setExerciseOpen}
            showIdealSolution={showIdealSolution}
            setShowIdealSolution={setShowIdealSolution}
            currExerciseIdx={currExerciseIdx}
            setCurrExerciseIdx={setCurrExerciseIdx}
          />
        )}
      </div>
    </div>
  );
};

export default Day1;

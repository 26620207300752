import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const IncludingBootstrap = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>
        Including Bootstrap in Your Project
      </span>
      <div className='flex flex-col gap-1'>
        <span className='font-semibold'>
          How to Use Bootstrap in Your Website?
        </span>
        <span>
          To use Bootstrap, you can either download it and include it in your
          project or use a quicker method called CDN (Content Delivery Network).
          The CDN method is often easier for beginners because you simply link
          to the Bootstrap files online instead of downloading them.
        </span>
        <span>Here's how you can include Bootstrap with a CDN:</span>
        <CodeDisplay
          title={'Include Bootstrap CSS'}
          codeStr={`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">`}
        />
        <span>
          This line goes inside the &lt;head&gt; section of your HTML file, and
          it will load Bootstrap's styling for your project.
        </span>
      </div>
    </div>
  );
};

export default IncludingBootstrap;

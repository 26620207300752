import React from 'react';
import { CodeDisplay } from '../../CodeDisplay';

const TailwindTransitions = () => {
  return (
    <div className='flex flex-col gap-5'>
      <span className='font-semibold text-lg'>Transitions</span>
      <div className='flex flex-col gap-2'>
        <span>
          Tailwind provides simple utilities to add smooth transitions and
          animations to your elements. These transitions help enhance the user
          experience by animating properties like background color, scale,
          opacity, etc., when elements change state, such as on hover or focus.
        </span>
        <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src='https://www.youtube.com/embed/0zA6GZa2ZKc?si=AbXeQ0u5Yx8EN9mW'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowFullScreen></iframe>

        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>
            1. Basic Transitions in Tailwind
          </span>
          <span>
            Tailwind offers the transition class, which automatically applies
            smooth transitions to elements. By default, it applies a smooth
            animation to the most common properties like background color,
            opacity, and transform.
          </span>
          <span>Example of a Basic Transition:</span>
          <CodeDisplay
            title={'Basic Transition Example'}
            codeStr={`<div class="bg-blue-500 p-4 hover:bg-blue-700 transition">
Hover to see a smooth background color change!
</div>`}
          />
          <span>
            When you hover over the box, the background color will smoothly
            change from blue (bg-blue-500) to a darker blue (bg-blue-700).
          </span>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>2. Custom Transitions</span>
          <span>
            You can further customize transitions by specifying which property
            should transition and how long it should take. Tailwind provides
            utility classes for different transition properties.
          </span>
          <span>Transition Properties:</span>
          <ul className='list-disc pl-5'>
            <li>transition-all: Transitions all properties that change.</li>
            <li>
              transition-colors: Transitions only color-related properties (like
              background and text color).
            </li>
            <li>transition-opacity: Transitions the opacity of an element.</li>
            <li>
              transition-transform: Transitions transformations (like scale,
              rotate, and translate).
            </li>
          </ul>
          <span>Example of a Custom Transition:</span>
          <CodeDisplay
            title={'Custom Transition Example'}
            codeStr={`<div class="bg-blue-500 p-4 hover:bg-blue-700 hover:scale-110 transition-all">
Hover to see color and scale change!
</div>`}
          />
          <span>
            This element smoothly transitions both the background color and
            scale when hovered. The box will grow in size due to the
            hover:scale-110 class.
          </span>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>3. Transition Duration</span>
          <span>
            You can control how long the transition takes by using the duration-
            {'{time}'} class. The time is defined in milliseconds (ms), where
            1000ms = 1 second.
          </span>
          <span>Example with Transition Duration:</span>
          <CodeDisplay
            title={'Transition Duration Example'}
            codeStr={`<div class="bg-blue-500 p-4 hover:bg-blue-700 transition-all duration-500">
Hover for a slower background color change!
</div>`}
          />
          <span>
            In this example, the background color transition will take 500ms
            (half a second).
          </span>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>4. Transition Timing Function</span>
          <span>
            Tailwind allows you to adjust the speed curve of the transition
            using the ease classes:
          </span>
          <ul className='list-disc pl-5'>
            <li>
              ease-linear: Applies a constant speed throughout the transition.
            </li>
            <li>ease-in: Starts slow, then speeds up.</li>
            <li>ease-out: Starts fast, then slows down.</li>
            <li>
              ease-in-out: Combines both, starting slow, speeding up in the
              middle, and slowing down at the end.
            </li>
          </ul>
          <span>Example with Timing Function:</span>
          <CodeDisplay
            title={'Transition Timing Function Example'}
            codeStr={`<div class="bg-blue-500 p-4 hover:bg-blue-700 transition-all duration-500 ease-in-out">
Hover for an ease-in-out transition!
</div>`}
          />
          <span>
            The color will gradually transition in and out, making the effect
            more natural.
          </span>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>5. Transition Delay</span>
          <span>
            To delay the start of the transition, you can use the delay-
            {'{time}'} class to specify how long to wait before the transition
            begins.
          </span>
          <span>Example with Transition Delay:</span>
          <CodeDisplay
            title={'Transition Delay Example'}
            codeStr={`<div class="bg-blue-500 p-4 hover:bg-blue-700 transition-all duration-500 delay-200">
Hover to see a delayed transition!
</div>`}
          />
          <span>
            The background color will wait 200ms before starting the transition
            when you hover.
          </span>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>6. Combining Transitions</span>
          <span>
            You can combine different transition properties like duration,
            delay, and easing for more complex effects.
          </span>
          <span>Example with Multiple Transition Properties:</span>
          <CodeDisplay
            title={'Combined Transition Example'}
            codeStr={`<div class="bg-blue-500 p-4 hover:bg-blue-700 hover:scale-110 transition-all duration-500 ease-in-out delay-200">
Hover for a combined transition effect!
</div>`}
          />
          <span>
            This element will smoothly transition both background color and
            scale after a 200ms delay, using a combined ease-in-out curve,
            taking 500ms for the transition to complete.
          </span>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='font-semibold'>7. Animating Other Properties</span>
          <span>
            Tailwind also supports smooth transitions for other properties like
            width, height, margin, and padding using transition-all.
          </span>
          <span>Example for Width and Height Transition:</span>
          <CodeDisplay
            title={'Width and Height Transition Example'}
            codeStr={`<div class="bg-blue-500 p-4 w-32 h-32 hover:w-48 hover:h-48 transition-all duration-700">
Hover to increase the box size smoothly!
</div>`}
          />
          <span>
            When you hover, the box will smoothly grow in width and height.
          </span>
        </div>
      </div>
    </div>
  );
};

export default TailwindTransitions;
